import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import useConnect from "./connect"
import WithSources from "~/ui-rtk/components/layout/WithSources/WithSources"
import { H1, H3, H5, H6 } from "~/ui-rtk/components/ui/typography"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { Field, Form, Formik, FormikProps } from "formik"
import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { useEffect, useMemo, useRef, useState } from "react"
import { Button, Input, Select } from "~/ui-rtk/components/ui/controls"
import { ErrorBlock } from "~/ui-rtk/utils/form"
import { Card, Loader } from "~/ui-rtk/components/ui/common"
import { CalendarDaysIcon } from "@heroicons/react/20/solid"
import MonthPicker from "~/ui-rtk/components/ui/controls/MonthPicker/MonthPicker"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import useDate from "~/ui-rtk/hooks/date"
import MetricStatisticCard from "./components/MetricStatisticCard"
import {
  COMPARE_TO,
  compareToOptions,
  GOAL_GROWTH_TYPE,
  goalGrowthOptions,
  metricsOptions,
  type TGoalForm,
  COMPARE_TO_LABEL,
} from "./constants"
import useMetricStatistics from "./hooks/metric-statistics"
import useMetricChartData from "./hooks/metric-chart"
import { debounce } from "lodash"
import { useChartForecast } from "./hooks/metric-forecast"
import GoalsChart from "./components/GoalsChart"
import { getMonthLabel } from "~/ui-rtk/components/ui/controls/MonthPicker/utils"
import { safeDivide, toCurrency } from "~/ui-rtk/utils/number-utils"

const dayjs = useDate()

const startOfCurrentMonth = dayjs().startOf("month")

export default function NewGoalPage() {
  const { initialValues, validationSchema, handleCreate } = useConnect()
  const { isLoading, setIsLoading } = useLoadingState()
  const formRef = useRef<FormikProps<TGoalForm> | null>(null)
  const navigate = useStableNavigate()
  const [selectedMetric, setSelectedMetric] = useState(initialValues.metric)
  const [compareTo, setCompareTo] = useState(initialValues.compareTo)
  const [targetPeriod, setTargetPeriod] = useState(initialValues.targetPeriod)
  const [growthType, setGrowthType] = useState(initialValues.goalGrowthType)
  const [growthValue, setGrowthValue] = useState(initialValues.goalGrowthValue)
  const {
    statistics,
    isLoading: isStatisticsLoading,
    requestStatistics,
  } = useMetricStatistics()
  const {
    chartData,
    requestChartData,
    isLoading: isChartDataLoading,
  } = useMetricChartData()

  const handleAddGoal = async (values: TGoalForm) => {
    setIsLoading(true)
    await handleCreate(values)
    navigate("/home")
    setIsLoading(false)
  }

  const handleCancel = () => {
    navigate("/home")
  }

  const minDate = useMemo(
    () => startOfCurrentMonth.toDate(),
    [startOfCurrentMonth],
  )

  const maxDate = useMemo(
    () => dayjs(minDate).add(11, "month").toDate(),
    [minDate],
  )

  const chartForecast = useChartForecast(
    chartData,
    growthValue,
    growthType,
    targetPeriod,
  )

  const requestChartDataDebounced = debounce(requestChartData, 500)

  const selectedMetricLabel = useMemo(
    () =>
      metricsOptions.find(({ value }) => value === selectedMetric)?.label ??
      "unknown",
    [metricsOptions, selectedMetric],
  )

  useEffect(() => {
    void requestStatistics(selectedMetric)
  }, [selectedMetric])

  useEffect(() => {
    void requestChartDataDebounced(selectedMetric, compareTo, targetPeriod)
  }, [selectedMetric, compareTo, targetPeriod])

  const title = useMemo(() => {
    const currentPeriod = getMonthLabel(targetPeriod)
    const vsPeriod = getMonthLabel(
      dayjs(targetPeriod)
        .subtract(1, compareTo === COMPARE_TO.LAST_MONTH ? "month" : "year")
        .toDate(),
    )
    return `${currentPeriod} (Forecast) v ${vsPeriod}`
  }, [targetPeriod, compareTo])

  const prevValue = useMemo(() => {
    const { prevData } = chartData
    return prevData.length > 0 ? prevData[prevData.length - 1].value : null
  }, [chartData])

  const forecastToPrevValuePercentage = useMemo(() => {
    if (!prevValue || !chartForecast) {
      return null
    }
    const { forecastData } = chartForecast
    const forecastValue = forecastData[forecastData.length - 1].value
    const absoluteForecastToPrev = safeDivide(forecastValue, prevValue)
    return absoluteForecastToPrev !== 0
      ? ((absoluteForecastToPrev - 1) * 100).toFixed(2)
      : 0
  }, [prevValue, chartForecast])

  const subtitle = useMemo(
    () =>
      chartForecast?.forecastDailyDelta && prevValue
        ? `Forecasted ${selectedMetricLabel} per Day ${toCurrency(chartForecast.forecastDailyDelta)} to hit target, ${forecastToPrevValuePercentage}% higher than ${COMPARE_TO_LABEL[compareTo]}`
        : "",
    [chartForecast, selectedMetricLabel, chartData],
  )

  return (
    <div className="h-full px-4 py-2 bg-basic-black text-basic-light">
      <WithLoader isLoading={isLoading}>
        <WithSources>
          <div className="text-start">
            <H1 className="flex">
              <Breadcrumbs
                items={[
                  {
                    name: "Welcome",
                  },
                  {
                    slug: brandRoutes.HOME.ROOT,
                    name: "Home",
                  },
                  {
                    slug: brandRoutes.HOME.NEW_GOAL,
                    name: "Add New Goal",
                  },
                ]}
              />
            </H1>
          </div>
          <div className="space-y-8 mt-4 pr-8 text-start">
            <Formik
              initialValues={initialValues}
              onSubmit={handleAddGoal}
              validationSchema={validationSchema}
              enableReinitialize
              innerRef={formRef}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({
                errors,
                handleChange,
                setFieldValue,
                setValues,
                values /* ,  touched */,
              }) => (
                <Form className="py-6">
                  <div>
                    <Field
                      id="title"
                      name="title"
                      as={Input}
                      type="text"
                      fullWidth
                      classes={{
                        input: "text-4xl",
                      }}
                      className="border-0 bg-transparent"
                      placeholder="I want to achieve..."
                      onChange={(ev: React.ChangeEvent<string>) => {
                        handleChange(ev)
                      }}
                    />
                    <ErrorBlock error={errors.title} />
                  </div>
                  <div className="min-h-32 border-b border-b-basic-blue">
                    <Field
                      id="description"
                      name="description"
                      as={Input}
                      type="text"
                      fullWidth
                      classes={{
                        input: "text-xl",
                      }}
                      className="border-0 bg-transparent"
                      placeholder="Add description"
                      onChange={(ev: React.ChangeEvent<string>) => {
                        handleChange(ev)
                      }}
                    />
                    <ErrorBlock error={errors.description} />
                  </div>
                  <div className="mt-3 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
                    <Card
                      rounded
                      className="border border-basic-blue bg-basic-dark-blue p-4 col-span-1 text-basic-neutral"
                    >
                      <H5>DEFINE THE TARGET</H5>
                      <div className="mt-3 flex flex-col space-y-4">
                        <label>
                          <H6 className="mb-1">Metric</H6>
                          <Field
                            id="metric"
                            name="metric"
                            as={Select}
                            options={metricsOptions}
                            className="border-0 bg-basic-blue"
                            onChange={(
                              ev: React.ChangeEvent<HTMLSelectElement>,
                            ) => {
                              handleChange(ev)
                              setSelectedMetric(ev.target.value)
                            }}
                          />
                          <ErrorBlock error={errors.metric} />
                        </label>
                        <label>
                          <H6 className="mb-1">Goal Type</H6>
                          <Field
                            id="goalGrowthType"
                            name="goalGrowthType"
                            as={Select}
                            options={goalGrowthOptions}
                            className="border-0 bg-basic-blue"
                            onChange={(
                              ev: React.ChangeEvent<HTMLSelectElement>,
                            ) => {
                              handleChange(ev)
                              setGrowthType(ev.target.value)
                            }}
                          />
                          <ErrorBlock error={errors.goalGrowthType} />
                        </label>
                        <label>
                          <H6 className="mb-1">Target Period</H6>
                          <Field
                            id="targetPeriod"
                            name="targetPeriod"
                            as={MonthPicker}
                            minDate={minDate}
                            maxDate={maxDate}
                            forceMinDate={true}
                            startOfMonth={values.targetPeriod}
                            icon={<CalendarDaysIcon className="w-6 h-6" />}
                            classes={{
                              input:
                                "!w-full border-0 bg-basic-blue !text-sm !font-normal",
                            }}
                            onChange={(val: Date) => {
                              const startOfMonth = dayjs(val).startOf("month")
                              setTargetPeriod(startOfMonth.toDate())
                              if (
                                !startOfCurrentMonth.isSame(
                                  startOfMonth,
                                  "month",
                                )
                              ) {
                                void setValues({
                                  ...values,
                                  compareTo: COMPARE_TO.SAME_MONTH_LAST_YEAR,
                                  targetPeriod: startOfMonth.toDate(),
                                })
                                setCompareTo(COMPARE_TO.SAME_MONTH_LAST_YEAR)
                              } else {
                                void setFieldValue(
                                  "targetPeriod",
                                  startOfMonth.toDate(),
                                )
                              }
                            }}
                            onBlur={(e: MouseEvent) => e.preventDefault()}
                          />
                          <ErrorBlock error={errors.targetPeriod as string} />
                        </label>
                        <label>
                          <H6 className="mb-1">Growth Value</H6>
                          <Field
                            id="goalGrowthValue"
                            name="goalGrowthValue"
                            as={Input}
                            type="number"
                            fullWidth
                            className="border-0 bg-basic-blue"
                            onChange={(
                              ev: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChange(ev)
                              setGrowthValue(+ev.target.value)
                            }}
                          />
                          <ErrorBlock error={errors.goalGrowthValue} />
                        </label>
                        <label>
                          <H6 className="mb-1">Compare To</H6>
                          <Field
                            id="compareTo"
                            name="compareTo"
                            as={Select}
                            disabled={
                              values.goalGrowthType ===
                                GOAL_GROWTH_TYPE.ABSOLUTE ||
                              !dayjs(values.targetPeriod).isSame(
                                startOfCurrentMonth,
                                "month",
                              )
                            }
                            options={compareToOptions}
                            className="border-0 bg-basic-blue"
                            onChange={(
                              ev: React.ChangeEvent<HTMLSelectElement>,
                            ) => {
                              handleChange(ev)
                              setCompareTo(ev.target.value)
                            }}
                          />
                          <ErrorBlock error={errors.compareTo} />
                        </label>
                      </div>
                      <div className="divider"></div>
                      <H5>DEFINE THE TARGET</H5>
                      <div className="mt-4 w-full">
                        <textarea
                          className="border-0 bg-basic-blue rounded-sm resize-none w-full text-sm"
                          value={values.notes}
                          name="notes"
                          id="notes"
                          placeholder="Why do you expect to meet this goal?"
                          onChange={handleChange}
                        ></textarea>
                        <ErrorBlock error={errors.notes} />
                      </div>
                    </Card>
                    <div className="col-auto xl:col-span-2">
                      <div className="flex gap-3 overflow-x-auto">
                        {isStatisticsLoading ? (
                          <div className="w-full h-20 flex items-center justify-center">
                            <Loader size={66} />
                          </div>
                        ) : (
                          statistics.map(stats => (
                            <MetricStatisticCard
                              key={stats.label}
                              title={stats.label}
                              actualValue={stats.actual}
                              comparedValue={stats.compared}
                              metric={selectedMetricLabel}
                            />
                          ))
                        )}
                      </div>
                      <Card
                        rounded
                        className="mt-3 p-5 border border-basic-blue bg-basic-dark-blue"
                      >
                        {isChartDataLoading ? (
                          <div className="w-full h-20 flex items-center justify-center">
                            <Loader size={66} />
                          </div>
                        ) : (
                          chartForecast?.forecastData && (
                            <>
                              <H3 className="mb-2">{title}</H3>
                              {prevValue && (
                                <H6 className="font-bold">
                                  Target:{" "}
                                  {toCurrency(
                                    chartForecast.forecastData[1].value,
                                  )}{" "}
                                  vs {toCurrency(prevValue)}
                                </H6>
                              )}
                              <H6 className="mb-2 text-sm">{subtitle}</H6>
                              <GoalsChart
                                prevData={chartData.prevData}
                                actualData={chartData.actualData}
                                forecastData={chartForecast.forecastData}
                              />
                            </>
                          )
                        )}
                      </Card>
                    </div>
                  </div>
                  <div className="mt-3 flex items-center justify-end gap-3">
                    <Button
                      variant={{ variant: "solid", color: "white" }}
                      size="sm"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={{ variant: "solid", color: "pink" }}
                      size="sm"
                      type="submit"
                      isLoading={isLoading}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </WithSources>
      </WithLoader>
    </div>
  )
}
