import { useState } from "react"
import Chip from "~/ui-rtk/components/ui/common/Chip"
import Input from "~/ui-rtk/components/ui/controls/Input"

type TChipInputProps = {
  value?: string[]
  defaultValue?: string[]
  placeholder?: string
  inputClassName?: string
  onChange: (value: string[]) => void
}

export default function ChipsInput({
  value: valueProps,
  defaultValue,
  placeholder,
  inputClassName,
  onChange: onChangeFromProps,
}: TChipInputProps) {
  const isControlled = typeof valueProps !== "undefined"
  const hasDefaultValue = typeof defaultValue !== "undefined"

  const [internalValue, setInternalValue] = useState<string[]>(
    hasDefaultValue ? defaultValue : [],
  )
  const [tempString, setTempString] = useState<string>("")

  const inputValue = isControlled ? valueProps : internalValue

  const onChange = (e: string[]) => {
    if (onChangeFromProps) {
      onChangeFromProps(e)
    }

    if (!isControlled) {
      setInternalValue(e)
    }
  }

  const handleRemove = (idx: number) => {
    if (idx > inputValue.length - 1) {
      return
    }
    const mutableArray = Array.isArray(inputValue)
      ? [...inputValue]
      : [inputValue]
    mutableArray.splice(idx, 1)
    onChange(mutableArray)
  }

  const apply = (event: React.KeyboardEvent | React.FocusEvent) => {
    const prevVal = Array.isArray(inputValue) ? inputValue : [inputValue]
    event.preventDefault()
    onChange([
      ...prevVal,
      ...tempString
        .split(",")
        .map((str: string) => String.prototype.trim.call(str))
        .filter((str: string) => !!str),
    ])
    setTempString("")
  }

  const handleKeyUp = (event: React.KeyboardEvent) => {
    event.stopPropagation()
    if ((event.key === "Enter" || event.key === ",") && tempString.length > 0) {
      apply(event)
    }
  }

  const handlBlur = (event: React.FocusEvent) => {
    event.stopPropagation()
    if (tempString.length > 0) {
      apply(event)
    }
  }

  return (
    <>
      <Input
        value={tempString}
        fullWidth
        className={inputClassName}
        placeholder={placeholder}
        onChange={e => setTempString(e.target.value)}
        onKeyUp={handleKeyUp}
        onKeyDown={e => {
          e.key === "Enter" && e.preventDefault()
        }}
        onBlur={handlBlur}
      />
      {inputValue && (
        <div className="flex flex-wrap mt-2 gap-y-1.5">
          {(Array.isArray(inputValue) ? inputValue : [inputValue]).map(
            (value, idx) => (
              <Chip
                className={`border border-basic-pink px-2 py-1.5 mr-2 leading-4`}
                key={`chip-${idx}`}
                onRemove={() => handleRemove(idx)}
              >
                <span className="text-sm">{value}</span>
              </Chip>
            ),
          )}
        </div>
      )}
    </>
  )
}
