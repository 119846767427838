import { useState } from "react"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { toast } from "react-toastify"
import * as yup from "yup"
import { useAuthControllerLogoutMutation } from "~/ui-rtk/api/authApi"
import { useLocalStorage, LocalStorageKeys } from "~/ui-rtk/utils/storage"
import { useCompanyMetadataUpdater } from "~/ui-rtk/hooks/company-updater"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import { CompanyMetadataDto } from "~/ui-rtk/api/types/company.types"

export type TKeywordsPageFormValues = {
  googleSearchAlias: string | undefined
  googleBrands: string[]
  googleCompetitors: string[]
  googleCategories: string[]
  instagramCompetitors: string[]
  webTerms: NonNullable<NonNullable<CompanyMetadataDto["mentions"]>["webTerms"]>
}

export const validationSchema = yup.object().shape({
  googleSearchAlias: yup.string().optional(),
  googleBrands: yup.array().of(yup.string()),
  // .min(1, "At least one brand is required")
  // .required("Please fill keywords"),
  googleCompetitors: yup.array().of(yup.string()),
  // .min(1, "At least one competitor is required")
  // .required("Please fill keywords"),
  googleCategories: yup.array().of(yup.string()),
  // .min(1, "At least one category is required")
  // .required("Please fill keywords"),
  instagramCompetitors: yup.array().of(yup.string()),
  // .min(1, "At least one competitor id is required")
  // .required("Please fill keywords"),
  webTerms: yup.array().of(
    yup.object().shape({
      primaryTerm: yup.string().required("Please fill primary term"),
      supportTerms: yup.array().of(yup.string()),
    }),
  ),
})

export default function useConnect() {
  const [dontShowDialogAgain, setDontShowDialogAgain] =
    useLocalStorage<boolean>(
      LocalStorageKeys.LOCAL_STORAGE_DONT_SHOW_AGAIN_SAVE_KEYWORD_DIALOG_NOTIFICATON_KEY,
      false,
    )
  const [reloaded, triggerReload] = useState(false)
  const currentCompany = useCurrentCompany()
  const { updateCompanyMetadata } = useCompanyMetadataUpdater()
  const { keywords, mentions } = (currentCompany?.metadata ?? {}) as {
    keywords: CompanyMetadataDto["keywords"]
    mentions: CompanyMetadataDto["mentions"]
  }
  const [logout] = useAuthControllerLogoutMutation()
  const navigate = useStableNavigate()

  const initialValues: TKeywordsPageFormValues = {
    googleSearchAlias: keywords?.google?.searchAlias,
    googleBrands: keywords?.google?.brands ?? [],
    googleCompetitors: keywords?.google?.competitors ?? [],
    googleCategories: keywords?.google?.categories ?? [],
    instagramCompetitors: keywords?.instagram?.competitors ?? [],
    webTerms: mentions?.webTerms ?? [],
  }

  const setDontShowDialogAgainHandler = (val: boolean) => {
    setDontShowDialogAgain(val)
    triggerReload(!reloaded)
  }

  const updateKeywords = async ({
    googleSearchAlias,
    googleBrands,
    googleCompetitors,
    googleCategories,
    instagramCompetitors,
    webTerms,
  }: TKeywordsPageFormValues) => {
    if (!currentCompany) {
      return
    }
    const metadata: CompanyMetadataDto = {
      ...(currentCompany?.metadata ?? {}),
      keywords: {
        ...(currentCompany?.metadata?.keywords ?? {}),
        google: {
          ...(currentCompany?.metadata?.keywords?.google ?? { hash: null }),
          searchAlias: googleSearchAlias,
          brands: googleBrands,
          competitors: googleCompetitors,
          categories: googleCategories,
        },
        instagram: {
          ...(currentCompany?.metadata?.keywords?.instagram ?? { hash: "" }),
          competitors: instagramCompetitors,
        },
      },
      mentions: {
        ...(currentCompany?.metadata?.mentions ?? {}),
        webTerms,
      },
    }

    try {
      await updateCompanyMetadata(metadata)
      if (dontShowDialogAgain) {
        toast.success("Keywords were updated")
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error)
      if (error.status === 401) {
        await logout()
        navigate("/")
        return
      }
      toast.error("Could not update keywords")
    }
  }

  return {
    companyName: currentCompany?.name,
    initialValues,
    validationSchema,
    updateKeywords,
    dontShowDialogAgain,
    setDontShowDialogAgain: setDontShowDialogAgainHandler,
  }
}
