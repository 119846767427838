import TabsGroup from "~/ui-rtk/components/ui/controls/TabsGroup/TabsGroup"
import { H3 } from "~/ui-rtk/components/ui/typography"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import { useEffect, useMemo } from "react"
import { Loader } from "~/ui-rtk/components/ui/common"
import MetricStatisticCard from "~/ui-rtk/pages/Goals/components/MetricStatisticCard"
import useMetricStatistics from "~/ui-rtk/pages/Goals/hooks/metric-statistics"
import { metricsOptions } from "~/ui-rtk/pages/Goals/constants"

type TMetricsStatisticsProps = {
  className?: string
}

const urlKey = "metric-statistics"

export default function MetricsStatistics({
  className,
}: TMetricsStatisticsProps) {
  const { params, setParams } = useURLSearchParams()

  const {
    statistics,
    isLoading: isStatisticsLoading,
    requestStatistics,
  } = useMetricStatistics()

  const tabIndex = useMemo(() => {
    if (params[urlKey]) {
      return +params[urlKey]
    }
    return 0
  }, [params])

  const handleSetTabIndex = (idx: number) => {
    setParams({
      [urlKey]: `${idx}`,
    })
  }

  const selectedMetricLabel = useMemo(
    () => metricsOptions[tabIndex].label,
    [tabIndex],
  )

  const selectedMetric = useMemo(
    () => metricsOptions[tabIndex].value,
    [tabIndex],
  )

  useEffect(() => {
    void requestStatistics(selectedMetric)
  }, [selectedMetric])

  return (
    <div className={cn("metrics-statistics-section h-full")}>
      <div>
        <H3>Metrics Statistics</H3>
      </div>
      <div
        className={cn(
          className,
          "join max-h-14 border-b border-b-basic-blue w-full",
        )}
      >
        <TabsGroup
          options={metricsOptions}
          onChange={handleSetTabIndex}
          index={tabIndex}
        />
      </div>
      <div className="flex gap-3 overflow-x-auto mt-4">
        {isStatisticsLoading ? (
          <div className="w-full h-20 flex items-center justify-center">
            <Loader size={66} />
          </div>
        ) : (
          statistics.map(stats => (
            <MetricStatisticCard
              key={stats.label}
              title={stats.label}
              actualValue={stats.actual}
              comparedValue={stats.compared}
              metric={selectedMetricLabel}
            />
          ))
        )}
      </div>
    </div>
  )
}
