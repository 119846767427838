import { POSTHOG_FEATURES } from "../constants/feature-flags"
import usePostHogFeatureFlags from "./posthog-feaures"

export default function useMentionsReviewAccess() {
  const isFeatureFlagEnabled = usePostHogFeatureFlags(
    POSTHOG_FEATURES.IG_TAG_REVIEW,
  )

  return isFeatureFlagEnabled
}
