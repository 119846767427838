import React, { useEffect, useRef, useState } from "react"
import type { Table } from "@tanstack/react-table"

import Menu from "./Menu"
import { FilterSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { ChevronDownSvg } from "~/ui-rtk/components/ui/svg/arrow"

import { useClickOutside } from "~/ui-rtk/shared/hooks"

import { cn } from "~/ui-rtk/utils/tailwind-utils"

import type {
  TableVisualizationColumnFilter,
  TableVisualizationMeta,
  TMetric,
} from "~/ui-rtk/constants/table-visualization-types"
import { TManualFilter } from "../../constants"

type TableFiltersProps = {
  table: Table<TMetric>
  filterToEdit?: {
    idx: number
    filter: TableVisualizationColumnFilter
  }
  classes?: Partial<{
    container: string
    menu: string
  }>
  additionalFilters?: TManualFilter[]
  onClose: () => void
}

const TableFilters: React.FC<TableFiltersProps> = ({
  table,
  classes,
  filterToEdit,
  additionalFilters,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)
  const headers = table.getHeaderGroups().at(0)?.headers || []
  const metadata = table.options?.meta as TableVisualizationMeta

  const closeMenu = () => {
    setIsOpen(false)
    onClose()
  }

  const toggleMenu = () => {
    setIsOpen(prev => !prev)
  }

  const handleApplyFilters = (filter: TableVisualizationColumnFilter) => {
    const addFilter = metadata?.addFilterFn

    if (addFilter) {
      addFilter(filter)
      closeMenu()
    }
  }

  const handleResetFilters = () => {
    metadata?.resetFilters?.()
  }

  useEffect(() => {
    if (filterToEdit) {
      setIsOpen(true)
    }
  }, [filterToEdit])

  useClickOutside(menuRef, closeMenu)

  return (
    <div
      ref={menuRef}
      className={cn(
        "relative bg-basic-dark-blue border border-basic-blue rounded-md py-1.5 px-2",
        classes?.container,
      )}
    >
      <button className="flex items-center gap-2 text-sm" onClick={toggleMenu}>
        <FilterSvg size={12} />
        <span>Filter</span>
        <ChevronDownSvg size={12} />
      </button>

      {isOpen && (
        <Menu
          filterToEdit={filterToEdit}
          headers={headers}
          additionalFilters={additionalFilters}
          onApply={handleApplyFilters}
          onReset={handleResetFilters}
        />
      )}
    </div>
  )
}

export default TableFilters
