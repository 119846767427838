import { AggregationType } from "../constants/charts"
import { usePeriod } from "./period"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

type TReturnType = {
  dateRange: [Date, Date]
  compareRange: [Date, Date] | null
}

const defaultMaxDate = dayjs().subtract(3, "day").toDate()

export const useMinMaxDateRange = (
  minDate?: Date,
  maxDate?: Date,
  defaultGranularity?: AggregationType,
  resetPeriodOnGranularityChange = false,
  lastDateMetric?: string,
): TReturnType => {
  const { lastDate } = useLastUpdateDate(
    lastDateMetric
      ? {
          dateMetric: lastDateMetric,
        }
      : undefined,
  )
  const { period: dateRange, comparePeriod: compareRange } = usePeriod(
    defaultGranularity,
    resetPeriodOnGranularityChange,
    maxDate,
  )
  const maxDateWithDefault =
    maxDate ?? (lastDate ? new Date(lastDate) : undefined) ?? defaultMaxDate
  let adjustedStartDate: Date = dayjs(dateRange[0]).toDate()
  let adjustedEndDate: Date = dayjs(dateRange[1]).toDate()
  let compareRangeAdjusted: [Date, Date] | null = null

  if (minDate && adjustedStartDate < minDate) {
    adjustedStartDate = minDate
  }

  if (maxDateWithDefault && adjustedEndDate > maxDateWithDefault) {
    adjustedEndDate = maxDateWithDefault
  }

  if (compareRange && compareRange[0] && compareRange[1]) {
    compareRangeAdjusted = [
      dayjs(compareRange[0]).toDate(),
      dayjs(compareRange[1]).toDate(),
    ]
  }

  return {
    dateRange: [adjustedStartDate, adjustedEndDate],
    compareRange: compareRangeAdjusted,
  }
}

export const useYearDateRange = (): [Date, Date] => {
  const today = new Date()
  const yearAgo = dayjs(today).subtract(1, "year").toDate()
  return [yearAgo, today]
}
