import useDate from "~/ui-rtk/hooks/date"
import { GOAL_GROWTH_TYPE, TGrowthType, TMetric } from "../constants"
import { safeDivide } from "~/ui-rtk/utils/number-utils"
import { TGoalChartData } from "./metric-chart"

const dayjs = useDate()

export type TMetricForecastProps = {
  endDate: Date
  startDate: Date
  previousTotal: number
  growthType: TGrowthType
  growthValue: number
}

export default function useMetricForecast({
  endDate,
  startDate,
  previousTotal,
  growthType,
  growthValue,
}: TMetricForecastProps) {
  const maxTotal =
    growthType === GOAL_GROWTH_TYPE.ABSOLUTE
      ? growthValue
      : previousTotal * (1 + growthValue / 100)

  const daysDiff = dayjs(endDate).diff(dayjs(startDate), "day")
  const valueDiff = maxTotal
  const forecastDailyDelta = safeDivide(valueDiff, daysDiff)

  return {
    forecastData: [
      {
        date: startDate,
        value: 0,
      },
      {
        date: endDate,
        value: maxTotal,
      },
    ] as [TMetric, TMetric],
    forecastDailyDelta,
  }
}

export function useChartForecast(
  chartData: TGoalChartData,
  growthValue: number,
  growthType: TGrowthType,
  targetPeriod: Date,
) {
  if (chartData.prevData?.length < 2) {
    return null
  }
  const { prevData } = chartData
  const prevDataLength = prevData.length
  const lastPrevItem = prevData[prevDataLength - 1]
  const startDate = dayjs(targetPeriod).startOf("month").toDate()
  const endDate = dayjs(startDate).endOf("month").toDate()
  return useMetricForecast({
    startDate,
    endDate,
    previousTotal: +(lastPrevItem?.value ?? 0),
    growthType,
    growthValue,
  })
}
