export type TGoalForm = {
  title: string
  description?: string
  notes?: string
  metric: string
  goalGrowthType: string
  goalGrowthValue: number
  targetPeriod: Date
  compareTo: string
}

export type TMetric = {
  date: Date
  value: number
}

export const GOAL_GROWTH_TYPE = {
  PERCENTAGE: "PERCENTAGE",
  ABSOLUTE: "ABSOLUTE",
}

export type TGrowthType =
  (typeof GOAL_GROWTH_TYPE)[keyof typeof GOAL_GROWTH_TYPE]

export const GOAL_METRIC = {
  BRAND_VALUE: "brand_value",
  BASELINE_REVENUE: "baseline_revenue",
}

export type TGoalMetric = (typeof GOAL_METRIC)[keyof typeof GOAL_METRIC]

export const COMPARE_TO = {
  SAME_MONTH_LAST_YEAR: "same_month_last_year",
  LAST_MONTH: "last_month",
}

export const COMPARE_TO_LABEL = {
  [COMPARE_TO.SAME_MONTH_LAST_YEAR]: "Last Year",
  [COMPARE_TO.LAST_MONTH]: "Last Month",
}

export type TCompareTo = (typeof COMPARE_TO)[keyof typeof COMPARE_TO]

export const goalGrowthOptions = [
  {
    label: "% Growth Goal",
    value: GOAL_GROWTH_TYPE.PERCENTAGE,
  },
  {
    label: "Absolte Value Growth Goal",
    value: GOAL_GROWTH_TYPE.ABSOLUTE,
  },
]

export const compareToOptions = [
  {
    label: "Same Month Last Year",
    value: COMPARE_TO.SAME_MONTH_LAST_YEAR,
  },
  {
    label: "Last Month",
    value: COMPARE_TO.LAST_MONTH,
  },
]

export const metricsOptions = [
  {
    label: "Brand Value",
    value: GOAL_METRIC.BRAND_VALUE,
  },
  {
    label: "Baseline Revenue",
    value: GOAL_METRIC.BASELINE_REVENUE,
  },
]
