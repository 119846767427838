import React, { FocusEvent, MouseEvent, ReactNode, useMemo } from "react"
import ReactDatePicker, { type ReactDatePickerProps } from "react-datepicker"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

import { Input } from "~/ui-rtk/components/ui/controls"
import { Popover } from "~/ui-rtk/components/ui/common"

import { MONTH_AGO_RESTRICTION } from "~/ui-rtk/constants/restrictions"

import { getMonthLabel } from "./utils"
import { isDateMoreRecentThanMonthsAgo } from "~/ui-rtk/utils/date-utils"

import "react-datepicker/dist/react-datepicker.css"
import "./index.scss"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import PeriodPickerDropDownIcons from "../../common/PeriodPickerDropDownIcons/PeriodPickerDropDownIcons"

type TMonthPickerClasses = {
  input: string
}

type TMonthPickerProps = {
  startOfMonth: Date
  classes?: Partial<TMonthPickerClasses>
  onChange: (date: Date | null) => void
  isPartialPeriod?: boolean
  icon?: ReactNode
  forceMinDate?: boolean
} & Omit<ReactDatePickerProps, "onChange">

const MonthPicker: React.FC<TMonthPickerProps> = ({
  startOfMonth,
  minDate,
  maxDate,
  forceMinDate,
  onChange,
  classes,
  icon: iconFromProps,
  isPartialPeriod = false,
  ...props
}) => {
  const $minDate = useMemo(() => {
    if (
      !minDate ||
      (!forceMinDate &&
        isDateMoreRecentThanMonthsAgo(minDate, MONTH_AGO_RESTRICTION))
    ) {
      return dayjs().subtract(MONTH_AGO_RESTRICTION, "month").toDate()
    }
    return minDate
  }, [minDate])

  const handlePickDate = (date: Date | null) => {
    void onChange(date)
  }

  const icon = iconFromProps ?? (
    <PeriodPickerDropDownIcons isPartialPeriod={isPartialPeriod} />
  )

  return (
    <Popover
      relative
      handler={() => (
        <Input
          readOnly
          value={getMonthLabel(startOfMonth)}
          appendNode={icon}
          classes={{
            input: classes?.input,
          }}
          className={cn("min-w-72 max-h-12.5 rounded-md ", classes?.input)}
          onBlur={(e: FocusEvent<HTMLInputElement>) => e.preventDefault()}
        />
      )}
    >
      {close => (
        <div
          onClick={(e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()
            e.preventDefault()
            e.nativeEvent.stopImmediatePropagation()
          }}
        >
          <ReactDatePicker
            calendarClassName="ui-rtk-week-picker mt-2"
            selected={startOfMonth}
            showMonthYearPicker
            showFullMonthYearPicker
            inline
            minDate={$minDate}
            maxDate={maxDate}
            shouldCloseOnSelect={true}
            onChange={(val: Date | null) => {
              handlePickDate(val)
              close()
            }}
            calendarStartDay={1}
            {...props}
          />
        </div>
      )}
    </Popover>
  )
}

export default MonthPicker
