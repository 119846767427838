import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  ColumnOrderState,
  ColumnPinningState,
  OnChangeFn,
  PaginationState,
  TableOptions,
  Updater,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers"
import { arrayMove } from "@dnd-kit/sortable"

import {
  Cell,
  Header,
  TableBody,
  TableHead,
  Pagination,
  TableFilters,
} from "./components"
import { useColumns, useData, usePagination } from "./hooks"

import { isColumnFilterable, isColumnSortable } from "./utils"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

import type { TDrawerConfig } from "~/ui-rtk/constants/drawer"
import {
  FILTER_OPERATOR,
  type TColumnFiltersState,
  type TMetric,
  type TSources,
  type TableVisualizationColumn,
  type TableVisualizationColumnFilter,
} from "../../../../constants/table-visualization-types"
import { CloseSvg, EditSvg } from "../../svg/essentials"
import { DEFAULT_CELL_WIDTH, TManualFilter } from "./constants"
import { TableSummary } from "./components/TableSummary/TableSummary"
import { MetricsMapping } from "~/ui-rtk/constants/metrics-mapping"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"

import useDefaultColumnWidth from "./hooks/useDefaultColumnWidth"
import { CaptionPreview } from "../../common"
import useUrlSorting from "~/ui-rtk/hooks/url-sorting"
import useUrlColumnFilters from "~/ui-rtk/hooks/url-column-filters"
import useUrlPinnedColumns from "~/ui-rtk/hooks/url-pinned-columns"
import { useSummaries } from "~/ui-rtk/hooks/cube"
import useUrlColumnOrder from "~/ui-rtk/hooks/url-column-order"
import useDemoMode from "~/ui-rtk/hooks/demo-mode"

export type TTableVisualizationProps = {
  compareMode?: boolean
  metrics: TMetric[]
  compareUniqueKey?: string
  columnConfig?: TableVisualizationColumn[]
  sources?: TSources
  className?: string
  setPaginationState: Dispatch<SetStateAction<PaginationState>>
  paginationState: PaginationState
  totalPages?: number
  enablePagination?: boolean
  disableHover?: boolean
  openDrawer?: (config: Partial<TDrawerConfig>) => void
  renderConfig?: Record<string, any>
  rowClickInDrawer?: boolean
  dateRange?: [Date, Date]
  compareRange?: [Date, Date]
  chartId: string
  urlParamsPrefix?: string
  summaryWidget?: keyof typeof MetricsMapping
  cubeFilters?: TCubeFilterOptions
  cubeQueryParams?: Record<string, string>
  totalLabelColumn?: string
  dataAggType?: string
  additionalFilters?: TManualFilter[]
  sortBy?: string
  sortDesc?: boolean
  hideFilters?: boolean
  summaryMetrics?: Record<string, any>
  hideItemsPerPage?: boolean
}

const MULTI_SORTING_ENABLED = false

const TableVisualization: React.FC<TTableVisualizationProps> = ({
  metrics,
  compareMode: isComparing,
  compareUniqueKey,
  columnConfig,
  sources,
  className,
  setPaginationState: setPaginationStateFromProps,
  paginationState: paginationStateFromProps,
  totalPages: totalPagesProps = 1,
  enablePagination = false,
  disableHover,
  openDrawer = () => null,
  rowClickInDrawer = false,
  dateRange,
  compareRange,
  chartId,
  urlParamsPrefix,
  summaryWidget,
  totalLabelColumn,
  cubeFilters,
  cubeQueryParams,
  additionalFilters = [],
  renderConfig,
  summaryMetrics,
  hideFilters = false,
  hideItemsPerPage = false,
  ...props
}) => {
  // ToDo: check if we need internal pagination for something?
  const {
    paginationState: paginationStateFromHook,
    setPaginationState: setPaginationStateFromHook,
    totalPages: totalPagesFromHook,
    showPagination: showPaginationFromHook,
  } = usePagination(metrics.length)
  const { data, noData, compareData } = useData(
    Boolean(isComparing),
    compareUniqueKey,
    metrics,
  )

  const { summaryData, compareSummaryData, isLoading } =
    summaryWidget && dateRange
      ? useSummaries({
          widget: summaryWidget,
          dateRange,
          compareRange: compareRange ?? undefined,
          cubeFilters,
          cubeQueryParams,
        })
      : {
          summaryData: null,
          compareSummaryData: null,
          isLoading: false,
        }

  const summary = useMemo(() => {
    if (summaryMetrics) {
      return summaryMetrics
    }
    if (summaryData) {
      return summaryData
    }
    return null
  }, [summaryData, summaryMetrics])

  const wrapperRef = useRef<HTMLTableElement | null>(null)
  const columns = useColumns(columnConfig, noData, data)

  const { columnOrder, setColumnOrder } = useUrlColumnOrder(
    urlParamsPrefix ?? chartId,
    columns.map(column => column.key),
  )
  const [filterToEditIdx, setFilterToEditIdx] = useState<number | null>(null)

  // ToDo: check if we need internal pagination for something?
  const totalPages = enablePagination ? totalPagesProps : totalPagesFromHook
  const paginationState = enablePagination
    ? paginationStateFromProps
    : paginationStateFromHook
  const setPagination = enablePagination
    ? setPaginationStateFromProps
    : setPaginationStateFromHook
  const showPagination = enablePagination ? true : showPaginationFromHook

  const defaultSorting = useMemo(() => {
    const id = props.sortBy
    const desc = props.sortDesc ?? true
    return id
      ? [
          {
            id,
            desc,
          },
        ]
      : undefined
  }, [props])
  const { sorting, setSorting } = useUrlSorting(
    urlParamsPrefix ?? chartId,
    defaultSorting,
  )
  const { columnFilters, setColumnFilters } = useUrlColumnFilters(
    urlParamsPrefix ?? chartId,
  )
  const { pinnedColumns: columnPinning, setPinnedColumns: setColumnPinning } =
    useUrlPinnedColumns(urlParamsPrefix ?? chartId)

  const handlePinningChange: OnChangeFn<ColumnPinningState> = (
    columnPinningUpdater: Updater<ColumnPinningState>,
  ) => {
    setColumnPinning(columnPinningUpdater)
  }

  const handleColumnOrderChange: OnChangeFn<ColumnOrderState> = (
    order: Updater<ColumnOrderState>,
  ) => {
    setColumnOrder(order)
  }

  const filterToEdit = useMemo(() => {
    if (filterToEditIdx === null || !columnFilters) {
      return null
    }

    return {
      idx: filterToEditIdx,
      filter: columnFilters[filterToEditIdx],
    }
  }, [filterToEditIdx, columnFilters])

  const defaultColumnWidth = useDefaultColumnWidth(
    columns,
    wrapperRef,
    DEFAULT_CELL_WIDTH,
  )

  const tableOptions: TableOptions<TMetric> = useMemo(
    () => ({
      columns: columns.map(column => ({
        ...column,
        accessorFn: row => {
          if (column.render) {
            return column.render(row, renderConfig)
          }

          return row[column.key]
        },
        id: column.key,
        size: column.width ?? defaultColumnWidth,
        enableSorting: isColumnSortable(column.type) && !column.disableSorting,
        enableFiltering:
          isColumnFilterable(column.type) && !column.disableFiltering,
        header: () => (
          <Header
            type={column.type}
            value={column.header}
            isBrand={column.isBrand}
            isComparing={Boolean(isComparing)}
            className={column.classes?.head}
          />
        ),
        footer: props => props.column.id,
        sortDescFirst: column.type !== "title",
        cell: props => {
          let compareWith = undefined
          if (isComparing) {
            compareWith = {
              key: compareUniqueKey,
              data: compareData,
            }
          }

          return (
            <Cell
              sources={sources}
              column={column}
              cellContext={props}
              compareWith={compareWith}
            />
          )
        },
        meta: {
          filterFn: (columnFilter: TableVisualizationColumnFilter) => {
            const updatedFilters = columnFilters?.filter(
              item => item.id !== columnFilter.id,
            )

            setColumnFilters?.([...(updatedFilters || []), columnFilter])
          },
        },
      })),
      data: data ?? [],
      state: {
        sorting,
        columnFilters,
        pagination: paginationState,
        columnPinning,
        columnOrder,
      },
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      onSortingChange: setSorting,
      onPaginationChange: setPagination,
      onColumnPinningChange: handlePinningChange,
      onColumnOrderChange: handleColumnOrderChange,
      manualPagination: enablePagination,
      manualSorting: true,
      manualFiltering: true,
      enableColumnResizing: false,
      enableMultiSort: MULTI_SORTING_ENABLED,
      meta: {
        getFiltersStateFn: () => columnFilters || [],
        filterFn: (state: TColumnFiltersState) => {
          setColumnFilters(state)
        },
        addFilterFn: (columnFilter: TableVisualizationColumnFilter) => {
          const filterToUpdate = [...(columnFilters ?? [])]
          const { idx, ...filter } = columnFilter
          if (typeof idx !== "undefined" && idx !== null) {
            filterToUpdate[idx] = filter
          } else {
            filterToUpdate.push(filter)
          }

          setColumnFilters([...filterToUpdate])
          setFilterToEditIdx(null)
        },
        resetFilters: () => {
          setColumnFilters([])
        },
      },
    }),
    [
      sorting,
      paginationState,
      columns,
      data,
      sources,
      compareUniqueKey,
      isComparing,
      columnFilters,
      columnPinning,
      columnOrder,
      defaultColumnWidth,
    ],
  )

  const table = useReactTable(tableOptions)
  const headerGroups = table.getHeaderGroups()
  const rowModel = table.getRowModel()
  const tBodyId = `tbody_${chartId}`

  const { isEnabled: isDemoEnabled } = useDemoMode()

  const onRowClick = (data: TMetric) => {
    if (rowClickInDrawer) {
      openDrawer({
        props: {
          metric: data,
        },
        clickSourceId: tBodyId,
      })
    }
  }

  const handleRemoveFilter = (idx: number) => () => {
    setColumnFilters((prev?: TColumnFiltersState) => {
      const prevFilters = [...(prev ?? [])]
      prevFilters.splice(idx, 1)
      return prevFilters
    })
  }

  const handleEditFilter = (idx: number) => () => {
    setFilterToEditIdx(idx)
  }

  // reorder columns after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      setColumnOrder(columnOrder => {
        const oldIndex = columnOrder.indexOf(active.id as string)
        const newIndex = columnOrder.indexOf(over.id as string)
        return arrayMove(columnOrder, oldIndex, newIndex) // this is just a splice util
      })
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 3 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 3 } }),
    useSensor(KeyboardSensor, {}),
  )

  const renderAppliedFilter = (
    filter: TableVisualizationColumnFilter,
    idx: number,
  ) => {
    const column = columns.find(column => column.key === filter.id)
    const prefix = column?.header || filter.id

    let displayValue: string

    if (filter.variant === "range") {
      const { min, max } = filter.value as { min?: number; max?: number }
      displayValue = `from ${min !== undefined ? min : "—"} to ${max !== undefined ? max : "—"}`
    } else if (filter.variant === "text") {
      const filterValue = filter.value
      const filterOperator = filter.operator

      displayValue = `${FILTER_OPERATOR[filterOperator ?? "contains"]}: ${
        Array.isArray(filterValue)
          ? filterValue.join(", ")
          : (filterValue as string)
      }`
    } else {
      return
    }

    return (
      <li
        key={`${filter.id}_${idx}`}
        className="flex bg-basic-dark-blue rounded-md px-3 py-2 gap-2"
      >
        <div>
          <span className="text-sm text-basic-grey-inactive">{prefix}:</span>{" "}
          <span className="text-3.5">
            <CaptionPreview text={displayValue} exact={true} align="left" />
          </span>
        </div>
        <button
          className="button button-circle"
          onClick={handleEditFilter(idx)}
        >
          <EditSvg />
        </button>
        <button
          className="button button-circle"
          onClick={handleRemoveFilter(idx)}
        >
          <CloseSvg />
        </button>
      </li>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      {!hideFilters && (
        <div className="flex items-center justify-between">
          <ul className="flex gap-1 max-w-200 flex-wrap">
            {columnFilters?.map(renderAppliedFilter)}
          </ul>
          <TableFilters
            table={table}
            filterToEdit={filterToEdit ?? undefined}
            additionalFilters={additionalFilters}
            onClose={() => setFilterToEditIdx(null)}
          />
        </div>
      )}
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div
          className={cn(
            "h-full flex flex-col justify-between space-y-3 transition-all border rounded-lg border-basic-blue bg-basic-dark-blue",
            className,
          )}
        >
          <div className="overflow-x-auto scrollbar relative flex-grow flex-shrink">
            <table
              ref={wrapperRef}
              className="min-w-full border-b rounded-lg border-b-basic-blue block h-full"
            >
              <TableHead
                headerGroups={headerGroups}
                sorting={sorting}
                columnOrder={columnOrder}
              />
              <TableBody
                demoMode={isDemoEnabled}
                rows={rowModel.rows}
                colsLength={columns.length}
                countPerPage={paginationState.pageSize}
                disableHover={disableHover}
                columnOrder={columnOrder}
                onRowClick={onRowClick}
                id={tBodyId}
              />
              {summary ? (
                <TableSummary
                  headerGroups={headerGroups}
                  columnOrder={columnOrder}
                  metric={summary}
                  isLoading={isLoading}
                  compareMetric={compareSummaryData ?? undefined}
                  totalLabelColumn={totalLabelColumn}
                />
              ) : null}
            </table>
          </div>
          {showPagination ? (
            <div className="px-2 py-1.5 flex-grow-0 flex-shrink-0">
              <Pagination
                hideItemsPerPage={hideItemsPerPage ?? false}
                state={paginationState}
                total={totalPages ?? 1}
                onChangeState={state =>
                  setPagination(prev => ({ ...prev, ...state }))
                }
                onNext={table.nextPage}
                onPrevious={table.previousPage}
              />
            </div>
          ) : null}
        </div>
      </DndContext>
    </div>
  )
}
export default TableVisualization
