import { BaseSyntheticEvent } from "react"
import { Checkbox } from "../../controls"
import InfoDialog, { DIALOG } from "../InfoDialog"

export default function KeywordsChangesDialog({
  onClose,
  dontShowDialogAgain,
  setDontShowDialogAgain,
}: {
  onClose: () => void
  dontShowDialogAgain: boolean
  setDontShowDialogAgain: (val: boolean) => void
}) {
  return (
    <InfoDialog isOpen={true} handleClose={onClose} type={DIALOG.SUCCESS}>
      <>
        <div className="grid grid-cols-8 gap-4">
          <div>&nbsp;</div>
          <div className="col-span-7 text-start">
            Your changes have been saved.
            <br />
            Please note, that data will be updated in 24 hours
          </div>
        </div>
        <hr className="mt-3 mb-2 border-states-success" />
        <div className="grid grid-cols-8 gap-4">
          <div>
            <Checkbox
              checked={dontShowDialogAgain}
              onChange={(evt: BaseSyntheticEvent) =>
                setDontShowDialogAgain(evt.target.checked)
              }
              className="border-states-success bg-background-dark-shade text-states-success"
            />
          </div>
          <div className="col-span-7 text-start">Don't show this again</div>
        </div>
      </>
    </InfoDialog>
  )
}
