import { useState } from "react"
import { useAppSession } from "../../Auth/useCubeApiToken"
import useDepVar from "~/ui-rtk/hooks/dep-var"
import { BinaryOperator, Filter, Query, QueryOrder } from "@cubejs-client/core"
import { polyfillCubeQueryWithTimeDimension } from "~/ui-rtk/utils/cube"
import dayjs from "dayjs"
import { DATE_FORMAT } from "~/ui-rtk/hooks/period"

export type TCreativeReport =
  | "ads_creative_id"
  | "ads_instagram_media_id"
  | "ads_instagram_permalink_url"
  | "ads_instagram_story_id"
  | "ads_object_story_id"
  | "ads_root_ad_name"
  | "ads_thumbnail_url"
  | "ads_video_id"

export const CREATIVE_REPORT: Record<string, TCreativeReport> = {
  ADS_CREATIVE_ID: "ads_creative_id",
  ADS_INSTAGRAM_MEDIA_ID: "ads_instagram_media_id",
  ADS_INSTAGRAM_PERMALINK_URL: "ads_instagram_permalink_url",
  ADS_INSTAGRAM_STORY_ID: "ads_instagram_story_id",
  ADS_OBJECT_STORY_ID: "ads_object_story_id",
  ADS_ROOT_AD_NAME: "ads_root_ad_name",
  ADS_THUMBNAIL_URL: "ads_thumbnail_url",
  ADS_VIDEO_ID: "ads_video_id",
}

export const CREATIVE_REPORT_OPTIONS: {
  label: string
  value: TCreativeReport
  primaryId: string
  skipPrimaryId?: boolean
}[] = [
  {
    label: "Ads Creative Id",
    value: CREATIVE_REPORT.ADS_CREATIVE_ID,
    primaryId: "creative_id",
  },
  {
    label: "Ads Instagram Media Id",
    value: CREATIVE_REPORT.ADS_INSTAGRAM_MEDIA_ID,
    primaryId: "instagram_media_id",
  },
  {
    label: "Ads Instagram Permalink Url",
    value: CREATIVE_REPORT.ADS_INSTAGRAM_PERMALINK_URL,
    primaryId: "instagram_permalink_url",
  },
  {
    label: "Ads Instagram Story Id",
    value: CREATIVE_REPORT.ADS_INSTAGRAM_STORY_ID,
    primaryId: "instagram_story_id",
  },
  {
    label: "Ads Object Story Id",
    value: CREATIVE_REPORT.ADS_OBJECT_STORY_ID,
    primaryId: "object_story_id",
  },
  {
    label: "Ads Root Ad Name",
    value: CREATIVE_REPORT.ADS_ROOT_AD_NAME,
    primaryId: "root_ad_name",
  },
  {
    label: "Ads Thumbnail Url",
    value: CREATIVE_REPORT.ADS_THUMBNAIL_URL,
    primaryId: "thumbnail_url",
    skipPrimaryId: true,
  },
  {
    label: "Ads Video Id",
    value: CREATIVE_REPORT.ADS_VIDEO_ID,
    primaryId: "video_id",
  },
]

export const CREATIVE_REPORTS_METRICS_MAP = {
  brand_value_agg: "Brand Value",
  d30_brand_value_agg: "Brand Value 30d",
  d60_brand_value_agg: "Brand Value 60d",
  d90_brand_value_agg: "Brand Value 90d",
  d120_brand_value_agg: "Brand Value 120d",
  d150_brand_value_agg: "Brand Value 150d",
  brand_value_over_spend: "Brand Value ROAS",
  d30_brand_value_over_spend: "Brand Value 30d ROAS",
  d60_brand_value_over_spend: "Brand Value 60d ROAS",
  d90_brand_value_over_spend: "Brand Value 90d ROAS",
  d120_brand_value_over_spend: "Brand Value 120d ROAS",
  d150_brand_value_over_spend: "Brand Value 150d ROAS",
  facebook_purchases: "Facebook Purchases",
  facebook_purchase_value: "Facebook Purchase Value",
  facebook_spend: "Facebook Spend",
  instagram_purchases: "Instagram Purchases",
  instagram_purchase_value: "Instagram Purchase Value",
  instagram_spend: "Instagram Spend",
  total_purchases: "Total Purchases",
  total_purchase_value: "Total Purchase Value",
  total_spend: "Total Spend",
  total_purchase_value_over_spend: "Total Purchase Value Over Spend",
  ga4_total_revenue: "Ga4 Total Revenue",
  ga4_total_revenue_over_spend: "Ga4 Total Revenue Over Spend",
  facebook_comments: "Facebook Comments",
  facebook_likes: "Facebook Likes",
  facebook_shares: "Facebook Shares",
  facebook_follows: "Facebook Follows",
  facebook_saves: "Facebook Saves",
  instagram_comments: "Instagram Comments",
  instagram_likes: "Instagram Likes",
  instagram_shares: "Instagram Shares",
  instagram_follows: "Instagram Follows",
  instagram_business_profile_views: "Instagram Business Profile Views",
  instagram_saves: "Instagram Saves",
  ga4_sessions: "Ga4 Sessions",
  ga4_engaged_sessions: "Ga4 Engaged Sessions",
  total_comments: "Total Comments",
  total_likes: "Total Likes",
  total_shares: "Total Shares",
  total_follows: "Total Follows",
  total_saves: "Total Saves",
}

export const ALL_METRICS = Object.keys(CREATIVE_REPORTS_METRICS_MAP)

export type TMetricsMapKey = keyof typeof CREATIVE_REPORTS_METRICS_MAP

export const ALWAYS_METRICS: TMetricsMapKey[] = [
  "brand_value_agg",
  "total_spend",
]

export const DEFAULT_METRICS: TMetricsMapKey[] = [
  "total_purchase_value_over_spend",
  "total_purchases",
]

export const MONETARY_METRICS: TMetricsMapKey[] = [
  "brand_value_agg",
  "d30_brand_value_agg",
  "d60_brand_value_agg",
  "d90_brand_value_agg",
  "d120_brand_value_agg",
  "d150_brand_value_agg",
  "brand_value_over_spend",
  "d30_brand_value_over_spend",
  "d60_brand_value_over_spend",
  "d90_brand_value_over_spend",
  "d120_brand_value_over_spend",
  "d150_brand_value_over_spend",
  "facebook_purchase_value",
  "facebook_spend",
  "instagram_purchase_value",
  "instagram_spend",
  "total_purchase_value",
  "total_spend",
  "total_purchase_value_over_spend",
  "ga4_total_revenue",
  "ga4_total_revenue_over_spend",
]

export const ITEMS_PER_PAGE = 12
const results: Record<string, any> = {}

function getQWithTimeDimensionAndDepVarFilters(
  q: Query,
  prefix: string,
  dateRange: [Date, Date],
  {
    depVar,
    depVarScaler,
  }: {
    depVar: string
    depVarScaler: string[]
  },
) {
  return polyfillCubeQueryWithTimeDimension(
    {
      ...q,
      timeDimensions: [
        {
          dimension: `${prefix}.md_date`,
        },
      ],
      filters: [
        {
          dimension: `${prefix}.dep_var`,
          operator: "equals" as BinaryOperator,
          values: [depVar],
        },
        depVarScaler.length > 0
          ? {
              dimension: `${prefix}.dep_var_scaler`,
              operator: "equals" as BinaryOperator,
              values: depVarScaler,
            }
          : undefined,
      ].filter(f => !!f) as Filter[],
    },
    {
      dateRange,
    },
  )
}

const getBaseQuery = (prefix: string, metrics: TMetricsMapKey[]) => ({
  measures: [
    ...ALWAYS_METRICS.map(metric => `${prefix}.${metric}`),
    ...metrics.filter(metric => !!metric).map(metric => `${prefix}.${metric}`),
  ],
})

export default function useConnect() {
  const [reportingItems, setReportingItems] = useState<Array<
    Record<string, string>
  > | null>(null)
  const [reportingItemsSummary, setReportingItemsSummary] = useState<Record<
    string,
    string
  > | null>(null)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isSummaryLoading, setIsSummaryLoading] = useState(false)
  const { cubeApi } = useAppSession()
  const { depVar, depVarScaler } = useDepVar()

  const requestReportingItems = async (
    source: TCreativeReport,
    dateRange: [Date, Date],
    page = 0,
  ) => {
    const { primaryId, skipPrimaryId } =
      CREATIVE_REPORT_OPTIONS.find(({ value }) => value === source) ?? {}

    if (!primaryId) {
      throw new Error(`No Creative Report cube found: ${source}`)
    }

    setIsLoading(true)

    const cacheKey = `${source}.${dayjs(dateRange[0]).format(DATE_FORMAT)}-${dayjs(dateRange[1]).format(DATE_FORMAT)}-${page}`

    if (!results[cacheKey]) {
      const prefix = `company_media_metrics_${source}`
      const q = {
        dimensions: [
          `${prefix}.thumbnail_url`,
          `${prefix}.instagram_permalink_url`,
          `${prefix}.all_ads`,
        ],
        ...getBaseQuery(prefix, ALL_METRICS as TMetricsMapKey[]),
        order: [[`${prefix}.brand_value_agg`, "desc"] as [string, QueryOrder]],
        offset: page * ITEMS_PER_PAGE,
        limit: ITEMS_PER_PAGE,
        total: true,
      }
      if (!skipPrimaryId) {
        q.dimensions.push(`${prefix}.${primaryId}`)
      }

      const res = await cubeApi.load(
        getQWithTimeDimensionAndDepVarFilters(q, prefix, dateRange, {
          depVar,
          depVarScaler,
        }),
      )

      results[cacheKey] = {
        data: res.rawData().map(item => {
          const noPrefixItm: Record<string, string> = {}
          Object.keys(item).forEach(keyWithPrefix => {
            const keyNoPrefix = keyWithPrefix.replace(`${prefix}.`, "")
            noPrefixItm[keyNoPrefix] = item[keyWithPrefix]
          })
          return noPrefixItm
        }),
        totalPages: Math.ceil((res.totalRows() ?? 0) / ITEMS_PER_PAGE) || 1,
      }
    }

    setReportingItems(results[cacheKey].data)
    setTotalPages(results[cacheKey].totalPages)
    setIsLoading(false)
  }

  const requestReportingItemsSummary = async (
    source: TCreativeReport,
    dateRange: [Date, Date],
  ) => {
    setIsSummaryLoading(true)

    const cacheKey = `${source}.${dayjs(dateRange[0]).format(DATE_FORMAT)}-${dayjs(dateRange[1]).format(DATE_FORMAT)}-summary`

    if (!results[cacheKey]) {
      const prefix = `company_media_metrics_${source}`
      const q = {
        ...getBaseQuery(prefix, ALL_METRICS as TMetricsMapKey[]),
      }

      const res = await cubeApi.load(
        getQWithTimeDimensionAndDepVarFilters(q, prefix, dateRange, {
          depVar,
          depVarScaler,
        }),
      )

      results[cacheKey] = res.rawData().map(item => {
        const noPrefixItm: Record<string, string> = {}
        Object.keys(item).forEach(keyWithPrefix => {
          const keyNoPrefix = keyWithPrefix.replace(`${prefix}.`, "")
          noPrefixItm[keyNoPrefix] = item[keyWithPrefix]
        })
        return noPrefixItm
      })[0]
    }

    setReportingItemsSummary(results[cacheKey])
    setIsSummaryLoading(false)
  }

  return {
    isLoading: isLoading || isSummaryLoading,
    reportingItems,
    reportingItemsSummary,
    requestReportingItems,
    requestReportingItemsSummary,
    totalPages,
    depVar,
    depVarScaler,
  }
}
