import { Card } from "~/ui-rtk/components/ui/common"
import { Image } from "~/ui-rtk/components/ui/common/Image"
import { H3 } from "~/ui-rtk/components/ui/typography"
import {
  CREATIVE_REPORTS_METRICS_MAP,
  MONETARY_METRICS,
  TMetricsMapKey,
} from "../connect"
import { Fragment } from "react/jsx-runtime"
import { toCurrency, toNumber } from "~/ui-rtk/utils/number-utils"
import { Link } from "react-router-dom"

export default function CreativeReportCard({
  card,
  primaryIdKey,
  skipTitle,
  metricsNames,
  imgUrl,
}: {
  card: Record<TMetricsMapKey | string, string>
  skipTitle?: boolean
  primaryIdKey: string
  metricsNames: TMetricsMapKey[]
  imgUrl?: string
}) {
  const renderRow = (key: TMetricsMapKey) => {
    const val = MONETARY_METRICS.includes(key)
      ? toCurrency(card[key])
      : toNumber(card[key])
    return (
      <Fragment key={key}>
        <div>{CREATIVE_REPORTS_METRICS_MAP[key]}:</div>
        <div className="bg-basic-black p-2">{val}</div>
      </Fragment>
    )
  }

  return (
    <Card
      rounded
      className="border-basic-blue bg-basic-dark-blue p-3 flex flex-col justify-between"
    >
      <div className="flex items-center justify-center flex-grow flex-shrink-0">
        <Image
          src={imgUrl ?? card.thumbnail_url}
          fallback={card.thumbnail_url}
          alt={card[primaryIdKey]}
        />
      </div>
      <div className="mt-3">
        {!skipTitle && <H3>{card[primaryIdKey]}</H3>}
        <div className="mt-3 grid grid-cols-2 gap-3 items-center">
          {metricsNames.map(metric => renderRow(metric))}
        </div>
        <div className="mt-2 flex justify-center items-center h-5">
          {card.instagram_permalink_url && (
            <Link
              to={card.instagram_permalink_url}
              target="_blank"
              className="text-brand"
            >
              View Ad
            </Link>
          )}
        </div>
      </div>
    </Card>
  )
}
