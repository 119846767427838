import { useMemo, useState } from "react"
import {
  CreateCheckoutSessionDto,
  useSubscriptionControllerCreateCheckoutSessionMutation,
} from "~/ui-rtk/api/subscriptionApi"
import getAnnualRevenue from "~/ui-rtk/hooks/get-anunual-revenue.ts"
import { type Query as TCubeQuery } from "@cubejs-client/core"
import { useCubeQuery } from "~/ui-rtk/hooks/cube"
import { getPivotData } from "~/ui-rtk/utils/cube"
import {
  generalPostProcess,
  MetricsMapping,
} from "~/ui-rtk/constants/metrics-mapping"

type TRevenueMappintItem = {
  upTo?: number
  revenueLbl: string
  price: number
  else?: boolean
  isSelected?: boolean
}

const REVENUE_MAPPINGS: TRevenueMappintItem[] = [
  {
    upTo: 1000000,
    revenueLbl: "< $1M",
    price: 495,
  },
  {
    upTo: 2500000,
    revenueLbl: "$1M to $2.5M",
    price: 595,
  },
  {
    upTo: 5000000,
    revenueLbl: "$2.5M to $5M",
    price: 850,
  },
  {
    upTo: 7500000,
    revenueLbl: "$5M to $7.5M",
    price: 1150,
  },
  {
    upTo: 10000000,
    revenueLbl: "$7.5M to $10M",
    price: 1450,
  },
  {
    upTo: 15000000,
    revenueLbl: "$10M to $15M",
    price: 1950,
  },
  {
    upTo: 20000000,
    revenueLbl: "$15M to $20M",
    price: 2350,
  },
  {
    upTo: 25000000,
    revenueLbl: "$20M to $25M",
    price: 2750,
  },
  {
    upTo: 30000000,
    revenueLbl: "$25M to $30M",
    price: 3150,
  },
  {
    else: true,
    revenueLbl: "> $30M",
    price: 3950,
  },
]

function queryTheCube(query: TCubeQuery) {
  const { resultSet, isLoading } = useCubeQuery(query)

  const dataSource = useMemo(() => {
    if (!resultSet) {
      return null
    }

    const columns = resultSet.tableColumns()
    const pivotData = getPivotData(resultSet, query)
    return generalPostProcess(pivotData, columns)
  }, [resultSet])

  return { isLoading, dataSource }
}

export default function useConnect() {
  const [createCheckoutSessionAsync] =
    useSubscriptionControllerCreateCheckoutSessionMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { revenue, isLoading } = getAnnualRevenue()

  const revenueMapping = useMemo(() => {
    const map = [...REVENUE_MAPPINGS]

    const revMapIdx = map.findIndex(rev => {
      if (isLoading) {
        return true
      }
      if (!revenue) {
        return true
      }
      if ((rev.upTo && rev.upTo > revenue) || rev.else) {
        return true
      }
      return false
    })
    if (revMapIdx >= 0) {
      map[revMapIdx].isSelected = true
    }
    return map
  }, [revenue, isLoading])

  const createCheckoutSession = async (dto: CreateCheckoutSessionDto) => {
    setIsSubmitting(true)
    try {
      const { checkoutUrl } = await createCheckoutSessionAsync({
        createCheckoutSessionDto: dto,
      }).unwrap()

      window.location.href = checkoutUrl
    } finally {
      setIsSubmitting(false)
    }
  }

  const { dataSource: organicMedia, isLoading: isLoadingOrganicMedia } =
    queryTheCube({
      ...MetricsMapping.subscribe_page_organic_media_card.q,
    } as TCubeQuery)

  const { dataSource: paidMedia, isLoading: isLoadingPaidMedia } = queryTheCube(
    {
      ...MetricsMapping.subscribe_page_paid_media_card.q,
    } as TCubeQuery,
  )

  const { dataSource: brandValue, isLoading: isLoadingBrandValue } =
    queryTheCube({
      ...MetricsMapping.subscribe_page_brand_value_card.q,
    } as TCubeQuery)

  return {
    createCheckoutSession,
    isSubmitting,
    organicMedia,
    isLoadingOrganicMedia,
    paidMedia,
    isLoadingPaidMedia,
    brandValue,
    isLoadingBrandValue,
    revenueMapping,
    isLoading,
  }
}
