import { type Query as TCubeQuery } from "@cubejs-client/core"
import { useCheckServiceIsConnected } from "~/ui-rtk/hooks/connected-services.ts"
import { useYearDateRange } from "~/ui-rtk/hooks/date-range.ts"
import useDepVar from "~/ui-rtk/hooks/dep-var.ts"
import { useCubeQuery } from "@cubejs-client/react"
import { CONNECTORS_IDS } from "~/ui-rtk/constants/sources.ts"

export default function getAnnualRevenue() {
  const [prevYear, now] = useYearDateRange()
  const { depVar } = useDepVar()

  const isAmazonPartnerConnected = useCheckServiceIsConnected(
    CONNECTORS_IDS.AMAZON_SELLING_PARTNER,
  )
  const isAmazonVendorConnected = useCheckServiceIsConnected(
    CONNECTORS_IDS.AMAZON_SELLING_PARTNER_VENDOR,
  )

  const isAmazonConnected = isAmazonPartnerConnected || isAmazonVendorConnected

  let revenueSource = null
  switch (depVar) {
    case "ga_revenue_from_organic_sessions":
      revenueSource = isAmazonConnected
        ? "ga_amazon_total_revenue"
        : "ga_revenue"
      break
    case "ga_multi_property_baseline_revenue":
      revenueSource = isAmazonConnected
        ? "ga_multi_property_amazon_total_revenue"
        : "ga_multi_property_revenue"
      break
    case "shopify_baseline_revenue":
      revenueSource = isAmazonConnected
        ? "shopify_amazon_total_revenue"
        : "shopify_revenue"
      break
    case "shopify_baseline_plus_subscription_revenue":
      revenueSource = isAmazonConnected
        ? "shopify_plus_subscription_amazon_total_revenue"
        : "shopify_subscription_total_revenue"
      break
  }

  const query: TCubeQuery = {
    dimensions: ["metrics.companyid"],
    measures: [`metrics.${revenueSource}`],
    filters: [
      {
        member: "metrics.date",
        operator: "inDateRange",
        values: [prevYear.toString(), now.toString()],
      },
    ],
  }

  const { resultSet, isLoading } = useCubeQuery(query)

  const data: Record<string, string> = resultSet?.rawData()[0]

  let revenue

  if (data) {
    revenue = Math.floor(parseInt(Object.values(data)[1], 10))
  }

  return {
    revenue,
    isLoading,
  }
}
