import {
  useRef,
  useMemo,
  useCallback,
  useEffect,
  useState,
  CSSProperties,
  SetStateAction,
  Dispatch,
} from "react"
import get from "lodash/get"
import {
  Area,
  CartesianGrid,
  Line,
  ComposedChart as ReComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import {
  AggregationType,
  CHARTS_LOADING_DATA,
  TOTAL_CALCULATED_DATA_KEY,
  UNKNOWN_DATA_KEY,
} from "~/ui-rtk/constants/charts"
import { useResizeObserver } from "~/ui-rtk/utils/resize-observer"
import { formatNumber } from "~/ui-rtk/utils/format"
import useTailwindTheme from "~/ui-rtk/hooks/tailwind-theme"
import ChartTooltipContent, {
  type TTootlipNumberProps,
} from "../ComposedChart/ChartTooltipContent"
import {
  BRAND_COLORS_MAP,
  getColorForMetric,
  getColorFromPaletteByIdx,
  getLightColor,
  getTickFormatter,
} from "~/ui-rtk/utils/chart-utils"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { toNumber } from "~/ui-rtk/utils/number-utils"
import { TCustomSource } from "~/ui-rtk/pages/BrandHealth/Overview/connect"
import ValueWithDelta from "../../common/ValueWithDelta/ValueWithDelta"
import { TGraphProps } from "../ComposedChart/ComposedChart"

import useDate from "~/ui-rtk/hooks/date"
import { Cog8ToothIcon } from "@heroicons/react/24/outline"

const dayjs = useDate()

export const isMetricHighlighted = (dataKey: string, highlightKey?: string) => {
  if (!highlightKey) {
    return false
  }
  return highlightKey === dataKey
}

const getCustomTotal = (
  dataKeys: string[],
  allTotalsValues?: [string, number][],
) => {
  if (!allTotalsValues) {
    return 0
  }
  const allTotalsMap = new Map(
    allTotalsValues.map(([id, value]) => [id, value]),
  )
  return dataKeys.reduce(
    (acc: number, dataKey: string) => acc + (allTotalsMap?.get(dataKey) ?? 0),
    0,
  )
}

const defaultTooltipCoords = {
  x: -1,
  y: -1,
}

function getOffset(ev: MouseEvent) {
  const coords: {
    x: number
    y: number
  } = defaultTooltipCoords
  if (ev.layerX || ev.layerX === 0) {
    // Firefox
    coords.x = ev.layerX
    coords.y = ev.layerY
  } else if (ev.offsetX || ev.offsetX === 0) {
    // Chrome & Opera
    coords.x = ev.offsetX
    coords.y = ev.offsetY
  }

  return coords
}

const aggFunc = (
  data: Array<{ date: number; [key: string]: number }>,
  dataKey: string,
  aggType:
    | "sum"
    | "avg"
    | "percent-total"
    | "percent-change"
    | "percent-average",
  graphs: IGraphProps[],
  yAxisId?: string,
) => {
  const length = data.length
  const sum = ["sum", "avg", "percent-total", "percent-average"].includes(
    aggType,
  )
    ? data.reduce(
        (sum: number, dp) =>
          isNaN(get(dp, dataKey)) ? sum : sum + get(dp, dataKey),
        0,
      )
    : 0

  switch (aggType) {
    case "avg":
      return sum / length
    case "percent-total": {
      const axisIdKeys = graphs
        .filter(graph => !yAxisId || graph.yAxisId === yAxisId)
        .map(graph => graph.dataKey)
      const axisIdTotal = data.reduce(
        (total: number, dp) =>
          total +
          axisIdKeys.reduce(
            (sum, key) => (isNaN(get(dp, dataKey)) ? sum : sum + get(dp, key)),
            0,
          ),
        0,
      )
      return (sum / axisIdTotal) * 100
    }
    case "percent-average": {
      return sum / length
    }
    case "percent-change":
      return length > 0 ? (data[length - 1].value / data[0].value - 1) * 100 : 0
    default:
      return sum
  }
}

const ETooltipTrigger = {
  CLICK: "click",
  HOVER: "hover",
} as const

export interface IGraphProps {
  type: "line" | "area" | "dashed"
  unit?: string
  pluralizeUnit?: boolean
  dataKey: string
  color?: string
  requiredSources?: string[]
  label?: string
  stacked?: boolean
  labelAggType?:
    | "sum"
    | "avg"
    | "percent-total"
    | "percent-change"
    | "percent-average"
  numberType?: "default" | "currency"
  yAxisId?: string
  isTotal?: boolean
}

export type TComposedSimpleChartProps = {
  title?: string
  chartId?: string
  compareMode?: boolean
  compareUnit?: string
  comparePluralizeUnit?: boolean
  compareTitle?: string
  sources?: Map<
    string,
    {
      name: string
      icon: string
      id: string
      color: string
    }
  >
  compareNumberType?: "default" | "currency"
  compareLabelAggType?: "sum" | "avg" | "percent-change"
  dataAggType?: AggregationType
  metrics: any[]
  graphs: IGraphProps[]
  graphsFromColumn?: {
    column: string
    type: "line" | "area" | "dashed"
    label: string
    unit?: string
    pluralizeUnit?: boolean
    labelAggType?:
      | "sum"
      | "avg"
      | "percent-total"
      | "percent-change"
      | "percent-average"
    numberType?: "default" | "currency"
  }
  state?: string
  isCompareMetricMain?: boolean
  className?: string
  highlightKey?: string
  stacked?: boolean
  manualTotal?: boolean
  requiredSources?: string[]
  customSources?: TCustomSource[]
  showPostsLink?: boolean
  showAdsLink?: boolean
  sortBy?: string
  totalLabelProps?: TGraphProps
  hideSources?: boolean
  hideTooltip?: boolean
  hideTotals?: boolean
  hideQuarterReferences?: boolean
  tooltipProps?: TTootlipNumberProps
  customTotals?: Array<{
    label: string
    dataKey: string
    dataKeysToSum: string[]
  }>
  blurTitle?: boolean
  selectedSources?: string[]
  onSettingClick?: () => void
  setSelectedSources?: Dispatch<SetStateAction<Array<string>>>
}

export default function ComposedSimpleChart({
  title,
  chartId = "default-chart",
  compareMode = false,
  metrics,
  sources,
  graphs: graphsProps,
  graphsFromColumn,
  state = "idle",
  isCompareMetricMain = false,
  className,
  highlightKey,
  stacked = false,
  manualTotal = false,
  requiredSources = [],
  showPostsLink = false,
  showAdsLink = false,
  hideSources = false,
  hideTooltip = false,
  hideQuarterReferences = false,
  customSources,
  totalLabelProps,
  tooltipProps,
  hideTotals: hideTotalsFromProps = false,
  customTotals = [],
  blurTitle = false,
  selectedSources = [],
  setSelectedSources = () => null,
  onSettingClick,
}: TComposedSimpleChartProps) {
  const theme = useTailwindTheme()
  const mainMetricPrefix = isCompareMetricMain ? "compared." : ""
  const comparedMetricPrefix = isCompareMetricMain ? "" : "compared."

  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number
    y: number
  }>(defaultTooltipCoords)
  const [tooltipTrigger, setTooltipTrigger] = useState<
    (typeof ETooltipTrigger)[keyof typeof ETooltipTrigger]
  >(ETooltipTrigger.HOVER)

  const customSourcesMode = useMemo(
    () => (customSources?.length ?? 0) > 0,
    [customSources],
  )

  const refEl = useRef<HTMLElement>(document.body)
  const size = useResizeObserver({ ref: refEl })
  const chartWidth = (size.width || refEl.current?.clientWidth) ?? 0

  const formatTick = getTickFormatter()

  const usedSources = useMemo(
    () =>
      customSourcesMode
        ? customSources
            ?.filter(({ requiredSource }) => sources?.has(requiredSource))
            .map((customSource, idx) => ({
              ...customSource,
              id: customSource.dataKey,
              icon: sources?.get(customSource.requiredSource)?.icon,
              color: getColorForMetric(customSource.dataKey, idx),
            }))
        : requiredSources
            .filter(source => sources?.has(source))
            .map(source => sources?.get(source)),
    [requiredSources, customSourcesMode, customSources],
  )

  const customTotalsFiltered = useMemo(
    () =>
      (customTotals ?? []).filter(customTotal =>
        selectedSources?.some(selectedDataKey =>
          customTotal.dataKeysToSum.includes(selectedDataKey),
        ),
      ),
    [selectedSources, customTotals],
  )

  const graphsRaw = useMemo(() => {
    if (graphsProps) {
      return graphsProps
    } else if (!graphsFromColumn) {
      throw new Error("graphs or graphsFromColumn must be defined")
    } else if (!metrics?.length) {
      return []
    }

    return Object.keys(metrics[0][graphsFromColumn?.column] ?? {})
      .map<IGraphProps>((key, idx) => ({
        type: graphsFromColumn?.type ?? "line",
        dataKey: `${graphsFromColumn?.column}.${key}`,
        label: key,
        numberType: graphsFromColumn?.numberType ?? "default",
        unit: graphsFromColumn?.unit,
        pluralizeUnit: graphsFromColumn?.pluralizeUnit ?? true,
        labelAggType: graphsFromColumn?.labelAggType ?? "sum",
        color: getLightColor(getColorFromPaletteByIdx(idx)),
      }))
      .concat(
        Object.keys(
          metrics[0][UNKNOWN_DATA_KEY][graphsFromColumn?.column],
        ).map<IGraphProps>(key => ({
          type: graphsFromColumn?.type ?? "line",
          dataKey: `${UNKNOWN_DATA_KEY}.${graphsFromColumn?.column}.${key}`,
          label: `Unknown: ${key}`,
          numberType: graphsFromColumn?.numberType ?? "default",
          unit: graphsFromColumn?.unit,
          pluralizeUnit: graphsFromColumn?.pluralizeUnit ?? true,
          color: BRAND_COLORS_MAP.UNKNOWN,
          labelAggType: graphsFromColumn?.labelAggType ?? "sum",
        })),
      )
  }, [graphsProps, graphsFromColumn, metrics])

  const noData =
    metrics?.length === 0 ||
    !metrics?.some(entry =>
      graphsRaw.some(({ dataKey }) => get(entry, dataKey) !== undefined),
    )

  const allSourceTotalsMap = useMemo(() => {
    const allTotalsValues: [string, number][] = (usedSources ?? []).map(
      source => {
        const value = noData
          ? 0
          : graphsRaw
              // get graphs connected to given source
              .reduce((acc: IGraphProps[], graph) => {
                if (customSourcesMode) {
                  if (graph.dataKey === (source as TCustomSource).dataKey) {
                    return [...acc, graph]
                  }
                  return acc
                }
                if (
                  graph.requiredSources?.includes(
                    source?.id ?? "unknown-source",
                  ) &&
                  graph.dataKey !== TOTAL_CALCULATED_DATA_KEY
                ) {
                  return [...acc, graph]
                }
                return acc
              }, [])
              // calculate value for each graph
              .map(item => {
                const aggregatedValues =
                  aggFunc(
                    metrics,
                    `${mainMetricPrefix}${item.dataKey}`,
                    item.labelAggType ?? "sum",
                    graphsRaw,
                    item.yAxisId,
                  ) ?? 0

                return aggregatedValues
              })
              // summing it up
              .reduce((acc, agg: number) => acc + agg, 0)

        return [source?.id ?? "unknown-source", value]
      },
    )

    return new Map(allTotalsValues.map(([id, value]) => [id, value]))
  }, [
    graphsRaw,
    metrics,
    mainMetricPrefix,
    usedSources,
    customSourcesMode,
    noData,
  ])

  const graphs = useMemo(() => {
    const $graphs = graphsRaw.map((graph, idx) => ({
      ...graph,
      color: graph.color ?? getColorForMetric(graph.dataKey, idx),
    }))

    return $graphs.filter(graph => {
      if (!manualTotal && graph.dataKey === TOTAL_CALCULATED_DATA_KEY) {
        return false
      }

      const aggregatedValues =
        aggFunc(
          metrics,
          `${mainMetricPrefix}${graph.dataKey}`,
          graph.labelAggType ?? "sum",
          graphsRaw,
          graph.yAxisId,
        ) ?? 0

      if (aggregatedValues === 0) {
        return false
      }

      if (customSourcesMode) {
        return selectedSources.some(
          selectedSource => selectedSource === graph.dataKey,
        )
      }
      const requiredSources = graph.requiredSources ?? []
      return requiredSources.every(source => selectedSources.includes(source))
    })
  }, [graphsRaw, selectedSources, manualTotal])

  const dataLabels = useMemo(
    () =>
      new Map(
        graphs.map((graph, idx) => [
          graph.dataKey,
          {
            label: graph.label ?? graph.dataKey,
            numberType: graph.numberType,
            unit: graph.unit,
            order: idx,
            dataKey: graph.dataKey,
            pluralizeUnit: graph.pluralizeUnit ?? true,
          },
        ]),
      ),
    [graphs],
  )

  const selectedSourceTotalsMap = useMemo(() => {
    if (graphs.length < 1) {
      return new Map()
    }
    const allTotalMapEntries = Array.from(allSourceTotalsMap.entries()).filter(
      ([id]) => selectedSources.includes(id),
    )

    const allTotals = [
      ...(allTotalMapEntries ?? []),
      ...(customTotalsFiltered ?? []).map(customTotal => [
        customTotal.dataKey,
        getCustomTotal(customTotal.dataKeysToSum, allTotalMapEntries),
      ]),
      [
        TOTAL_CALCULATED_DATA_KEY,
        allTotalMapEntries?.reduce((acc, agg) => acc + agg[1], 0),
      ],
    ]
    return new Map(allTotals.map(([id, value]) => [id, value]))
  }, [graphs, allSourceTotalsMap])

  const selectedSourceComparedTotalsMap = useMemo(() => {
    if (!compareMode) {
      return new Map()
    }

    const allComparedTotalsValues: [string, number][] | undefined = usedSources
      ?.filter(source =>
        selectedSources.includes(source?.id ?? "unknown-source"),
      )
      .map(source => {
        const value = noData
          ? 0
          : graphs
              // get graphs connected to given source
              .reduce((acc: IGraphProps[], graph) => {
                if (customSourcesMode) {
                  if (graph.dataKey === (source as TCustomSource).dataKey) {
                    return [...acc, graph]
                  }
                  return acc
                }
                if (
                  graph.requiredSources?.includes(
                    source?.id ?? "unknown-source",
                  ) &&
                  graph.dataKey !== TOTAL_CALCULATED_DATA_KEY
                ) {
                  return [...acc, graph]
                }
                return acc
              }, [])
              // calculate value for each graph
              .map(
                item =>
                  aggFunc(
                    metrics,
                    `${comparedMetricPrefix}${item.dataKey}`,
                    item.labelAggType ?? "sum",
                    graphs,
                    item.yAxisId,
                  ) ?? 0,
              )
              // summing it up
              .reduce((acc, agg: number) => acc + agg, 0)

        return [source?.id ?? "unknown-source", value]
      })

    const allComparedTotals = [
      ...(allComparedTotalsValues ?? []),
      ...(customTotalsFiltered ?? []).map(customTotal => [
        customTotal.dataKey,
        getCustomTotal(customTotal.dataKeysToSum, allComparedTotalsValues),
      ]),
      [
        TOTAL_CALCULATED_DATA_KEY,
        allComparedTotalsValues?.reduce((acc, agg) => acc + agg[1], 0) ?? 0,
      ],
    ]
    return new Map(allComparedTotals.map(([id, value]) => [id, value]))
  }, [graphs, metrics, comparedMetricPrefix, compareMode, usedSources])

  const yAxis = useMemo(() => {
    const axisMap = new Set(
      graphs.map(({ yAxisId }) => yAxisId).filter(id => id),
    )
    return Array.from(axisMap)
  }, [graphs])

  const settingsIcon = useMemo(() => {
    if (!onSettingClick) {
      return null
    }
    return (
      <Cog8ToothIcon
        className="w-10 h-10 text-white/50 hover:text-white cursor-pointer"
        onClick={onSettingClick}
      />
    )
  }, [onSettingClick])

  const handleSetSelected = useCallback(
    (source: string) => {
      if (selectedSources.includes(source)) {
        selectedSources.splice(selectedSources.indexOf(source), 1)
        setSelectedSources([...selectedSources])
      } else {
        setSelectedSources([...selectedSources, source])
      }
    },
    [selectedSources],
  )

  const handleChartClick = useCallback(
    (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!showPostsLink && !showAdsLink) {
        return
      }
      if (tooltipTrigger === ETooltipTrigger.CLICK) {
        setTooltipTrigger(ETooltipTrigger.HOVER)
        setTooltipPosition(defaultTooltipCoords)
      } else {
        setTooltipTrigger(ETooltipTrigger.CLICK)
        const coords = getOffset(evt.nativeEvent)
        setTooltipPosition(coords)
      }
    },
    [setTooltipPosition, setTooltipTrigger, tooltipTrigger],
  )

  useEffect(() => {
    if (usedSources) {
      setSelectedSources(
        usedSources.map(source => source?.id ?? "").filter(id => id.length > 0),
      )
    }
  }, [chartId])

  const showTotals = !noData
  const totalsLabel =
    usedSources?.length === selectedSources.length ? "Total" : "Subtotal"

  const composedChartStyle = useMemo(
    () =>
      state !== "idle" || noData
        ? { opacity: 0.15, filter: "grayscale(1)" }
        : undefined,
    [state, noData],
  )

  const cartesianGridStyles = useMemo(
    () => ({ stroke: theme.colors["gray-dark"] }),
    [theme],
  )

  const yAxisStyle = useMemo(
    () => ({
      fontSize: `${theme.fontSize.sm}`,
      textAnchor: "end" as CSSProperties["textAnchor"],
      transform: "translate(-10px, 10px)",
    }),
    [theme],
  )

  const usedSourcesFiltered = useMemo(
    () =>
      usedSources?.filter(source => {
        if (!source) {
          return false
        }
        const sourceTotal = allSourceTotalsMap.get(source.id)
        return sourceTotal && Number(sourceTotal)
      }),
    [usedSources],
  )

  return (
    <div
      className={cn(
        "composed-simple-chart relative flex flex-col justify-between composed-chart",
        className,
      )}
    >
      <div className="flex justify-start gap-3 mt-1">
        {!hideSources && !hideTotalsFromProps && showTotals && (
          <div className="cursor-pointer min-w-35 min-h-28 p-3 rounded-md border border-basic-blue bg-brand/[.33]">
            <div className="flex justify-between">
              <div className="text-2xl leading-[1.6rem]">Σ</div>
              {compareMode &&
                selectedSourceComparedTotalsMap.has(
                  TOTAL_CALCULATED_DATA_KEY,
                ) &&
                selectedSourceComparedTotalsMap.has(
                  TOTAL_CALCULATED_DATA_KEY,
                ) && (
                  <div
                    className={`bg-background-dark-shade rounded-md p-0.5 text-sm`}
                  >
                    <ValueWithDelta
                      actualValue={selectedSourceTotalsMap.get(
                        TOTAL_CALCULATED_DATA_KEY,
                      )}
                      comparedValue={selectedSourceComparedTotalsMap.get(
                        TOTAL_CALCULATED_DATA_KEY,
                      )}
                      hideActualValue
                    />
                  </div>
                )}
            </div>
            <div className="mt-1 text-sm font-bold text-gray">
              {totalsLabel}
            </div>
            <div className="mt-4 text-xl text-white text-bold">
              {toNumber(selectedSourceTotalsMap.get(TOTAL_CALCULATED_DATA_KEY))}
            </div>
          </div>
        )}
        {!hideSources &&
          customTotalsFiltered?.map(
            customTotal =>
              customTotal && (
                <div
                  className={`cursor-pointer min-w-35 min-h-27.5 p-3 rounded-md border border-basic-blue bg-brand/[.33]`}
                  key={customTotal.dataKey}
                >
                  <div className="flex justify-between">
                    <div className="text-2xl leading-[1.6rem]">Σ</div>
                    {compareMode &&
                      selectedSourceTotalsMap.has(customTotal.dataKey) &&
                      selectedSourceComparedTotalsMap.has(
                        customTotal.dataKey,
                      ) && (
                        <div
                          className={`bg-background-dark-shade rounded-md p-[2px] text-sm`}
                        >
                          <ValueWithDelta
                            actualValue={selectedSourceTotalsMap.get(
                              customTotal.dataKey,
                            )}
                            comparedValue={selectedSourceComparedTotalsMap.get(
                              customTotal.dataKey,
                            )}
                            hideActualValue
                          />
                        </div>
                      )}
                  </div>
                  <div className="mt-1 text-sm text-gray">
                    {customTotal.label}
                  </div>
                  <div className="mt-4 text-xl text-white text-bold">
                    {selectedSourceTotalsMap.has(customTotal.dataKey)
                      ? toNumber(
                          selectedSourceTotalsMap.get(customTotal.dataKey),
                        )
                      : "-"}
                  </div>
                </div>
              ),
          )}
        {!hideSources &&
          usedSourcesFiltered?.map(source => {
            if (!source) {
              return null
            }

            const sourceTotal = allSourceTotalsMap.get(source.id) ?? 0

            return (
              <div
                className={`cursor-pointer min-w-35 min-h-27.5 p-3 rounded-md border border-basic-blue ${selectedSources.includes(source.id) ? "bg-dark-blue" : "bg-transparent"}`}
                onClick={() => handleSetSelected(source?.id ?? "")}
                key={source.id}
              >
                <div className="flex justify-between">
                  <div>
                    <img
                      key={source.id}
                      src={source.icon}
                      alt={source.name}
                      title={source.name}
                      width={24}
                      height={24}
                      className="object-cover rounded-sm"
                    />
                  </div>
                  {compareMode &&
                    selectedSourceTotalsMap.has(source.id) &&
                    selectedSourceComparedTotalsMap.has(source.id) && (
                      <div
                        className={`bg-background-dark-shade rounded-md p-0.5 text-sm`}
                      >
                        <ValueWithDelta
                          actualValue={sourceTotal}
                          comparedValue={selectedSourceComparedTotalsMap.get(
                            source.id,
                          )}
                          hideActualValue
                        />
                      </div>
                    )}
                </div>
                <div className="mt-1 text-sm text-gray">{source.name}</div>

                <div className="flex justify-between items-center mt-4">
                  <div className="text-xl text-white text-bold">
                    {selectedSourceTotalsMap.has(source.id)
                      ? toNumber(sourceTotal)
                      : "-"}
                  </div>
                  <div className="w-5">
                    <div
                      className="w-full h-0.5 rounded"
                      style={{
                        backgroundColor: source.color,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )
          })}
        {settingsIcon}
      </div>
      <div
        className="flex items-end mt-4 grow shrink-0 basis-auto"
        onClick={handleChartClick}
      >
        <ResponsiveContainer
          key={`chart-${chartWidth}-${size.height}-${noData ? "no-data" : ""}`}
          width="100%"
          minHeight={85}
        >
          <ReComposedChart
            data={state !== "idle" || noData ? CHARTS_LOADING_DATA : metrics}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            style={composedChartStyle}
          >
            {!hideQuarterReferences &&
              metrics
                .filter(({ date }) =>
                  dayjs(date).isSame(dayjs(date).startOf("quarter")),
                )
                .map(({ date }, index) => (
                  <ReferenceLine
                    key={index}
                    x={date}
                    strokeDasharray="2 2"
                    stroke={theme.colors["gray-light"]}
                    label={{
                      value: `Q${dayjs(date).quarter()}`,
                      position: "bottom",
                      style: {
                        transform: "translateY(14px)",
                        fontSize: `${theme.fontSize.sm}`,
                      },
                    }}
                  />
                ))}
            <CartesianGrid
              strokeDasharray="3 0"
              vertical={false}
              style={cartesianGridStyles}
            />
            <XAxis
              xAxisId={0}
              dataKey="date"
              tickFormatter={formatTick}
              interval="preserveStart"
              minTickGap={30}
              className="text-sm"
            />
            <XAxis xAxisId={1} dataKey="comparedDate" tick={false} hide />
            {yAxis.map(id => (
              <YAxis key={id} yAxisId={id} tick={false} hide />
            ))}
            <YAxis
              domain={[0, "dataMax"]}
              orientation="right"
              interval="preserveEnd"
              padding={{ top: 0, bottom: 0 }}
              width={0.01}
              axisLine={false}
              tickLine={false}
              style={yAxisStyle}
              tickFormatter={(value, index) =>
                index !== 0 || value > 0
                  ? `${formatNumber(value, { compact: true })}`
                  : ""
              }
            />
            {graphs?.map(
              ({
                type = "line",
                dataKey,
                color,
                requiredSources,
                stacked: graphStacked,
                yAxisId,
              }) => {
                // hide chart if source is not connected
                if (
                  requiredSources &&
                  !requiredSources.some(source => sources?.has(source))
                ) {
                  return null
                }

                const mainDataKey = `${mainMetricPrefix}${dataKey}`

                switch (type) {
                  case "area":
                    return (
                      <Area
                        type="monotone"
                        key={mainDataKey}
                        dataKey={mainDataKey}
                        strokeWidth={2}
                        stroke={color}
                        fill={color}
                        stackId={stacked || graphStacked ? 1 : undefined}
                        fillOpacity={
                          isMetricHighlighted(mainDataKey, highlightKey)
                            ? 0.75
                            : 0.25
                        }
                        isAnimationActive={true}
                        animationDuration={1500}
                        animationBegin={0}
                        yAxisId={yAxisId}
                      />
                    )
                  case "line":
                    return (
                      <Line
                        type="monotone"
                        key={mainDataKey}
                        dataKey={mainDataKey}
                        dot={{ r: 1 }}
                        strokeWidth={2}
                        stroke={color}
                        fill={color}
                        fillOpacity={0.25}
                        isAnimationActive={true}
                        animationDuration={1500}
                        animationBegin={0}
                        yAxisId={yAxisId}
                      />
                    )
                  case "dashed":
                    return (
                      <Line
                        type="monotone"
                        key={mainDataKey}
                        dataKey={mainDataKey}
                        dot={false}
                        strokeWidth={2}
                        strokeDasharray="6 6"
                        stroke={color}
                        fill={color}
                        fillOpacity={0.25}
                        isAnimationActive={true}
                        animationDuration={1500}
                        animationBegin={0}
                        yAxisId={yAxisId}
                      />
                    )
                }
                return null
              },
            )}
            {compareMode &&
              graphs?.map(
                ({
                  dataKey,
                  color,
                  requiredSources,
                  stacked: graphStacked,
                }) => {
                  // hide chart if source is not connected
                  if (
                    requiredSources &&
                    !requiredSources.some(source => sources?.has(source))
                  ) {
                    return null
                    // hide chart if source is not focused
                  }

                  return (
                    <Area
                      type="monotone"
                      key={`${comparedMetricPrefix}${dataKey}`}
                      dataKey={`${comparedMetricPrefix}${dataKey}`}
                      dot={false}
                      strokeWidth={2}
                      strokeDasharray="6 6"
                      stroke={color}
                      fill={color}
                      fillOpacity={0}
                      strokeOpacity={0.5}
                      stackId={stacked || graphStacked ? 2 : undefined}
                      isAnimationActive={true}
                      animationDuration={1500}
                      animationBegin={0}
                      z={2}
                    />
                  )
                },
              )}
            {state === "idle" && !noData && !hideTooltip ? (
              <Tooltip
                animationDuration={0}
                wrapperStyle={{ zIndex: 1000, pointerEvents: "auto" }}
                trigger={tooltipTrigger}
                coordinate={
                  tooltipTrigger === ETooltipTrigger.CLICK
                    ? {
                        x: tooltipPosition.x,
                      }
                    : undefined
                }
                content={({ active, payload }) => {
                  if (!active || !payload || !payload.length) {
                    return null
                  }
                  return (
                    <ChartTooltipContent
                      active={active}
                      payload={payload}
                      title={title}
                      dataLabels={dataLabels}
                      showTotal={!manualTotal && !hideTotalsFromProps}
                      isCompareMetricMain={isCompareMetricMain}
                      compareMode={compareMode}
                      highlightKey={highlightKey}
                      totalLabelConfig={totalLabelProps}
                      ignoreDecimals
                      tooltipProps={tooltipProps}
                      blurTitle={blurTitle}
                    />
                  )
                }}
              />
            ) : null}
          </ReComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
