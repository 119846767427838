import { useState } from "react"
import * as yup from "yup"
import { useCompanyGoalControllerCreateCompanyGoalMutation } from "~/ui-rtk/api/companyGoalApi"
import {
  COMPARE_TO,
  GOAL_GROWTH_TYPE,
  metricsOptions,
  type TGoalForm,
} from "./constants"
import useDate from "~/ui-rtk/hooks/date"

const dayjs = useDate()

export const validationSchema = yup.object().shape({
  title: yup.string().required("Please fill in a title"),
  description: yup.string().optional(),
  notes: yup.string().optional(),
  metric: yup.string().required("Please select a metric"),
  goalGrowthType: yup.string().required("Please select a Goal Growth type"),
  goalGrowthValue: yup
    .number()
    .moreThan(0, "Growth Target should be more than 0")
    .required("Please fill in Goal Growth value"),
  targetPeriod: yup.date().required("Please select target period"),
  compareTo: yup.string().when("goalGrowthType", {
    is: (val: string) => val === GOAL_GROWTH_TYPE.PERCENTAGE,
    then: schema => schema.required("Please set Growth Target"),
    otherwise: schema => schema,
  }),
})

export default function useConnect() {
  const [createCompanyGoal] =
    useCompanyGoalControllerCreateCompanyGoalMutation()

  const [isSaving, setIsSaving] = useState(false)

  const initialValues = {
    title: "",
    description: undefined,
    notes: undefined,
    metric: metricsOptions[0]?.value ?? "",
    goalGrowthType: GOAL_GROWTH_TYPE.PERCENTAGE,
    goalGrowthValue: 0,
    targetPeriod: dayjs().startOf("month").toDate(),
    compareTo: COMPARE_TO.LAST_MONTH,
  } as TGoalForm

  const handleCreate = async (values: TGoalForm) => {
    setIsSaving(true)
    await createCompanyGoal({
      body: values,
    }).unwrap()
    setIsSaving(false)
  }

  return {
    isSaving,
    validationSchema,
    initialValues,
    handleCreate,
  }
}
