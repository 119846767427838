import { useCompanyConnectorControllerGetIgConnectorTagsQuery } from "~/ui-rtk/api/companyConnectorApi"

export default function useConnect({
  before,
  after,
}: {
  before?: string
  after?: string
}) {
  const { data, isLoading, isError, error } =
    useCompanyConnectorControllerGetIgConnectorTagsQuery({
      before,
      after,
    })

  return {
    data,
    isLoading,
    isError,
    error,
  }
}
