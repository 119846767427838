import { useState } from "react"
import { GOAL_METRIC, TGoalMetric } from "../constants"
import useDepVar from "~/ui-rtk/hooks/dep-var"
import { BinaryOperator, Filter } from "@cubejs-client/core"
import useBaselineRevenueMetric from "~/ui-rtk/hooks/baseline-revenue-metric"
import useDate, { type Dayjs } from "~/ui-rtk/hooks/date"
import { DATE_FORMAT } from "~/ui-rtk/hooks/period"
import { useAppSession } from "../../Auth/useCubeApiToken"
import { getMonthLabel } from "~/ui-rtk/components/ui/controls/MonthPicker/utils"

const dayjs = useDate()

function getMainMetricKey(metric: TGoalMetric, baselineMetric: string) {
  if (metric === GOAL_METRIC.BRAND_VALUE) {
    return "company_platform_brand_value.revenue180Days"
  }
  return `metrics.${baselineMetric}`
}

function getQForGoalMetric(
  metric: TGoalMetric,
  dateRange: [Dayjs, Dayjs],
  {
    depVar,
    depVarScaler,
    baselineMetric,
  }: {
    depVar: string
    depVarScaler: string[]
    baselineMetric: string
  },
) {
  const [startDate, endDate] = dateRange.map(date => date.format(DATE_FORMAT))

  if (metric === GOAL_METRIC.BRAND_VALUE) {
    return {
      measures: [getMainMetricKey(metric, baselineMetric)],
      filters: [
        {
          and: [
            {
              dimension: "company_platform_brand_value.dep_var",
              operator: "equals" as BinaryOperator,
              values: [depVar],
            },
            {
              member: "company_platform_brand_value.engagement_date",
              operator: "inDateRange" as BinaryOperator,
              values: [startDate, endDate],
            },
            depVarScaler.length > 0
              ? {
                  dimension: "company_platform_brand_value.dep_var_scaler",
                  operator: "equals" as BinaryOperator,
                  values: depVarScaler,
                }
              : undefined,
          ].filter(f => !!f) as Filter[],
        },
      ],
    }
  }
  return {
    measures: [getMainMetricKey(metric, baselineMetric)],
    filters: [
      {
        member: "metrics.date",
        operator: "inDateRange" as BinaryOperator,
        values: [startDate, endDate],
      },
    ],
  }
}

const PERIODS = {
  ONE_MONTH: "ONE_MONTH",
  THREE_MONTH: "THREE_MONTH",
  SIX_MONTH: "SIX_MONTH",
}

const PERIODS_SORT = {
  [PERIODS.ONE_MONTH]: 1,
  [PERIODS.THREE_MONTH]: 2,
  [PERIODS.SIX_MONTH]: 3,
}

const PERIODS_DATE_RANGES = {
  [PERIODS.ONE_MONTH]: {
    actual: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
    compare: [
      dayjs().subtract(1, "year").subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "year").subtract(1, "month").endOf("month"),
    ],
  },
  [PERIODS.THREE_MONTH]: {
    actual: [
      dayjs().subtract(3, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
    compare: [
      dayjs().subtract(1, "year").subtract(3, "month").startOf("month"),
      dayjs().subtract(1, "year").subtract(1, "month").endOf("month"),
    ],
  },
  [PERIODS.SIX_MONTH]: {
    actual: [
      dayjs().subtract(6, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
    compare: [
      dayjs().subtract(6, "year").subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "year").subtract(1, "month").endOf("month"),
    ],
  },
}

const PERIODS_LABELS = {
  [PERIODS.ONE_MONTH]: `${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.ONE_MONTH].actual[0].toDate())} v ${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.ONE_MONTH].compare[0].toDate())}`,
  [PERIODS.THREE_MONTH]: `${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.THREE_MONTH].actual[0].toDate())} > ${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.THREE_MONTH].actual[1].toDate())} v ${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.THREE_MONTH].compare[0].toDate())} > ${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.THREE_MONTH].compare[1].toDate())}`,
  [PERIODS.SIX_MONTH]: `${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.SIX_MONTH].actual[0].toDate())} > ${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.SIX_MONTH].actual[1].toDate())} v ${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.SIX_MONTH].compare[0].toDate())} > ${getMonthLabel(PERIODS_DATE_RANGES[PERIODS.SIX_MONTH].compare[1].toDate())}`,
}

export default function useMetricStatistics() {
  const [statistics, setStatistics] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { depVar, depVarScaler } = useDepVar()
  const baselineMetric = useBaselineRevenueMetric()

  const { cubeApi } = useAppSession()

  const requestStatistics = async (goalMetric: TGoalMetric) => {
    setIsLoading(true)
    const statistics: any[] = []
    const promises = Object.values(PERIODS).map(async period => {
      const actualQ = getQForGoalMetric(
        goalMetric,
        PERIODS_DATE_RANGES[period].actual as [Dayjs, Dayjs],
        {
          depVar,
          depVarScaler,
          baselineMetric,
        },
      )
      const comparedQ = getQForGoalMetric(
        goalMetric,
        PERIODS_DATE_RANGES[period].compare as [Dayjs, Dayjs],
        {
          depVar,
          depVarScaler,
          baselineMetric,
        },
      )
      const [actualResultSet, comparedResultSet] = await Promise.all([
        cubeApi.load(actualQ),
        cubeApi.load(comparedQ),
      ])
      const actualData = actualResultSet?.rawData()[0]
      const comparedData = comparedResultSet?.rawData()[0]

      statistics.push({
        key: period,
        label: PERIODS_LABELS[period],
        actual: +(
          actualData?.[getMainMetricKey(goalMetric, baselineMetric)] ?? 0
        ),
        compared: +(
          comparedData?.[getMainMetricKey(goalMetric, baselineMetric)] ?? 0
        ),
      })
    })
    await Promise.all(promises)
    setStatistics(
      statistics.sort(
        ({ key: keyA }, { key: keyB }) =>
          PERIODS_SORT[keyA] - PERIODS_SORT[keyB],
      ),
    )
    setIsLoading(false)
  }

  return {
    statistics,
    isLoading,
    requestStatistics,
  }
}
