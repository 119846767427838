import React, { useState, useEffect, useRef } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import dayjs from "dayjs"
import { Switch } from "@headlessui/react"

import "./index.scss"
import { minDate } from "../PeriodPicker/utils"
import useMobileView from "~/ui-rtk/hooks/mobile-view"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

// Define the props interface
interface PeriodPickerProps {
  start: Date | null
  end: Date | null
  comparisonEnabled?: boolean
  compareStart?: Date
  compareEnd?: Date
  minDate?: Date
  maxDate?: Date
  onChange: (dates: {
    start: Date
    end: Date
    compareStart?: Date | null
    compareEnd?: Date | null
  }) => void
}

// Predefined period templates
const PREDEFINED_PERIODS = [
  { label: "Last 7 days", days: 7 },
  { label: "Last 30 days", days: 30 },
  { label: "Last 3 months", days: 90 },
  { label: "Last 6 months", days: 180 },
  { label: "Last 12 months", days: 365 },
]

// Comparison period templates
const COMPARISON_TEMPLATES = [
  { label: "Same days last year", type: "lastYear" },
  { label: "Previous period", type: "previousPeriod" },
]

const defaultMaxDate = new Date()
const defaultMinDate = minDate

// Format date to string
const formatDate = (date?: Date | null): string =>
  date ? dayjs(date).format("MMM D, YYYY") : ""

// Parse date from string
const parseDate = (dateStr: string): Date | null => {
  const parsed = dayjs(dateStr, "MMM D, YYYY")
  return parsed.isValid() ? parsed.toDate() : null
}

export const DateRangePicker: React.FC<PeriodPickerProps> = ({
  start,
  end,
  comparisonEnabled = true,
  compareStart,
  compareEnd,
  minDate = defaultMinDate,
  maxDate = defaultMaxDate,
  onChange,
}) => {
  // Local state
  const [isOpen, setIsOpen] = useState(false)
  const [localStart, setLocalStart] = useState<Date | null>(start)
  const [localEnd, setLocalEnd] = useState<Date | null>(end)
  const [localCompareStart, setLocalCompareStart] = useState<Date | undefined>(
    compareStart,
  )
  const [localCompareEnd, setLocalCompareEnd] = useState<Date | undefined>(
    compareEnd,
  )
  const isComparisonMode = !!compareStart && !!compareEnd
  const [localComparisonEnabled, setLocalComparisonEnabled] =
    useState(isComparisonMode)
  const [calendarSelectingStart, setCalendarSelectingStart] = useState(true)
  const [calendarCompareSelectingStart, setCalendarCompareSelectingStart] =
    useState(true)
  const [startInputValue, setStartInputValue] = useState("")
  const [endInputValue, setEndInputValue] = useState("")
  const [compareStartInputValue, setCompareStartInputValue] = useState("")
  const [compareEndInputValue, setCompareEndInputValue] = useState("")

  const popupRef = useRef<HTMLDivElement>(null)
  const isMobile = useMobileView()

  // Update input values when dates change
  useEffect(() => {
    setStartInputValue(formatDate(localStart))
    setEndInputValue(formatDate(localEnd))
    setCompareStartInputValue(formatDate(localCompareStart))
    setCompareEndInputValue(formatDate(localCompareEnd))
  }, [localStart, localEnd, localCompareStart, localCompareEnd])

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)

        if (localStart && localEnd) {
          // Commit changes when closing
          onChange({
            start: localStart,
            end: localEnd,
            compareStart: localComparisonEnabled ? localCompareStart : null,
            compareEnd: localComparisonEnabled ? localCompareEnd : null,
          })
        }
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [
    isOpen,
    localStart,
    localEnd,
    localCompareStart,
    localCompareEnd,
    localComparisonEnabled,
    onChange,
  ])

  // Apply predefined period
  const applyPredefinedPeriod = (days: number) => {
    const end = new Date()
    const start = dayjs(end).subtract(days, "day").toDate()

    setLocalStart(start)
    setLocalEnd(end)
    setCalendarSelectingStart(true)
  }

  // Apply comparison template
  const applyComparisonTemplate = (type: string) => {
    if (!localStart || !localEnd) return

    const periodLength = dayjs(localEnd).diff(dayjs(localStart), "day")

    if (type === "lastYear") {
      const compareEnd = dayjs(localEnd).subtract(1, "year").toDate()
      const compareStart = dayjs(localStart).subtract(1, "year").toDate()

      setLocalCompareStart(compareStart)
      setLocalCompareEnd(compareEnd)
    } else if (type === "previousPeriod") {
      const compareEnd = dayjs(localStart).subtract(1, "day").toDate()
      const compareStart = dayjs(compareEnd)
        .subtract(periodLength, "day")
        .toDate()

      setLocalCompareStart(compareStart)
      setLocalCompareEnd(compareEnd)
    }

    setCalendarCompareSelectingStart(true)
  }

  // Handle text field input changes
  const handleStartInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartInputValue(e.target.value)
    const parsed = parseDate(e.target.value)
    if (parsed) {
      setLocalStart(parsed)
    }
  }

  const handleEndInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndInputValue(e.target.value)
    const parsed = parseDate(e.target.value)
    if (parsed) {
      setLocalEnd(parsed)
    }
  }

  const handleCompareStartInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCompareStartInputValue(e.target.value)
    const parsed = parseDate(e.target.value)
    if (parsed) {
      setLocalCompareStart(parsed)
    }
  }

  const handleCompareEndInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCompareEndInputValue(e.target.value)
    const parsed = parseDate(e.target.value)
    if (parsed) {
      setLocalCompareEnd(parsed)
    }
  }

  // Build the display text for the text field
  const getDisplayText = () => {
    const primaryRange =
      localStart && localEnd
        ? `${formatDate(localStart)} - ${formatDate(localEnd)}`
        : "Select date range"

    if (!localComparisonEnabled || !localCompareStart || !localCompareEnd) {
      return primaryRange
    }

    return `${primaryRange} vs ${formatDate(localCompareStart)} - ${formatDate(localCompareEnd)}`
  }

  return (
    <div className="relative ui-rtk-period-picker">
      {/* Main TextField */}
      <div
        className="cursor-pointer min-w-64 px-2 py-1.5 rounded-md bg-basic-dark-blue border border-basic-blue text-sm"
        onClick={() => setIsOpen(true)}
      >
        <div className="flex items-center justify-between">
          <span>{getDisplayText()}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 9.75v7.5"
            />
          </svg>
        </div>
      </div>

      {/* Popup */}
      {isOpen && (
        <div
          ref={popupRef}
          className={cn(
            "absolute z-50 mt-2 right-0 bg-basic-dark-blue border border-basic-blue rounded-lg px-2 py-1.5",
            {
              "grid grid-cols-4 gap-2 w-[65rem]": !isMobile,
              "w-[16rem]": isMobile,
            },
          )}
        >
          {/* Left Column - Predefined Periods */}
          <div
            className={cn("border-basic-blue", {
              "border-r pr-2": !isMobile,
              "border-b pb-2": isMobile,
            })}
          >
            <h3 className="text-sm font-medium mb-4">
              {localComparisonEnabled
                ? "Comparison Templates"
                : "Predefined Periods"}
            </h3>

            {!localComparisonEnabled ? (
              <div
                className={cn("", {
                  "grid grid-cols-2 gap-2": isMobile,
                  "space-y-2": !isMobile,
                })}
              >
                {PREDEFINED_PERIODS.map(period => (
                  <button
                    key={period.label}
                    onClick={() => applyPredefinedPeriod(period.days)}
                    className={cn("btn btn-sm w-full", {
                      "justify-start": !isMobile,
                      "justify-center items-center": isMobile,
                    })}
                  >
                    {period.label}
                  </button>
                ))}
              </div>
            ) : (
              <div
                className={cn("", {
                  "grid grid-cols-2 gap-2": isMobile,
                  "space-y-2": !isMobile,
                })}
              >
                {COMPARISON_TEMPLATES.map(template => (
                  <button
                    key={template.label}
                    onClick={() => applyComparisonTemplate(template.type)}
                    className={cn("btn btn-sm w-full", {
                      "justify-start": !isMobile,
                      "justify-center items-center": isMobile,
                    })}
                  >
                    {template.label}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Center Column - Calendars */}
          <div
            className={cn("border-basic-blue", {
              "border-r px-2 col-span-2": !isMobile,
              "border-b py-2": isMobile,
            })}
          >
            <h3 className="text-sm font-medium mb-2">
              {localComparisonEnabled ? "Comparison Period" : "Select Period"}
            </h3>

            <div
              className={cn({
                "grid grid-cols-2 gap-2": !isMobile,
                "flex flex-col gap-2 items-center": isMobile,
              })}
            >
              <div>
                <DatePicker
                  selected={
                    localComparisonEnabled ? localCompareStart : localStart
                  }
                  onChange={(date: Date) => {
                    if (localComparisonEnabled) {
                      setLocalCompareStart(date)
                      setCalendarCompareSelectingStart(false)
                    } else {
                      setLocalStart(date)
                      setCalendarSelectingStart(false)
                    }
                  }}
                  selectsStart
                  startDate={
                    localComparisonEnabled ? localCompareStart : localStart
                  }
                  endDate={localComparisonEnabled ? localCompareEnd : localEnd}
                  minDate={minDate}
                  maxDate={localComparisonEnabled ? localCompareEnd : maxDate}
                  inline
                  disabled={
                    localComparisonEnabled
                      ? !calendarCompareSelectingStart
                      : !calendarSelectingStart
                  }
                />
              </div>

              <div>
                <DatePicker
                  selected={localComparisonEnabled ? localCompareEnd : localEnd}
                  onChange={(date: Date) => {
                    if (localComparisonEnabled) {
                      setLocalCompareEnd(date)
                      setCalendarCompareSelectingStart(true)
                    } else {
                      setLocalEnd(date)
                      setCalendarSelectingStart(true)
                    }
                  }}
                  selectsEnd
                  startDate={
                    localComparisonEnabled ? localCompareStart : localStart
                  }
                  endDate={localComparisonEnabled ? localCompareEnd : localEnd}
                  minDate={
                    localComparisonEnabled ? localCompareStart : localStart
                  }
                  maxDate={maxDate}
                  inline
                  disabled={
                    localComparisonEnabled
                      ? calendarCompareSelectingStart
                      : calendarSelectingStart
                  }
                />
              </div>
            </div>
          </div>

          {/* Right Column - Input Fields & Toggle */}
          <div
            className={cn("pb-2", {
              "pl-2": !isMobile,
              "ps-2": isMobile,
            })}
          >
            <h3 className="text-sm font-medium mb-4">Period Details</h3>

            {/* Primary Period */}
            <div className="mb-4">
              {localComparisonEnabled && (
                <div className="text-sm mb-2">Primary Period</div>
              )}
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <label className="block text-sm mb-1">Start Date</label>
                  <input
                    type="text"
                    value={startInputValue}
                    onChange={handleStartInputChange}
                    disabled={localComparisonEnabled}
                    className="input input-bordered input-sm w-full"
                    placeholder="MMM D, YYYY"
                  />
                </div>
                <div>
                  <label className="block text-sm mb-1">End Date</label>
                  <input
                    type="text"
                    value={endInputValue}
                    onChange={handleEndInputChange}
                    disabled={localComparisonEnabled}
                    className="input input-bordered input-sm w-full"
                    placeholder="MMM D, YYYY"
                  />
                </div>
              </div>
            </div>

            {/* Comparison Toggle */}
            <div className="mb-4">
              {comparisonEnabled && (
                <Switch.Group>
                  <div className="flex items-center">
                    <Switch
                      checked={localComparisonEnabled}
                      onChange={setLocalComparisonEnabled}
                      className={`${
                        localComparisonEnabled
                          ? "bg-basic-pink"
                          : "bg-basic-pink/50"
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                      <span
                        className={`${
                          localComparisonEnabled
                            ? "translate-x-6"
                            : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                    <Switch.Label className="ml-2 text-sm">
                      Enable Comparison
                    </Switch.Label>
                  </div>
                </Switch.Group>
              )}
            </div>

            {/* Comparison Period */}
            {localComparisonEnabled && (
              <div>
                <div className="text-sm mb-2">Comparison Period</div>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <label className="block text-sm mb-1">Start Date</label>
                    <input
                      type="text"
                      value={compareStartInputValue}
                      onChange={handleCompareStartInputChange}
                      className="input input-bordered input-sm w-full"
                      placeholder="MMM D, YYYY"
                    />
                  </div>
                  <div>
                    <label className="block text-sm mb-1">End Date</label>
                    <input
                      type="text"
                      value={compareEndInputValue}
                      onChange={handleCompareEndInputChange}
                      className="input input-bordered input-sm w-full"
                      placeholder="MMM D, YYYY"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Apply Button */}
            <div className="mt-4">
              <button
                className="btn text-black bg-basic-pink btn-sm w-full"
                onClick={() => {
                  setIsOpen(false)
                  if (localStart && localEnd) {
                    onChange({
                      start: localStart,
                      end: localEnd,
                      compareStart: localComparisonEnabled
                        ? localCompareStart
                        : null,
                      compareEnd: localComparisonEnabled
                        ? localCompareEnd
                        : null,
                    })
                  }
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DateRangePicker
