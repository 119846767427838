export const brandRoutes = {
  DOCS: "/docs",

  HOME: {
    ROOT: "/home",
    NEW_GOAL: "/home/new-goal",
  },

  BRAND_HEALTH: {
    ROOT: "/brand-health",
    OVERVIEW: "/brand-health/overview",
    MIND_SHARE: "/brand-health/mind-share",
    OWNED_AND_ORGANIC: "/revenue/shopify",
    PAID_BRAND_SEARCH: "/brand-health/paid-brand-search",
  },

  BRAND_VALUE: {
    ROOT: "/brand-value",
    OVERVIEW: "/brand-value/overview",
    COHORT_ANALYSIS: "/brand-value/cohort-analysis",
  },

  BRAND_MEDIA: {
    ROOT: "/brand-media",
    PAID_OPTIMIZATION: "/brand-media/paid-optimization",
    ORGANIC_SOCIAL_POSTS: "/brand-media/organic-social-posts",
    MEDIA_MENTIONS: "/brand-media/media-mentions",
    CREATIVE_REPORTS: "/brand-media/creative-reports",
  },

  REVENUE: {
    ROOT: "/revenue",
    GOOGLE_ANALYTICS: "/revenue/google-analytics",
    GOOGLE_ANALYTICS_MULTI_PROPERTY: "/revenue/google-analytics-multi-property",
    SHOPIFY: "/revenue/shopify",
  },

  SETTINGS: {
    ROOT: "/settings",
    PROFILE: "/settings/profile",
    PASSWORD: "/settings/password",
    USERS: "/settings/users",
    COMPANY: "/settings/company",
    BRAND_EVENTS: "/settings/brand-events",
  },

  SETUP: {
    ROOT: "/setup",
    CONNECTORS: "/setup/connectors",
    KEYWORDS: "/setup/keywords-and-competitors",
    REVENUE_SOURCE: "/setup/revenue-source",
  },

  COMPANY_CREATE: {
    ROOT: "/company/create",
  },

  ONBOARDING: {
    CONNECT_SOURCES: "/onboarding/connect-sources",
  },

  OAUTH_CONNECTION: "/oauth-connection",
  AUTHENTICATE_CONNECTOR: "/authenticate-connector",

  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  RESET_PASSWORD: "/reset-password",
} as const

export const DEFAULT_LOGGED_ROUTE = brandRoutes.BRAND_VALUE.OVERVIEW
