import { Link } from "react-router-dom"
import { Form, Formik } from "formik"

import useConnect from "./connect"
import { Button, FormField } from "~/ui-rtk/components/ui/controls"

const SignInForm = () => {
  const { isSubmitting, initialValues, handleLogin, validationSchema } =
    useConnect()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleLogin}
      validationSchema={validationSchema}
    >
      {({ touched, errors }) => (
        <Form className="w-full">
          <div className="flex flex-col items-center gap-8 mb-5">
            <FormField
              id="email"
              type="email"
              placeholder="Email"
              isError={Boolean(touched.email && errors.email)}
            />
            <FormField
              id="password"
              type="password"
              placeholder="Password"
              isError={Boolean(touched.password && errors.password)}
            />
          </div>
          <Link
            to="/forgot-password"
            className="leading-5 tracking-[-1%] font-bold text-basic-pink hover:text-basic-pink-hover mb-8"
          >
            Forgot password?
          </Link>
          <Button
            type="submit"
            className="mx-auto mt-8 sm:max-w-48 w-full"
            isLoading={isSubmitting}
            size="sm"
          >
            Sign In
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default SignInForm
