import { Popover as HPopover } from "@headlessui/react"
import { ReactNode, useCallback, useState } from "react"
import { CheckMarkSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { ChevronDownSvg } from "../../svg/arrow"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type TMultiSelectOption = {
  label: string
  id: string
  icon?: ReactNode | null
  disabled?: boolean
}

type TMultiSelectProps = {
  options: TMultiSelectOption[]
  value?: TMultiSelectOption[]
  defaultValue?: TMultiSelectOption[]
  className?: string
  classes?: Partial<{
    list: string
  }>
  label?: string
  onChange: (options: TMultiSelectOption[]) => void
}

export default function MultiSelect({
  options,
  value: valueProps,
  defaultValue,
  className,
  classes,
  label,
  onChange: onChangeFromProps,
}: TMultiSelectProps) {
  const isControlled = typeof valueProps !== "undefined"
  const hasDefaultValue = typeof defaultValue !== "undefined"

  const [internalValue, setInternalValue] = useState<TMultiSelectOption[]>(
    hasDefaultValue ? defaultValue : [],
  )

  const selectedOptions = isControlled ? valueProps : internalValue

  const onChange = (e: TMultiSelectOption[]) => {
    if (onChangeFromProps) {
      onChangeFromProps(e)
    }

    if (!isControlled) {
      setInternalValue(e)
    }
  }

  const getSelectedIdx = (option: TMultiSelectOption) =>
    selectedOptions.findIndex(_option => option.id === _option.id)
  const isOptionSelected = (option: TMultiSelectOption) =>
    getSelectedIdx(option) !== -1
  const toggle = useCallback(
    (option: TMultiSelectOption) => {
      if (option.disabled) {
        return
      }
      if (isOptionSelected(option)) {
        const idx = getSelectedIdx(option)
        const newSelected = [...selectedOptions]
        newSelected.splice(idx, 1)
        onChange(newSelected)
      } else {
        onChange([...selectedOptions, option])
      }
    },
    [selectedOptions],
  )

  let btnLabel
  if (label) {
    btnLabel = label
  } else if (selectedOptions.length > 0) {
    btnLabel = `Selected ${selectedOptions.length} item${selectedOptions.length > 1 ? "s" : ""}`
  } else {
    btnLabel = "Click to select"
  }

  return (
    <HPopover className={`relative ${className} ring-0 focus:ring-0 space-y-2`}>
      <HPopover.Button
        className={cn(
          "flex items-center w-full ring-0 focus:ring-0 outline-none justify-between gap-2 px-2 py-1.5 text-sm text-white border rounded-md border-basic-blue bg-basic-dark-blue",
          className,
        )}
      >
        <p className="whitespace-nowrap">{btnLabel}</p>
        <ChevronDownSvg />
      </HPopover.Button>
      <HPopover.Panel className="absolute z-40">
        <div
          className={cn(
            "max-h-75 overflow-y-auto scrollbar container w-full text-white border rounded-md border-basic-blue bg-basic-dark-blue",
            classes?.list,
          )}
        >
          {options.map((option, idx) => {
            const isFirst = idx === 0
            const isLast = options.length - 1 === idx
            return (
              <div
                key={option.id}
                className={cn(
                  `px-2 py-1.5 text-sm cursor-pointer flex items-center space-x-2 w-full pr-6 hover:bg-background-dark-shade ${option.disabled ? "disabled" : ""}`,
                  isFirst && "rounded-t-md",
                  isLast && "rounded-b-md",
                )}
                onClick={() => toggle(option)}
                aria-label={option.label}
              >
                {isOptionSelected(option) && (
                  <CheckMarkSvg className="me-1" fill="green" />
                )}
                <div
                  className={cn(
                    `${!isOptionSelected(option) ? "ms-6" : ""}`,
                    !isOptionSelected(option) && "pl-1",
                    "whitespace-nowrap flex items-center justify-start gap-1",
                  )}
                >
                  {option.icon && (
                    <div className="w-3 h-3 flex items-center justify-start">
                      {option.icon}
                    </div>
                  )}
                  {option.label}
                </div>
              </div>
            )
          })}
        </div>
      </HPopover.Panel>
    </HPopover>
  )
}
