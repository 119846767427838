import { useState, useRef, useMemo, useEffect } from "react"
import { Route, Routes, Navigate, useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import { Topbar, Sidebar } from "~/ui-rtk/components/layout"

import Docs from "../Docs"
import Settings from "../Settings"
import SettingsProfilePage from "../Settings/ProfilePage"
import SettingsPasswordPage from "../Settings/PasswordPage"
import SettingsUsersPage from "../Settings/UsersPage"
import Connectors from "../Setup/ConnectorsPage"
import useConnect from "./connect"
import ConfirmEmail from "../ConfirmEmail"

import {
  brandRoutes,
  DEFAULT_LOGGED_ROUTE,
  guestRoutes,
  sharedRoutes,
} from "~/ui-rtk/constants/routes"

import { useScrollRestoration } from "~/ui-rtk/shared/hooks"
import useLoadingState from "~/ui-rtk/hooks/loading-state"
import { SessionRole } from "~/api/dto/auth/session-role"
import AuthenticateConnector from "../AuthenticateConnector"
import { useAuthControllerSwitchCompanyMutation } from "~/ui-rtk/api/authApi"
import { usePermissions, withGuard } from "./usePermissions"
import BrandHealth from "../BrandHealth"
import BrandsHealthMindShare from "../BrandHealth/MindShare"
import BrandsHealthOverview from "../BrandHealth/Overview"
import RevenueIndexPage from "../Revenue"
import GoogleAnalytics from "../Revenue/GoogleAnalytics"
import RevenueShopifyPage from "../Revenue/Shopify"
import BrandValue from "../BrandValue"
import BrandsValueOverview from "../BrandValue/Overview"
import BrandMedia from "../BrandMedia"
import PageBrandMediaOptimization from "../BrandMedia/Optimization/PageBrandMediaOptimization"
import PageBrandMediaOrganicSocialPosts from "../BrandMedia/OrganicSocialPosts/PageBrandMediaOrganicSocialPosts"
import PageBrandMediaCreativeReports from "../BrandMedia/CreativeReports/CreativeReports"
import Setup from "../Setup"
import KeywordsPage from "../Setup/KeywordsPage/KeywordsPage"
import WithDrawer from "~/ui-rtk/components/layout/WithDrawer/WithDrawer"
import SettingsCompanyPage from "../Settings/CompanyPage/CompanyPage"
import MoneyWarnDailog from "~/ui-rtk/components/ui/common/MoneyWarnDialog/MoneyWarnDailog"
import useMoneyWarnDialog from "~/ui-rtk/hooks/money-warn-dialog"
import HomePage from "../Home/HomePage"

import { SubscriptionGuard } from "~/ui-rtk/components/features/guards/SubscriptionGuard"
import { PricingDetails } from "../PricingDetails"
import { SettingsBrandEventsPage } from "../Settings/BrandEventsPage/BrandEventsPage"
import Checkout, { SubscribeGuard } from "../Subscribe/Checkout/Checkout"
import { Confirm } from "../Subscribe/Confirm/Confirm"
import { AdhocFivetranConnection } from "../AdhocFivetranConnection"
import useMobileView from "~/ui-rtk/hooks/mobile-view"
import { RevenueSourcePage as SetupRevenueSourcePage } from "../Setup/RevenueSourcePage"
import usePostHogFeatureFlags from "~/ui-rtk/hooks/posthog-feaures"
import { POSTHOG_FEATURES } from "~/ui-rtk/constants/feature-flags"
import useAccess from "~/ui-rtk/hooks/access"
import { URL_COMPANY_ID } from "~/ui-rtk/constants/url-params"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import PageBrandMediaMediaMentions from "../BrandMedia/Mentions/PageBrandMediaMentions"
import useMediaMentionsAccess from "~/ui-rtk/hooks/media-mentions-access"
import useShopifyAccess from "~/ui-rtk/hooks/shopify-access"
import useGAConnectorConnected from "~/ui-rtk/hooks/ga-connector-connected"
import useFeaturebase from "~/ui-rtk/hooks/featurebase.tsx"
import getAnnualRevenue from "~/ui-rtk/hooks/get-anunual-revenue.ts"
import { usePostHog } from "posthog-js/react"
import NewGoalPage from "../Goals/NewGoalPage"
import useHomepageAccess from "~/ui-rtk/hooks/homepage-access"
import useCreativeReportsPageAccess from "~/ui-rtk/hooks/creative-reports-access"
import useMentionsReviewAccess from "~/ui-rtk/hooks/mentions-review-access"
import MentionsReviewPage from "../BrandMedia/MentionsReview/MentionsReview"

const NO_LAYOUT_PAGES: string[] = []
const NO_SIDEBAR_PAGES = [guestRoutes.SUBSCRIBE_CONFIRM]
const NO_TOPBAR_PAGES: string[] = []

export default function Main() {
  const {
    currentCompany,
    sessionRole,
    isAccessGranted,
    isBillingEnabled,
    // isPermissionGranted,
    isUserManagementGranted,
    isCompanyManagementGranted,
    isBrandConversionEventsManagementGranted,
  } = usePermissions()
  const { boot, update } = useIntercom()

  const posthog = usePostHog()
  const { identify } = useFeaturebase()
  const { revenue } = getAnnualRevenue()

  const hasAccessToMediaMentions = useMediaMentionsAccess()
  const hasMentionsReviewAccess = useMentionsReviewAccess()
  const hasAccessToCreativeReports = useCreativeReportsPageAccess()
  const [switchAsync] = useAuthControllerSwitchCompanyMutation()
  const { session, refetch } = useConnect()
  const { setIsLoading } = useLoadingState()
  const [renderKey, requestRerender] = useState(
    session?.currentCompany.id ?? "",
  )
  const { isSuperAdmin } = useAccess()
  const { params, setParams } = useURLSearchParams()
  const { [URL_COMPANY_ID]: companyIdFromUrl } = params
  const mainFrameRef = useRef<HTMLDivElement | null>(null)
  const isMobile = useMobileView()
  const isRevenueSourcePageFeatureEnabled = usePostHogFeatureFlags(
    POSTHOG_FEATURES.REVENUE_DEP_VAR_SETUP_PAGE,
  )
  const isRevenueShopifyPageEnabled = useShopifyAccess()
  const isGAAvailable = useGAConnectorConnected()

  const location = useLocation()
  const { pathname } = location

  const noBars = useMemo(() => NO_LAYOUT_PAGES.includes(pathname), [pathname])
  const noSideBar = useMemo(
    () => NO_SIDEBAR_PAGES.includes(pathname),
    [pathname],
  )
  const noTopBar = useMemo(() => NO_TOPBAR_PAGES.includes(pathname), [pathname])

  useScrollRestoration(mainFrameRef)
  const [showMoneyWarnDialog, hideMoneyWarnDialog] = useMoneyWarnDialog()

  const { user } = session || {}

  useEffect(() => {
    if (isMobile) {
      boot()
    }
  }, [boot, isMobile])

  useEffect(() => {
    if (companyIdFromUrl) {
      if (isSuperAdmin) {
        void switchAsync({ switchCompanyDto: { companyId: companyIdFromUrl } })
      }
      const newParams = {
        ...params,
        [URL_COMPANY_ID]: undefined,
      }
      setParams(newParams)
    }
  }, [])

  if (!session) {
    return <Navigate to="/" replace />
  }

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.id, {
        email: user.email.toLowerCase(),
        name: user.name,
      })
      if (currentCompany && revenue) {
        posthog?.group("company", currentCompany.id, {
          name: currentCompany.name,
          setupStatus: currentCompany.setupStatus,
          subscription: currentCompany.subscription,
          revenue,
        })
        identify()
      }
    }
  }, [posthog, user?.id, user?.email, currentCompany?.id, revenue])

  useEffect(() => {
    if (user && currentCompany) {
      update({
        userId: user?.id,
        name: user?.name,
        email: user?.email,
        company: {
          companyId: currentCompany?.id,
          name: currentCompany?.name,
          createdAt: currentCompany?.createdAt,
          website: currentCompany?.domain,
          industry: currentCompany?.metadata?.industry,
        },
      })
    }
  }, [update, user?.id, user?.email, currentCompany?.id])

  let defaultRoute: string

  if (sessionRole === SessionRole.SUPER_ADMIN) {
    defaultRoute = DEFAULT_LOGGED_ROUTE
  } else if (isBillingEnabled && isAccessGranted) {
    defaultRoute = DEFAULT_LOGGED_ROUTE
  } else if (isBillingEnabled) {
    defaultRoute = guestRoutes.SUBSCRIBE
  } else {
    defaultRoute = brandRoutes.SETUP.CONNECTORS
  }

  const handleCompanyChange = async (companyId: string) => {
    setIsLoading(true)
    await switchAsync({ switchCompanyDto: { companyId } })
    await refetch()
    requestRerender(companyId)
    setIsLoading(false)
  }

  return (
    <div
      key={renderKey}
      ref={mainFrameRef}
      className="h-full overflow-y-auto bg-basic-black text-basic-light"
    >
      {currentCompany && !noBars && !noTopBar && (
        <Topbar
          onCompanyChange={handleCompanyChange}
          currentCompany={currentCompany}
        />
      )}
      {!noBars && !noSideBar && <Sidebar />}

      <div
        className={
          noBars || noTopBar ? "p-2" : "mt-15 md:ms-16 h-[calc(100%-4rem)]"
        }
      >
        <WithDrawer>
          <Routes>
            <Route
              path={brandRoutes.HOME.ROOT}
              element={withGuard(<HomePage />, [useHomepageAccess()])}
            />
            <Route
              path={brandRoutes.HOME.NEW_GOAL}
              element={withGuard(<NewGoalPage />, [useHomepageAccess()])}
            />
            <Route
              path={brandRoutes.BRAND_HEALTH.ROOT}
              element={withGuard(
                <SubscriptionGuard>
                  <BrandHealth />
                </SubscriptionGuard>,
                [isAccessGranted],
              )}
            >
              <Route
                path={brandRoutes.BRAND_HEALTH.OVERVIEW}
                element={withGuard(
                  <SubscriptionGuard>
                    <BrandsHealthOverview />
                  </SubscriptionGuard>,
                  [isAccessGranted],
                )}
              />
              <Route
                path={brandRoutes.BRAND_HEALTH.MIND_SHARE}
                element={withGuard(
                  <SubscriptionGuard>
                    <BrandsHealthMindShare />
                  </SubscriptionGuard>,
                  [isAccessGranted],
                )}
              />
              <Route
                index
                element={
                  <Navigate to={brandRoutes.BRAND_HEALTH.OVERVIEW} replace />
                }
              />
            </Route>
            <Route
              path={brandRoutes.BRAND_VALUE.ROOT}
              element={withGuard(
                <SubscriptionGuard>
                  <BrandValue />
                </SubscriptionGuard>,
                [isAccessGranted],
              )}
            >
              <Route
                path={brandRoutes.BRAND_VALUE.OVERVIEW}
                element={withGuard(
                  <SubscriptionGuard>
                    <BrandsValueOverview />
                  </SubscriptionGuard>,
                  [isAccessGranted],
                )}
              />
              {/* https://linear.app/marathon-data/issue/MD-2250/hide-cohortvisualization-for-mvp */}
              {/* <Route
                path={brandRoutes.BRAND_VALUE.COHORT_ANALYSIS}
                element={withGuard(<BrandsValueCohortAnalysis />, [
                                    isPermissionGranted([
                    SessionRole.ADMIN,
                    SessionRole.USER,
                    SessionRole.SUPER_ADMIN,
                  ]),
                ])}
              /> */}
              <Route
                index
                element={
                  <Navigate to={brandRoutes.BRAND_VALUE.OVERVIEW} replace />
                }
              />
            </Route>
            <Route
              path={guestRoutes.PRICING_DETAILS}
              element={<PricingDetails />}
            />
            <Route
              path={guestRoutes.SUBSCRIBE}
              element={withGuard(
                <SubscribeGuard session={session}>
                  <Checkout />
                </SubscribeGuard>,
                [isBillingEnabled],
              )}
            />
            <Route path={guestRoutes.SUBSCRIBE_CONFIRM} element={<Confirm />} />
            <Route
              path={brandRoutes.BRAND_MEDIA.ROOT}
              element={withGuard(
                <SubscriptionGuard>
                  <BrandMedia />
                </SubscriptionGuard>,
                [isAccessGranted],
              )}
            >
              <Route
                path={brandRoutes.BRAND_MEDIA.PAID_OPTIMIZATION}
                element={withGuard(
                  <SubscriptionGuard>
                    <PageBrandMediaOptimization />
                  </SubscriptionGuard>,
                  [isAccessGranted],
                )}
              />
              <Route
                path={brandRoutes.BRAND_MEDIA.ORGANIC_SOCIAL_POSTS}
                element={withGuard(
                  <SubscriptionGuard>
                    <PageBrandMediaOrganicSocialPosts />
                  </SubscriptionGuard>,
                  [isAccessGranted],
                )}
              />
              {hasMentionsReviewAccess ? (
                <Route
                  path={brandRoutes.BRAND_MEDIA.MEDIA_MENTIONS}
                  element={withGuard(
                    <SubscriptionGuard>
                      <MentionsReviewPage />
                    </SubscriptionGuard>,
                    [isAccessGranted],
                  )}
                />
              ) : (
                <Route
                  path={brandRoutes.BRAND_MEDIA.MEDIA_MENTIONS}
                  element={withGuard(
                    <SubscriptionGuard>
                      <PageBrandMediaMediaMentions />
                    </SubscriptionGuard>,
                    [isAccessGranted, hasAccessToMediaMentions],
                  )}
                />
              )}
              <Route
                path={brandRoutes.BRAND_MEDIA.CREATIVE_REPORTS}
                element={withGuard(
                  <SubscriptionGuard>
                    <PageBrandMediaCreativeReports />
                  </SubscriptionGuard>,
                  [isAccessGranted, hasAccessToCreativeReports],
                )}
              />
              <Route
                index
                element={
                  <Navigate
                    to={brandRoutes.BRAND_MEDIA.PAID_OPTIMIZATION}
                    replace
                  />
                }
              />
            </Route>
            <Route
              path={brandRoutes.REVENUE.ROOT}
              element={withGuard(
                <SubscriptionGuard>
                  <RevenueIndexPage />
                </SubscriptionGuard>,
                [isAccessGranted],
              )}
            >
              <Route
                path={brandRoutes.REVENUE.GOOGLE_ANALYTICS}
                element={withGuard(
                  <SubscriptionGuard>
                    <GoogleAnalytics />
                  </SubscriptionGuard>,
                  [isAccessGranted, isGAAvailable],
                )}
              />
              <Route
                path={brandRoutes.REVENUE.SHOPIFY}
                element={withGuard(
                  <SubscriptionGuard>
                    <RevenueShopifyPage />
                  </SubscriptionGuard>,
                  [isAccessGranted, isRevenueShopifyPageEnabled],
                )}
              />
              <Route
                index
                element={
                  <Navigate to={brandRoutes.REVENUE.GOOGLE_ANALYTICS} replace />
                }
              />
            </Route>
            <Route path={brandRoutes.SETUP.ROOT} element={<Setup />}>
              <Route
                path={brandRoutes.SETUP.CONNECTORS}
                element={<Connectors />}
              />
              <Route
                path={brandRoutes.SETUP.KEYWORDS}
                element={<KeywordsPage />}
              />
              <Route
                path={brandRoutes.SETUP.REVENUE_SOURCE}
                element={withGuard(<SetupRevenueSourcePage />, [
                  isRevenueSourcePageFeatureEnabled,
                ])}
              />
              <Route
                index
                element={<Navigate to={brandRoutes.SETUP.CONNECTORS} replace />}
              />
            </Route>
            <Route path={brandRoutes.SETTINGS.ROOT} element={<Settings />}>
              <Route
                path={brandRoutes.SETTINGS.PROFILE}
                element={<SettingsProfilePage />}
              />
              <Route
                path={brandRoutes.SETTINGS.PASSWORD}
                element={<SettingsPasswordPage />}
              />
              <Route
                path={brandRoutes.SETTINGS.USERS}
                element={withGuard(
                  <SettingsUsersPage />,
                  isUserManagementGranted,
                )}
              />
              <Route
                path={brandRoutes.SETTINGS.COMPANY}
                element={withGuard(
                  <SettingsCompanyPage />,
                  isCompanyManagementGranted,
                )}
              />
              <Route
                path={brandRoutes.SETTINGS.BRAND_EVENTS}
                element={withGuard(
                  <SettingsBrandEventsPage />,
                  isBrandConversionEventsManagementGranted,
                )}
              />
              <Route
                index
                element={<Navigate to={brandRoutes.SETTINGS.PROFILE} replace />}
              />
            </Route>
            <Route
              path={brandRoutes.AUTHENTICATE_CONNECTOR}
              element={
                <AuthenticateConnector
                  redirectTo={brandRoutes.SETUP.CONNECTORS}
                />
              }
            />
            <Route
              path={sharedRoutes.FIVETRAN_CONNECTION}
              element={
                <AdhocFivetranConnection
                  redirectTo={brandRoutes.SETUP.CONNECTORS}
                />
              }
            />
            <Route
              path={guestRoutes.CONFIRM_EMAIL}
              element={<ConfirmEmail />}
            />
            <Route path={brandRoutes.DOCS} element={<Docs />} />
            <Route path="*" element={<Navigate to={defaultRoute} replace />} />
          </Routes>
        </WithDrawer>
      </div>

      {showMoneyWarnDialog && (
        <MoneyWarnDailog onClose={hideMoneyWarnDialog as () => void} />
      )}
    </div>
  )
}
