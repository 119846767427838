import { TOTAL_CALCULATED_DATA_KEY } from "~/ui-rtk/constants/charts"
import { H2 } from "~/ui-rtk/components/ui/typography"
import { toNumber } from "~/ui-rtk/utils/number-utils"
import ValueWithDelta from "../../../../ui/common/ValueWithDelta/ValueWithDelta"
import numeral from "numeral"

export default function Total({
  metrics,
  totalDataKey = TOTAL_CALCULATED_DATA_KEY,
  label,
  compareMode = false,
  isCompareMetricMain = false,
}: {
  metrics: any[]
  totalDataKey?: string
  label?: string
  compareMode?: boolean
  isCompareMetricMain?: boolean
}) {
  const [mainMetricKey, compareMetricKey] = isCompareMetricMain
    ? [`compared.${totalDataKey.toLowerCase()}`, totalDataKey.toLowerCase()]
    : [totalDataKey.toLowerCase(), `compared.${totalDataKey.toLowerCase()}`]
  const absTotal = metrics.reduce(
    (acc, metric) => acc + (metric ? (metric[mainMetricKey] ?? 0) : 0),
    0,
  )
  const absCompareTotal = compareMode
    ? metrics.reduce(
        (acc, metric) => acc + (metric ? (metric[compareMetricKey] ?? 0) : 0),
        0,
      )
    : null

  return (
    <>
      <H2 className="flex gap-3 items-center">
        {label ?? "Total"}: {toNumber(absTotal)}
        {compareMode && (
          <div className="flex items-center gap-3 rounded-xl px-2 bg-background-dark">
            <ValueWithDelta
              className="text-base"
              size={20}
              actualValue={absTotal}
              comparedValue={absCompareTotal}
              hideActualValue
            />
            <span className="text-sm">
              prev. {numeral(absCompareTotal).format("0.00a")}
            </span>
          </div>
        )}
      </H2>
    </>
  )
}
