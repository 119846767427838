import { TDashboardItemsSet } from "~/ui-rtk/constants/dashboard"
import useSources from "~/ui-rtk/hooks/sources"
import { getContentWidgetIds } from "~/ui-rtk/utils/content"
import { useVisualizationsMap } from "~/ui-rtk/hooks/visualizations"
import { type TLastUpdateDateProps } from "~/ui-rtk/hooks/cube"
import { VISUALIZATIONS_MAP } from "~/ui-rtk/constants/charts"
import { useMemo } from "react"
import useDepVar from "~/ui-rtk/hooks/dep-var"
import useBaselineRevenueMetric from "~/ui-rtk/hooks/baseline-revenue-metric"

const content = {
  id: "clsaphypc000608jy5jef5svc",
  slug: "/brand-value/overview",
  icon: null,
  title: "Overview",
  parent: {
    slug: "/brand-value",
    title: "Brand Value",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 20.1,
  content: {
    items: [
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "brand_value_stacked_chart",
          },
        ],
      },
      {
        className: "min-h-[25rem] max-h-[40rem]",
        grid: 1,
        items: [
          {
            widget: "brand_value_conversions_table",
          },
        ],
      },
    ],
    title: "Overview",
    periodPicker: {
      visible: true,
    },
    lastDateChipProps: {
      dateMetric: "company_last_updated_date.brand_value_overview_max_date",
    } as TLastUpdateDateProps,
  },
  items: [],
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  const widgetIds = getContentWidgetIds(
    content.content.items as TDashboardItemsSet,
  )
  const { visualizations } = useVisualizationsMap(widgetIds)

  const { depVar, depVarScaler, toggleAmazon, amazonEnabled } = useDepVar()
  const baselineMetric = useBaselineRevenueMetric()

  const requiredSources = useMemo(
    () =>
      Array.from(
        new Set(
          widgetIds.reduce(
            (acc: string[], widget) => [
              ...acc,
              ...(VISUALIZATIONS_MAP[widget].requiredSources ?? []),
            ],
            [],
          ),
        ),
      ),
    [],
  )

  return {
    content,
    toggleAmazon,
    amazonEnabled,
    sources,
    visualizations,
    requiredSources,
    cubeQueryParams: {
      depVar,
      depVarScaler,
      baselineMetric,
    } as Record<string, string | string[]>,
    isLoading: isSourcesDataLoading,
  }
}
