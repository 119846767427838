import { cn } from "~/ui-rtk/utils/tailwind-utils"
import {
  TColGroupItem,
  TMetrics,
  type TConfigItemColumn,
  type TSimpleTableProps,
} from "./types"
import ValueWithDelta from "~/ui-rtk/components/ui/common/ValueWithDelta/ValueWithDelta"
import { safeFormat } from "../TableVisualization/utils"
import { type TableColumnType } from "~/ui-rtk/constants/table-visualization-types"
import { Card } from "~/ui-rtk/components/ui/common"

export default function SimpleTable({
  metrics,
  className,
  columnConfig: config,
  compareMode = false,
  showTotalRow = false,
}: TSimpleTableProps) {
  const mainMetrics = (compareMode ? metrics[0] : metrics) as TMetrics
  const compareMetrics = compareMode ? (metrics[1] as TMetrics) : null

  const cellClasses = "px-2 py-1.5 border-s-basic-blue text-ellipsis text-sm"
  const thClasses = "px-2 py-1.5 bg-basic-dark-blue border-s-basic-blue text-md"

  const renderCell = (idx: number, col: TConfigItemColumn) => {
    const mainMetricsRow = mainMetrics[idx]
    if (col.render) {
      return col.render(mainMetricsRow)
    }
    const value = mainMetricsRow[col.dataKey]
    const compareValue =
      compareMode && compareMetrics ? compareMetrics[idx][col.dataKey] : null
    const suffix = col.getSuffix ? col.getSuffix(mainMetricsRow) : ""

    return (
      <>
        {safeFormat(value, col.type as TableColumnType)} {suffix}
        {compareMode && (
          <div className={`p-0.5 text-sm flex gap-2`}>
            <ValueWithDelta
              actualValue={value as number}
              comparedValue={compareValue as number}
              hideActualValue
            />
            <span>
              prev: {safeFormat(compareValue, col.type as TableColumnType)}
            </span>
          </div>
        )}
      </>
    )
  }

  const renderTotal = (colGroup: TColGroupItem) => {
    if (colGroup.totalRender) {
      return colGroup.totalRender(mainMetrics)
    }

    if (!colGroup.totalDataKey) {
      return "- -"
    }

    const value = mainMetrics.reduce(
      (acc, row) => acc + +(row[colGroup.totalDataKey ?? ""] ?? 0),
      0,
    )
    const compareValue =
      compareMode && compareMetrics
        ? compareMetrics.reduce(
            (acc, row) =>
              acc +
              +(row[colGroup.totalDataKey ? colGroup.totalDataKey : ""] ?? 0),
            0,
          )
        : null

    return (
      <>
        {safeFormat(
          value,
          (colGroup.totalType as TableColumnType) ?? "currency",
        )}
        {compareMode && (
          <div className={`p-0.5 text-sm flex gap-2`}>
            <ValueWithDelta
              actualValue={value}
              comparedValue={compareValue ?? 0}
              hideActualValue
            />
            <span>
              prev:{" "}
              {safeFormat(
                compareValue,
                (colGroup.totalType as TableColumnType) ?? "currency",
              )}
            </span>
          </div>
        )}
      </>
    )
  }

  return (
    <Card
      rounded
      className={cn(
        "h-full space-y-3 border border-basic-blue bg-basic-dark-blue",
        className,
      )}
    >
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="w-full border-b border-b-basic-blue">
            <tr>
              {config.map(colGroup => (
                <th
                  key={colGroup.id}
                  className={cn(
                    thClasses,
                    "min-w-35.5 text-white font-bold leading-6 tracking-[-2%]",
                  )}
                  colSpan={colGroup.columns.length}
                  align="left"
                >
                  {colGroup.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full">
            {mainMetrics.map((_row, idz) => (
              <tr
                key={idz}
                className="border-b border-b-basic-blue bg-basic-black"
              >
                {config.map((colGroup, idx) =>
                  colGroup.columns.map((col, idy) => (
                    <td
                      className={cn(cellClasses, idx + idy && "border-s")}
                      key={`${idx}-${idy}`}
                    >
                      {renderCell(idz, col)}
                    </td>
                  )),
                )}
              </tr>
            ))}
          </tbody>
          {showTotalRow && (
            <tfoot>
              <tr>
                {config.map(colGroup => (
                  <th
                    key={colGroup.id}
                    className={cn(
                      thClasses,
                      "text-4.5 min-w-35.5 text-white font-bold leading-6 tracking-[-2%]",
                    )}
                    colSpan={colGroup.columns.length}
                    align="left"
                  >
                    {renderTotal(colGroup)}
                  </th>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </Card>
  )
}
