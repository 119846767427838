import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { Breadcrumbs } from "~/ui-rtk/components/features/dashboards/BrandMediaOptimizationDashboard/components/Breadcrumbs/index"
import useConnect, {
  CREATIVE_REPORT_OPTIONS,
  CREATIVE_REPORTS_METRICS_MAP,
  DEFAULT_METRICS,
  ALWAYS_METRICS,
  type TCreativeReport,
  type TMetricsMapKey,
  ITEMS_PER_PAGE,
  MONETARY_METRICS,
} from "./connect"
import { MouseEvent, useEffect, useMemo, useState } from "react"
import DropdownMenu from "~/ui-rtk/components/ui/controls/DropdownMenu/DropdownMenu"
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
  ViewfinderCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import { H5 } from "~/ui-rtk/components/ui/typography"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import SearchSelect, {
  type Option,
} from "~/ui-rtk/components/ui/common/SearchSelect/index"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { PeriodPicker } from "~/ui-rtk/components/ui/controls"
import useDate from "~/ui-rtk/hooks/date"
import { DATE_FORMAT, usePeriod } from "~/ui-rtk/hooks/period"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import { AggregationType } from "~/ui-rtk/constants/charts"
import { CaptionPreview, Card, Loader } from "~/ui-rtk/components/ui/common"
import useUrlPagination from "~/ui-rtk/hooks/url-pagination"
import CreativeReportCard from "./components/CreativeReportCard"
import { TableVisualization } from "~/ui-rtk/components/ui/charts"
import {
  TableVisualizationColumn,
  TMetric,
} from "~/ui-rtk/constants/table-visualization-types"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import useAdImage from "~/ui-rtk/hooks/ad-image"
import { Image } from "~/ui-rtk/components/ui/common/Image"

const MODE_URL_KEY = "report-mode"
const METRICS_URL_KEY = "metrics"

const CREATIVE_REPORTS_METRICS_OPTIONS = Object.keys(
  CREATIVE_REPORTS_METRICS_MAP,
).map(
  (key: string) =>
    ({
      value: key,
      label: CREATIVE_REPORTS_METRICS_MAP[key as TMetricsMapKey],
    }) as Option,
)

const CREATIVE_REPORTS_METRICS_OPTIONS_MAP = Object.keys(
  CREATIVE_REPORTS_METRICS_MAP,
).reduce(
  (acc: Record<TMetricsMapKey, Option>, key: string) => ({
    ...acc,
    [key]: {
      value: key,
      label: CREATIVE_REPORTS_METRICS_MAP[key as TMetricsMapKey],
    } as Option,
  }),
  {} as Record<TMetricsMapKey, Option>,
)

const dayjs = useDate()

const visibleItemsAmount = 2
const hiddenItemsAmount = 6
const maxDateRestriction = dayjs().toDate()

const CHART_ID = "cr"

export default function CreativeReportsPage() {
  const {
    isLoading,
    requestReportingItems,
    requestReportingItemsSummary,
    reportingItems,
    reportingItemsSummary,
    depVar,
    totalPages,
    depVarScaler,
  } = useConnect()
  const { params, setParams } = useURLSearchParams()
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [, rerender] = useState(0)
  const currentCompany = useCurrentCompany()

  const creativeReportMode = useMemo<TCreativeReport>(
    () =>
      (params[MODE_URL_KEY] as TCreativeReport) ??
      CREATIVE_REPORT_OPTIONS[0].value,
    [params],
  )

  const selectedMetrics: TMetricsMapKey[] = useMemo(() => {
    const metricsFromParams = params[METRICS_URL_KEY]
      ? (params[METRICS_URL_KEY]?.split(",") as TMetricsMapKey[])
      : []

    return metricsFromParams.length > 0 ? metricsFromParams : DEFAULT_METRICS
  }, [params])

  const { dateRange } = useMinMaxDateRange(
    undefined,
    maxDateRestriction,
    AggregationType.DAILY,
  )
  const { setPeriod } = usePeriod()

  const { pagination, setPagination } = useUrlPagination(
    CHART_ID,
    ITEMS_PER_PAGE,
  )

  useEffect(() => {
    void requestReportingItems(
      creativeReportMode,
      dateRange,
      pagination.pageIndex,
    )
  }, [creativeReportMode, depVar, depVarScaler, pagination.pageIndex])

  useEffect(() => {
    void requestReportingItemsSummary(creativeReportMode, dateRange)
  }, [creativeReportMode, depVar, depVarScaler])

  const setCreativeReportMode = (mode: TCreativeReport) => {
    setParams({
      [MODE_URL_KEY]: mode,
    })
  }

  const changePeriod = (dateRange: [Date, Date]) => {
    const [start, end] = dateRange.map(date => dayjs(date))

    void setPeriod(start.format(DATE_FORMAT), end.format(DATE_FORMAT))
  }

  const setSelectedMetric = (metric: string | null, idx: number) => {
    const metrics: Array<string | null> = [...selectedMetrics]
    metrics[idx] = metric

    setParams({
      [METRICS_URL_KEY]: metrics.join(","),
    })
    rerender(idx)
  }

  const allwaysSeenMetrics = useMemo(() => {
    const emptyArray = new Array(visibleItemsAmount).fill(null)

    return [...selectedMetrics, ...emptyArray].slice(0, visibleItemsAmount)
  }, [selectedMetrics])

  const collapseMetrics = useMemo(() => {
    const emptyArray = new Array(hiddenItemsAmount).fill(null)
    return [...selectedMetrics, ...emptyArray].slice(
      visibleItemsAmount,
      visibleItemsAmount + hiddenItemsAmount,
    )
  }, [selectedMetrics])

  const selectedCreativeReportModel = useMemo(
    () =>
      CREATIVE_REPORT_OPTIONS.find(
        option => option.value === creativeReportMode,
      ),
    [creativeReportMode],
  )

  const selectedCreativeReportModeLabel = useMemo(
    () => selectedCreativeReportModel?.label,
    [selectedCreativeReportModel],
  )

  const selectedCreativeReportModePrimaryId = useMemo(
    () => selectedCreativeReportModel?.primaryId,
    [selectedCreativeReportModel],
  )

  const selectedCreativeReportModeSkipTitle = useMemo(
    () => selectedCreativeReportModel?.skipPrimaryId,
    [selectedCreativeReportModel],
  )

  const metricsOptions = useMemo(
    () =>
      CREATIVE_REPORTS_METRICS_OPTIONS.filter(
        option =>
          !selectedMetrics.includes(option.value as TMetricsMapKey) &&
          !ALWAYS_METRICS.includes(option.value as TMetricsMapKey),
      ),
    [selectedMetrics],
  )

  const allMetrics = useMemo(
    () =>
      [...ALWAYS_METRICS, ...selectedMetrics].filter(
        (metric: TMetricsMapKey) => !!metric,
      ),
    [selectedMetrics],
  )

  const columnConfig = useMemo(
    () =>
      [
        ...(selectedCreativeReportModeSkipTitle &&
        selectedCreativeReportModePrimaryId &&
        selectedCreativeReportModeLabel
          ? []
          : [
              {
                header: selectedCreativeReportModeLabel,
                disableSorting: true,
                key: selectedCreativeReportModePrimaryId,
                render: (row: any) => {
                  if (!selectedCreativeReportModePrimaryId) {
                    return ""
                  }
                  const caption = row[selectedCreativeReportModePrimaryId]
                  if (typeof caption !== "string") {
                    return ""
                  }

                  return (
                    <CaptionPreview
                      text={caption}
                      alwaysShow={true}
                      exact={true}
                      fullCaptionClass="max-w-200 word-break break-words"
                      align="left"
                    />
                  )
                },
              },
            ]),
        {
          type: "image",
          header: "Media Preview",
          key: "thumbnail_url",
          disableSorting: true,
          render: (row: any) => {
            const imageUrl = useAdImage(
              currentCompany?.id,
              "meta",
              row["all_ads"][0],
            )
            const fallbackUrl = row["thumbnail_url"]

            return (
              <Image
                classes={{
                  image: "w-full h-full",
                  wrapper: "aspect-square overflow-hidden rounded-xl",
                }}
                src={imageUrl}
                fallback={fallbackUrl}
                alt={row[selectedCreativeReportModePrimaryId ?? ""]}
              />
            )
          },
          width: 400,
        },
        ...allMetrics.map(metric => ({
          key: metric,
          header: CREATIVE_REPORTS_METRICS_MAP[metric],
          type: MONETARY_METRICS.includes(metric) ? "currency" : "number",
          disableSorting: true,
        })),
      ] as TableVisualizationColumn[],
    [
      selectedCreativeReportModeSkipTitle,
      selectedCreativeReportModePrimaryId,
      allMetrics,
    ],
  )

  return (
    <WithLoader isLoading={isLoading}>
      <div className="creative-reports-page h-full overflow-y-visible">
        <Breadcrumbs
          slug={brandRoutes.BRAND_MEDIA.CREATIVE_REPORTS}
          title={"Creative Reporting"}
          parent={{
            slug: "/brand-media",
            title: "Brand Media",
          }}
        />
        <div className="mt-6 flex justify-between items-center">
          <DropdownMenu
            options={CREATIVE_REPORT_OPTIONS}
            onItemClick={value =>
              setCreativeReportMode(value as TCreativeReport)
            }
            className="border border-basic-blue bg-basic-dark-blue rounded-md"
          >
            <div className="flex gap-2 items-center justify-center">
              <ViewfinderCircleIcon className="w-5 h-5 text-white"></ViewfinderCircleIcon>
              <div>Report on: {selectedCreativeReportModeLabel}</div>
              <ChevronDownIcon className="w-3 h-3 text-white" />
            </div>
          </DropdownMenu>
          <PeriodPicker
            dateRange={dateRange}
            onChange={changePeriod}
            restrictions={{
              maxDate: maxDateRestriction,
            }}
            comparisonEnabled={false}
          />
        </div>
        <div className="mt-6 rounded border border-basic-blue bg-basic-dark-blue p-4 overflow-y-visible">
          <H5>Select Metrics or Dimensions</H5>
          <div className="grid grid-cols-3 mt-3 gap-2 text-sm">
            <div className="grid grid-cols-2 col-span-2 gap-2">
              {ALWAYS_METRICS.map((metric: TMetricsMapKey) => (
                <Card
                  rounded
                  key={metric}
                  className="border border-basic-blue bg-basic-blue px-4 py-2 text-cadet-blue/75"
                >
                  {CREATIVE_REPORTS_METRICS_OPTIONS_MAP[metric].label}
                </Card>
              ))}
              {allwaysSeenMetrics.map((metric: TMetricsMapKey, idx) => {
                const selectedOption =
                  CREATIVE_REPORTS_METRICS_OPTIONS_MAP[metric] ?? null
                const options = selectedOption
                  ? [selectedOption, ...metricsOptions]
                  : metricsOptions

                return (
                  <SearchSelect
                    key={`${metric}_${idx}`}
                    classes={{
                      container: "max-w-full",
                      input: "border-basic-blue bg-basic-blue rounded-md",
                    }}
                    options={options}
                    value={selectedOption}
                    onSelect={(option: Option) => {
                      setSelectedMetric(option.value as string, idx)
                    }}
                    Icon={
                      <XMarkIcon
                        className="w-4 h-4"
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation()
                          setSelectedMetric(null, idx)
                        }}
                      />
                    }
                  />
                )
              })}
            </div>
            <div
              className={cn(
                "collapse border-0 col-span-2 w-full mt-2 overflow-visible",
                {
                  "collapse-open": collapseOpen,
                  "collapse-close": !collapseOpen,
                },
              )}
            >
              <input type="checkbox" className="max-h-1 h-1 hidden" />
              <div
                className="collapse-title font-semibold flex items-center justify-center w-full p-0 m-0 h-5 min-h-5"
                onClick={() => setCollapseOpen(!collapseOpen)}
              >
                {collapseOpen ? (
                  <ChevronDoubleUpIcon className="w-5 h-5 text-basic-neutral" />
                ) : (
                  <ChevronDoubleDownIcon className="w-5 h-5 text-basic-neutral" />
                )}
              </div>
              <div className="collapse-content p-0">
                <div className="grid grid-cols-2 col-span-2 gap-2 mt-3">
                  {collapseMetrics.map((metric, idx) => {
                    const selectedOption =
                      CREATIVE_REPORTS_METRICS_OPTIONS_MAP[
                        metric as TMetricsMapKey
                      ] ?? null

                    const options = selectedOption
                      ? [selectedOption, ...metricsOptions]
                      : metricsOptions

                    return (
                      <SearchSelect
                        key={`${metric}_${idx}`}
                        classes={{
                          container: "max-w-full",
                          input: "border-basic-blue bg-basic-blue rounded-md",
                        }}
                        options={options}
                        value={selectedOption}
                        onSelect={(option: Option) => {
                          setSelectedMetric(
                            option.value as string,
                            idx + visibleItemsAmount,
                          )
                        }}
                        Icon={
                          <XMarkIcon
                            className="w-4 h-4"
                            onClick={(e: MouseEvent) => {
                              e.stopPropagation()
                              setSelectedMetric(null, idx + visibleItemsAmount)
                            }}
                          />
                        }
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="h-0.5 w-full flex items-center justify-center">
            <Loader size={30} />
          </div>
        ) : (
          <>
            <div className="mt-6 grid grid-cols-4 gap-3">
              {reportingItems?.map((item, idx) => {
                const itemPrimaryId =
                  item[selectedCreativeReportModePrimaryId ?? ""]
                const imgUrl = useAdImage(
                  currentCompany?.id,
                  "meta",
                  item["all_ads"][0],
                )
                return (
                  <CreativeReportCard
                    key={`${itemPrimaryId}_${idx}`}
                    imgUrl={imgUrl}
                    primaryIdKey={
                      selectedCreativeReportModePrimaryId ?? "thumbnail_url"
                    }
                    skipTitle={selectedCreativeReportModeSkipTitle}
                    card={item}
                    metricsNames={allMetrics}
                  />
                )
              })}
            </div>
            <div className="mt-6">
              <TableVisualization
                metrics={(reportingItems as TMetric[]) ?? []}
                enablePagination={true}
                chartId={CHART_ID}
                paginationState={pagination}
                summaryMetrics={reportingItemsSummary ?? undefined}
                setPaginationState={setPagination}
                columnConfig={columnConfig}
                totalPages={totalPages}
                hideFilters={true}
                hideItemsPerPage={true}
              />
            </div>
          </>
        )}
      </div>
    </WithLoader>
  )
}
