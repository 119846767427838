import { useMemo } from "react"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"
import { H1 } from "~/ui-rtk/components/ui/typography"
import { HOMEPAGE_SECTIONS } from "~/ui-rtk/pages/Home/constants"
import SetupGuideSection from "./components/SetupGuide/SetupGuideSection"
import LearningZoneSection from "./components/LearningZone/LearningZoneSection"
import Top10HeroAdsSection from "./components/RecommendedActions/Top10HeroAds"
import Top10BrandAdsSection from "./components/RecommendedActions/Top10BrandAds"
import BrandValueIncreaseAdsetBudgetSection from "./components/RecommendedActions/BrandValueIncreaseAdSetBudget"
import GoalsSection from "./components/Goals/GoalsSection"
import BrandValueIncreaseCampaignBudgetSection from "./components/RecommendedActions/BrandValueIncreaseCampaignBudget"
import MetricsStatistics from "./components/MetricsStatistics/MetricsStatistics"

export interface THomeDashboardProps {
  title: string
  slug: string
  dateRangeRestrictions?: Partial<{ minDate: Date; maxDate: Date }>
  items: Record<string, Record<string, any>>
}

export default function HomeDashboard({
  title,
  slug,
  items,
}: THomeDashboardProps) {
  const canSeeMetricStatistics = useMemo(
    () => items[HOMEPAGE_SECTIONS.METRIC_STATISTICS].isEnabled,
    [items],
  )
  const canSeeSetupGuideSection = useMemo(
    () => items[HOMEPAGE_SECTIONS.SETUP_GUIDE].isEnabled,
    [items],
  )
  const canSeeLearningZoneSection = useMemo(
    () => items[HOMEPAGE_SECTIONS.LEARNING_ZONE].isEnabled,
    [items],
  )
  const canSeeRecommendedActionsSection = useMemo(
    () => items[HOMEPAGE_SECTIONS.RECOMMENDED_ACTIONS].isEnabled,
    [items],
  )
  const canSeeGoalsSection = useMemo(
    () => items[HOMEPAGE_SECTIONS.GOALS].isEnabled,
    [items],
  )
  return (
    <div className="home-dashboard">
      <div className="text-start">
        <H1 className="flex">
          <Breadcrumbs
            items={[
              {
                name: "Welcome",
              },
              {
                slug,
                name: title,
              },
            ]}
          />
        </H1>
      </div>
      {canSeeMetricStatistics ? (
        <div className="mt-8">
          <MetricsStatistics />
        </div>
      ) : null}
      {canSeeSetupGuideSection ? (
        <div className="mt-8">
          <SetupGuideSection />
        </div>
      ) : null}
      {canSeeRecommendedActionsSection ? (
        <div className="mt-8">
          <Top10HeroAdsSection />
        </div>
      ) : null}
      {canSeeRecommendedActionsSection ? (
        <div className="mt-8">
          <Top10BrandAdsSection />
        </div>
      ) : null}
      {canSeeRecommendedActionsSection ? (
        <div className="mt-8">
          <BrandValueIncreaseCampaignBudgetSection />
        </div>
      ) : null}
      {canSeeRecommendedActionsSection ? (
        <div className="mt-8">
          <BrandValueIncreaseAdsetBudgetSection />
        </div>
      ) : null}
      {canSeeGoalsSection ? (
        <div className="mt-8">
          <GoalsSection />
        </div>
      ) : null}
      {canSeeLearningZoneSection ? (
        <div className="mt-8">
          <LearningZoneSection />
        </div>
      ) : null}
    </div>
  )
}
