import type { TButtonColor, TButtonSize, TButtonVariant } from "./"

const commonBtnClassNames =
  "flex items-center rounded-lg text-4 line-height-4 font-semibold leading-5 transition-all active:scale-95"

export const btnColorMap: { [key in TButtonColor]: string } = {
  red: `${commonBtnClassNames} bg-states-error/[.15] text-basic-white border border-states-error hover:opacity-90 hover:border-states-error/[.70] hover:text-basic-white/[.70] disabled:bg-states-error/[.05] disabled:border-states-error/[.40] disabled:text-basic-white/[.60] active:bg-states-error[.25] active:opacity-90 active:text-basic-white/[.90]`,
  pink: `${commonBtnClassNames} bg-basic-pink text-basic-black hover:bg-basic-pink-hover disabled:opacity-90 disabled:bg-basic-grey-inactive disabled:text-basic-black/[.60] active:bg-basic-pink/[.90]`,
  white: `${commonBtnClassNames} bg-basic-white text-basic-black opacity-90 hover:bg-basic-white/[.80] disabled:bg-basic-white/[.60] disabled:text-basic-black/[.60] active:bg-basic-white/[.90]`,
  blue: `${commonBtnClassNames} bg-basic-dark-blue border border-basic-blue hover:bg-basic-blue`,
}

export const buttonSizeMap: { [key in TButtonSize]: string } = {
  xs: "py-1.5 px-2 text-sm",
  sm: "py-2 px-4 text-sm",
  md: "py-3 px-4 text-md",
  lg: "p-4 text-lg",
}

export const getButtonVariant = (arg: TButtonVariant) => {
  switch (arg.variant) {
    case "outlined":
      return "ring-1 rounded-lg ring-inset ring-white"
    case "underlined":
      return ""
    case "text":
      return `${commonBtnClassNames} text-basic-white hover:text-basic-white/[.80] active:text-basic-white/[.90] disabled:text-basic-grey-inactive`
    default:
      return btnColorMap[arg.color]
  }
}
