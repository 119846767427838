import { useState } from "react"
import {
  useCompanyGoalControllerGetAllCompanyGoalsQuery,
  useCompanyGoalControllerRemovCompanyGoalMutation,
} from "~/ui-rtk/api/companyGoalApi"

export default function useGoals() {
  const { data, isLoading, isError, refetch, isUninitialized } =
    useCompanyGoalControllerGetAllCompanyGoalsQuery()
  const [deleteGoal] = useCompanyGoalControllerRemovCompanyGoalMutation()
  const [deleteInProgress, setDeleteInProgress] = useState(false)

  const deleteGoalById = async (id: string) => {
    setDeleteInProgress(true)
    await deleteGoal({ id })
    await refetch()
    setDeleteInProgress(false)
  }

  const tryRefetch = () => {
    if (!isUninitialized) {
      void refetch()
    }
  }

  return {
    isLoading: isLoading || deleteInProgress,
    isError,
    goals: data,
    tryRefetch,
    deleteGoal: deleteGoalById,
  }
}
