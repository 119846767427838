import { Toggle } from "~/ui-rtk/components/ui/controls/Toggle"
import { Image } from "~/ui-rtk/components/ui/common/Image"

import AmazonSvg from "/assets/img/sources/amazon_ads.svg?url"
import { Card, RichTooltip } from "../../common"
import usePostHogFeatureFlags from "~/ui-rtk/hooks/posthog-feaures"
import { POSTHOG_FEATURES } from "~/ui-rtk/constants/feature-flags"

export default function AmazonToggle({
  amazonEnabled,
  toggleAmazon,
}: {
  amazonEnabled?: boolean
  toggleAmazon: () => void
}) {
  if (!usePostHogFeatureFlags(POSTHOG_FEATURES.AMAZON_SCALER_TOGGLE)) {
    return null
  }

  return (
    <div className="flex justify-end">
      <div className="flex items-center gap-2 px-2 py-1.5 text-sm rounded-md border border-basic-blue bg-basic-dark-blue">
        <RichTooltip
          content={
            <Card
              rounded
              className="text-nowrap text-start leading-5 px-2 py-1.5"
            >
              Include Estimates for Amazon
            </Card>
          }
        >
          <Image size={20} src={AmazonSvg} alt="amazon icon" />
        </RichTooltip>
        <Toggle size="xs" value={amazonEnabled} onChange={toggleAmazon} />
      </div>
    </div>
  )
}
