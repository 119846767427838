import * as yup from "yup"
import { toast } from "react-toastify"

import { CompanyMetadataDto } from "~/ui-rtk/api/types"
import { useCompanyMetadataUpdater } from "~/ui-rtk/hooks/company-updater"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import { LocalStorageKeys, useLocalStorage } from "~/ui-rtk/utils/storage"

export type TKeywordsPageFormValues = {
  googleBrands: string[]
}

export const validationSchema = yup.object().shape({
  googleBrands: yup.array().of(yup.string()),
  // .min(1, "At least one brand is required")
  // .required("Please fill keywords"),
})

export default function useConnect() {
  const [dontShowDialogAgain, setDontShowDialogAgain] =
    useLocalStorage<boolean>(
      LocalStorageKeys.LOCAL_STORAGE_DONT_SHOW_AGAIN_SAVE_KEYWORD_DIALOG_NOTIFICATON_KEY,
      false,
    )

  const currentCompany = useCurrentCompany()
  const { updateCompanyMetadata } = useCompanyMetadataUpdater()
  const { keywords } = (currentCompany?.metadata ?? {}) as {
    keywords: CompanyMetadataDto["keywords"]
  }

  const initialValues: TKeywordsPageFormValues = {
    googleBrands: keywords?.google?.brands ?? [],
  }

  const setDontShowDialogAgainHandler = (val: boolean) => {
    setDontShowDialogAgain(val)
  }

  const updateKeywords = async ({ googleBrands }: TKeywordsPageFormValues) => {
    if (!currentCompany) {
      return
    }
    const metadata: CompanyMetadataDto = {
      ...(currentCompany?.metadata ?? {}),
      keywords: {
        ...(currentCompany?.metadata?.keywords ?? {}),
        google: {
          ...(currentCompany?.metadata?.keywords?.google ?? {
            hash: null,
            competitors: [],
            categories: [],
          }),
          brands: googleBrands,
        },
      },
    }

    try {
      await updateCompanyMetadata(metadata)
      if (dontShowDialogAgain) {
        toast.success("Keywords were updated")
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  return {
    companyName: currentCompany?.name,
    initialValues,
    validationSchema,
    updateKeywords,
    dontShowDialogAgain,
    setDontShowDialogAgain: setDontShowDialogAgainHandler,
  }
}
