import { LocalStorageKeys, useLocalStorage } from "~/ui-rtk/utils/storage"
import { PaginationSkeleton } from "./PaginationSkeleton"
import "./skeleton.scss"
import { ITEMS_PER_PAGE } from "../TableVisualization/constants"
import useRefetchDialog from "~/ui-rtk/hooks/refetch-dialog"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { Card } from "../../common"

export function TableSkeleton() {
  const [itemsPerPage] = useLocalStorage<number>(
    LocalStorageKeys.TABLE_VISUALIZATION_ITEMS_PER_PAGE,
    ITEMS_PER_PAGE,
  )

  const refetchContent = useRefetchDialog()

  const rows = new Array(itemsPerPage).fill("&nbsp;")
  const cols = new Array(6).fill("&nbsp;")
  return (
    <Card
      rounded
      className="h-full mt-[54px] px-0 py-0 space-y-3 overflow-hidden border border-basic-blue bg-basic-black relative"
    >
      {refetchContent}
      <table className="table border-b border-b-basic-blue !mt-0">
        <thead>
          <tr>
            {cols.map((_, idx) => (
              <th
                key={`skeleton-head-${idx}`}
                className={cn(
                  "px-2 py-1.5 bg-basic-dark-blue  border-s-basic-blue",
                  idx && "border-s",
                )}
              >
                <div className="skeleton bg-basic-dark-blue">&nbsp;</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((_, idx) => (
            <tr
              key={`skeleton-row-${idx}`}
              className={"border-b border-b-basic-blue bg-basic-black"}
            >
              {cols.map((__, idy) => (
                <td
                  key={`skeleton-cell-${idx}-${idy}`}
                  className={cn(
                    "px-2 py-1.5 border-s-basic-blue",
                    idy && "border-s",
                  )}
                >
                  <div className="skeleton bg-basic-black">&nbsp;</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationSkeleton />
    </Card>
  )
}
