import { useEffect, useMemo, useState } from "react"
import useConnect from "./connect"
import { toast } from "react-toastify"
import { DashboardBreadcrumbs, Loader } from "~/ui-rtk/components/ui/common"
import SimpleTable from "~/ui-rtk/components/ui/charts/SimpleTable/SimpleTable"
import { Link } from "react-router-dom"
import { brandRoutes } from "~/ui-rtk/constants/routes"

const columnConfig = [
  {
    title: "caption",
    id: "caption",
    columns: [
      {
        dataKey: "caption",
        type: "string",
      },
    ],
  },
  {
    title: "comments_count",
    id: "comments_count",
    columns: [
      {
        dataKey: "comments_count",
        type: "number",
      },
    ],
  },
  {
    title: "id",
    id: "id",
    columns: [
      {
        dataKey: "id",
        type: "string",
      },
    ],
  },
  {
    title: "like_count",
    id: "like_count",
    columns: [
      {
        dataKey: "like_count",
        type: "number",
      },
    ],
  },
  {
    title: "media_product_type",
    id: "media_product_type",
    columns: [
      {
        dataKey: "media_product_type",
        type: "string",
      },
    ],
  },
  {
    title: "media_type",
    id: "media_type",
    columns: [
      {
        dataKey: "media_type",
        type: "string",
      },
    ],
  },
  {
    title: "timestamp",
    id: "timestamp",
    columns: [
      {
        dataKey: "timestamp",
        type: "string",
      },
    ],
  },
  {
    title: "username",
    id: "username",
    columns: [
      {
        dataKey: "username",
        type: "string",
      },
    ],
  },
  {
    title: "permalink",
    id: "permalink",
    columns: [
      {
        dataKey: "permalink",
        type: "string",
        render: (row: Record<string, string | number>) =>
          row.permalink ? (
            <Link
              to={row.permalink as string}
              target="_blank"
              className="text-blue"
            >
              View Post
            </Link>
          ) : (
            <>-</>
          ),
      },
    ],
  },
]

type TNavigationState = {
  type: "before" | "after"
  value: string
} | null

export default function MentionsReviewPage() {
  const [toPage, setToPage] = useState<TNavigationState>(null)
  const paginationState = useMemo(() => {
    if (!toPage) {
      return {}
    }
    return {
      [toPage.type]: toPage.value,
    }
  }, [toPage])
  const { data, isLoading, isError } = useConnect(paginationState)

  useEffect(() => {
    if (isError) {
      toast.error("Failed to fetch instagram tags")
    }
  }, [isError])

  const content = useMemo(() => {
    if (isError) {
      return (
        <div className="w-full h-50 flex items-center justify-center text-lg">
          Could not fetch instagram tags
        </div>
      )
    }
    if (isLoading) {
      return (
        <div className="w-full h-100 flex items-center justify-center text-lg">
          <Loader size={50} />
        </div>
      )
    }
    if (data?.data) {
      return (
        <div>
          <SimpleTable metrics={data.data} columnConfig={columnConfig} />
        </div>
      )
    }
  }, [isLoading])

  const handlePrevPage = (value: string) => {
    setToPage({
      type: "before",
      value,
    })
  }

  const handleNextPage = (value: string) => {
    setToPage({
      type: "after",
      value,
    })
  }

  const pagination = useMemo(() => {
    if (!data?.paging) {
      return null
    }
    const { before, after } = data.paging.cursors
    return (
      <div className="join grid grid-cols-2">
        {before ? (
          <button
            className="join-item btn btn-outline"
            onClick={() => handlePrevPage(before)}
          >
            &lt; Previous Page
          </button>
        ) : null}
        {after ? (
          <button
            className="join-item btn btn-outline"
            onClick={() => handleNextPage(after)}
          >
            Next Page &gt;
          </button>
        ) : null}
      </div>
    )
  }, [data])

  return (
    <>
      <DashboardBreadcrumbs
        slug={brandRoutes.BRAND_MEDIA.MEDIA_MENTIONS}
        title="Mentioned Media [Beta]"
        parent={{
          slug: brandRoutes.BRAND_MEDIA.ROOT,
          title: "Brand Media",
        }}
      />

      <div className="mt-3">{content}</div>
      <div className="mt-3">{pagination}</div>
    </>
  )
}
