import { URL_SOURCE_KEYS_PARAM_KEY } from "../constants/url-params"
import { useUrlParamsStateArray } from "./urlParamsState"

export default function useUrlSourceKeys(
  chartId = "",
  initialSources: string[] = [],
) {
  const urlKey = `${chartId}_${URL_SOURCE_KEYS_PARAM_KEY}`
  const { state: sourceKeys, setState: setSourceKeys } = useUrlParamsStateArray<
    Array<string>
  >(urlKey, initialSources)

  return {
    urlKey,
    sourceKeys: sourceKeys as string[],
    setSourceKeys,
  }
}
