import useDate from "~/ui-rtk/hooks/date"
import { COMPARE_TO, GOAL_METRIC, TCompareTo, TGoalMetric } from "../constants"
import { BinaryOperator, Filter } from "@cubejs-client/core"
import { polyfillCubeQueryWithTimeDimension } from "~/ui-rtk/utils/cube"
import { useState } from "react"
import useDepVar from "~/ui-rtk/hooks/dep-var"
import useBaselineRevenueMetric from "~/ui-rtk/hooks/baseline-revenue-metric"
import { useAppSession } from "../../Auth/useCubeApiToken"
import { GranularityMapping } from "~/ui-rtk/constants/metrics-mapping"

const dayjs = useDate()

export type TGoalChartData = {
  prevData: {
    date: Date
    value: number
  }[]
  actualData: {
    date: Date
    value: number
  }[]
}

export function getMainMetricKey(metric: TGoalMetric, baselineMetric: string) {
  if (metric === GOAL_METRIC.BRAND_VALUE) {
    return "company_platform_brand_value.revenue180Days"
  }
  return `metrics.${baselineMetric}`
}

export function getDateMetricKey(metric: TGoalMetric) {
  if (metric === GOAL_METRIC.BRAND_VALUE) {
    return "company_platform_brand_value.engagement_date"
  }
  return "metrics.date"
}

export function getQForGoalMetric(
  metric: TGoalMetric,
  dateRange: [Date, Date],
  {
    depVar,
    depVarScaler,
    baselineMetric,
  }: {
    depVar: string
    depVarScaler: string[]
    baselineMetric: string
  },
  withGranularty = true,
) {
  if (metric === GOAL_METRIC.BRAND_VALUE) {
    return polyfillCubeQueryWithTimeDimension(
      {
        measures: [getMainMetricKey(metric, baselineMetric)],
        timeDimensions: [
          {
            dimension: getDateMetricKey(metric),
          },
        ],
        filters: [
          {
            dimension: "company_platform_brand_value.dep_var",
            operator: "equals" as BinaryOperator,
            values: [depVar],
          },
          depVarScaler.length > 0
            ? {
                dimension: "company_platform_brand_value.dep_var_scaler",
                operator: "equals" as BinaryOperator,
                values: depVarScaler,
              }
            : undefined,
        ].filter(f => !!f) as Filter[],
      },
      {
        dateRange,
        granularity: withGranularty ? GranularityMapping.DAILY : undefined,
      },
    )
  }
  return polyfillCubeQueryWithTimeDimension(
    {
      measures: [getMainMetricKey(metric, baselineMetric)],
      timeDimensions: [
        {
          dimension: getDateMetricKey(metric),
        },
      ],
    },
    {
      dateRange,
      granularity: withGranularty ? GranularityMapping.DAILY : undefined,
    },
  )
}

export const processResultSet =
  (mainKey: string, dateKey: string) =>
  (
    acc: {
      metrics: Record<string, string>[]
      accVal: number
    },
    metric: Record<string, string>,
  ) => {
    const value = acc.accVal + +metric[mainKey]
    return {
      metrics: [
        ...acc.metrics,
        {
          date: metric[dateKey],
          value: acc.accVal,
        },
      ],
      accVal: value,
    }
  }

const startOfCurrentMonth = dayjs().startOf("month")

export function getGoalValueForDate(endOfMonthGoalValue: number, date: Date) {
  const today = dayjs(date)
  const todayDayNo = today.date()
  const daysInThisMonth = today.daysInMonth()
  const deltaValue = endOfMonthGoalValue / daysInThisMonth
  return deltaValue * todayDayNo
}

export default function useMetricChartData() {
  const [chartData, setChartData] = useState<TGoalChartData>({
    prevData: [],
    actualData: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const { depVar, depVarScaler } = useDepVar()
  const baselineMetric = useBaselineRevenueMetric()

  const { cubeApi } = useAppSession()

  const requestChartData = async (
    goalMetric: TGoalMetric,
    compareTo: TCompareTo,
    targetPeriod: Date,
  ) => {
    setIsLoading(true)
    const today = dayjs().startOf("day")
    const startOfMonth = dayjs(targetPeriod).startOf("month")
    const metricKey = getMainMetricKey(goalMetric, baselineMetric)
    const dateKey = getDateMetricKey(goalMetric)

    const startOfPrevPeriod =
      compareTo === COMPARE_TO.LAST_MONTH
        ? dayjs(targetPeriod).subtract(1, "month").startOf("month")
        : dayjs(targetPeriod).subtract(1, "year").startOf("month")
    const endOfPrevPeriod = startOfPrevPeriod.clone().endOf("month")

    const prevDataQ = getQForGoalMetric(
      goalMetric,
      [startOfPrevPeriod.toDate(), endOfPrevPeriod.toDate()],
      {
        depVar,
        depVarScaler,
        baselineMetric,
      },
    )

    const prevDataPromise = cubeApi.load(prevDataQ)
    const actualDataPromise = startOfMonth.isSame(startOfCurrentMonth, "month")
      ? cubeApi.load(
          getQForGoalMetric(
            goalMetric,
            [startOfMonth.toDate(), today.toDate()],
            {
              depVar,
              depVarScaler,
              baselineMetric,
            },
          ),
        )
      : Promise.resolve(null)

    const [actualData, prevData] = await Promise.all([
      actualDataPromise,
      prevDataPromise,
    ])

    setChartData({
      prevData: prevData
        ?.rawData()
        .reduce(processResultSet(metricKey, dateKey), {
          metrics: [],
          accVal: 0,
        }).metrics,
      actualData:
        actualData?.rawData().reduce(processResultSet(metricKey, dateKey), {
          metrics: [],
          accVal: 0,
        }).metrics ?? [],
    })

    setIsLoading(false)
  }

  return {
    chartData,
    isLoading,
    requestChartData,
  }
}
