import React from "react"
import BreadcrumbsComponent from "~/ui-rtk/components/layout/Breadcrumbs"

type TBreadcrumbsProps = {
  slug: string
  title: string
  parent: {
    slug: string
    title: string
  }
}

export const Breadcrumbs: React.FC<TBreadcrumbsProps> = ({
  slug,
  title,
  parent,
}) => (
  <div className="text-start">
    <BreadcrumbsComponent
      items={[
        {
          slug: parent.slug,
          name: parent.title,
        },
        {
          slug,
          name: title,
        },
      ]}
    />
  </div>
)
