import React from "react"
import MarathonDataLogo from "~/ui-rtk/components/ui/svg/Logo"
import type { TableColumnType } from "~/ui-rtk/constants/table-visualization-types"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type THeaderProps = {
  value?: string
  type: TableColumnType
  isComparing: boolean
  className?: string
  isBrand?: boolean
}

export const Header: React.FC<THeaderProps> = ({
  value,
  type,
  isComparing,
  className,
  isBrand,
}) => (
  <div className={cn(["w-full", className])}>
    <div className="flex items-center justify-start gap-2">
      {isBrand && (
        <div className="grow-0 shrink-0">
          <MarathonDataLogo />
        </div>
      )}
      <div className="w-full overflow-x-hidden text-wrap grow-1 shrink-1">
        {value ?? ""}
      </div>
    </div>
    {isComparing && ["currency", "number"].includes(`${type}`) ? (
      <span className="text-gray-light">&nbsp;(Compared)</span>
    ) : null}
  </div>
)
