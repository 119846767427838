import { HOMEPAGE_SECTIONS } from "./constants"

const content = {
  id: "home-page",
  slug: "/home",
  icon: null,
  title: "Home",
  items: {
    [HOMEPAGE_SECTIONS.METRIC_STATISTICS]: {
      id: HOMEPAGE_SECTIONS.METRIC_STATISTICS,
      isEnabled: true,
    },
    [HOMEPAGE_SECTIONS.SETUP_GUIDE]: {
      id: HOMEPAGE_SECTIONS.SETUP_GUIDE,
      isEnabled: true,
    },
    [HOMEPAGE_SECTIONS.RECOMMENDED_ACTIONS]: {
      id: HOMEPAGE_SECTIONS.RECOMMENDED_ACTIONS,
      isEnabled: true,
    },
    [HOMEPAGE_SECTIONS.GOALS]: {
      id: HOMEPAGE_SECTIONS.GOALS,
      isEnabled: true,
    },
    [HOMEPAGE_SECTIONS.LEARNING_ZONE]: {
      id: HOMEPAGE_SECTIONS.LEARNING_ZONE,
      isEnabled: true,
    },
  },
}

export default function useConnect() {
  return {
    content,
  }
}
