import { Card } from "~/ui-rtk/components/ui/common"
import ValueWithDelta from "~/ui-rtk/components/ui/common/ValueWithDelta/ValueWithDelta"
import { toCurrency } from "~/ui-rtk/utils/number-utils"

export type TMetricStatisticCartProps = {
  title: string
  metric: string
  actualValue: number
  comparedValue: number
}

export default function MetricStatisticCard({
  title,
  metric,
  actualValue,
  comparedValue,
}: TMetricStatisticCartProps) {
  return (
    <Card
      rounded
      className="metric-statistic-card p-4 border-0 bg-basic-dark-blue w-80 h-28 flex flex-col justify-between gap-2"
    >
      <div className="text-basic-grey-inactive text-sm self-start">
        {title}
        <br />
        {metric}
      </div>
      <div className="self-end flex items-end justify-end gap-2">
        <div className="text-2xl">{toCurrency(actualValue)}</div>
        <ValueWithDelta
          actualValue={actualValue}
          comparedValue={comparedValue}
          actualClassName="text-sm"
          hideActualValue
          size={16}
        />
        <div className="flex text-sm items-center">
          (<div className="text-sm">{toCurrency(comparedValue)}</div>)
        </div>
      </div>
    </Card>
  )
}
