import { PropsWithChildren, useRef } from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TDropdownMenuOption = {
  label: string
  value: any
}

type TDropdownMenuProps = {
  options: TDropdownMenuOption[]
  onItemClick: (val: any) => void
  className?: string
}

export default function DropdownMenu({
  options,
  children,
  onItemClick,
  className,
}: PropsWithChildren<TDropdownMenuProps>) {
  const ref = useRef<HTMLUListElement>(null)
  return (
    <div className="dropdown dropdown-start">
      <div
        tabIndex={0}
        role="button"
        className={cn("btn btn-ghost rounded-md", className)}
      >
        {children}
      </div>
      <ul
        tabIndex={0}
        ref={ref}
        className="menu dropdown-content bg-basic-dark-blue border border-basic-blue rounded-md z-50 mt-1 min-w-40 p-1 shadow-sm"
      >
        {options.map((option, idx) => (
          <li
            onClick={ev => {
              onItemClick(option.value)
              ref.current?.blur()
              ev.stopPropagation()
            }}
            key={`${option.value.toString()}_${idx}`}
          >
            <a>{option.label}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}
