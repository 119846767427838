import { Filter, Query } from "@cubejs-client/core"
import { useMemo, useState } from "react"
import { MetricsMapping, TQuery } from "~/ui-rtk/constants/metrics-mapping"
import { useCubeQuery } from "~/ui-rtk/hooks/cube"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import useDate from "~/ui-rtk/hooks/date"
import { DATE_FORMAT } from "~/ui-rtk/hooks/period"
import {
  SessionStorageKeys,
  useSessionStorage,
} from "~/ui-rtk/hooks/session-storage"
import {
  getPivotData,
  polyfillCubeQueryWithTimeDimension,
} from "~/ui-rtk/utils/cube"

const cache: Record<string, any> = {}
const query: Record<string, Query> = {}

const dayjs = useDate()

function getItemsToIncreaseBudget(
  dateStart: Date,
  diff: number,
  q: TQuery["q"],
  postProcess: TQuery["postProcess"],
  prefix: string,
) {
  const { id } = useCurrentCompany() ?? { id: null }

  const dateEnd = dayjs().subtract(2, "day").toDate()

  const formattedDateStart = `${prefix}-${id}-${dayjs(dateStart).format(DATE_FORMAT)}-${diff}`

  if (!query[formattedDateStart]) {
    query[formattedDateStart] = polyfillCubeQueryWithTimeDimension(
      {
        ...q,
        filters: [
          {
            and: [
              {
                dimension: `${prefix}.budget`,
                operator: "set",
              },
              {
                or: [
                  {
                    and: [
                      {
                        dimension: `${prefix}.brand_value_over_spend`,
                        operator: "gt",
                        values: ["0.75"],
                      },
                      {
                        dimension: `${prefix}.short_term_revenue_roas`,
                        operator: "gt",
                        values: ["1"],
                      },
                    ],
                  },
                  {
                    dimension: `${prefix}.brand_value_over_spend`,
                    operator: "gt",
                    values: ["1.5"],
                  },
                ],
              },
            ],
          },
        ] as Filter[],
        order: {
          [`${prefix}.long_term_revenue`]: "desc",
        },
      } as Query,
      {
        dateRange: [dateStart, dateEnd],
      },
    )
  }

  const cubeQuery = useCubeQuery(query[formattedDateStart])
  const { resultSet, isLoading } = cubeQuery

  if (resultSet) {
    const pivotData = postProcess?.(
      getPivotData(resultSet, query[formattedDateStart]),
      {},
    )

    if (pivotData?.[0]) {
      cache[formattedDateStart] = pivotData[0]
    }
  }

  return {
    data: cache[formattedDateStart],
    isLoading,
    isStarted:
      JSON.stringify(cubeQuery.previousQuery) ===
      JSON.stringify(query[formattedDateStart]),
  }
}

export function useCampaignsToIncreaseBudget(dateStart: Date, diff: number) {
  const { q, postProcess } =
    MetricsMapping.brand_media_optimization_campaign_table
  const prefix = "company_media_metrics_campaigns"

  const [hiddenCardIds, setHiddenCardIds] = useSessionStorage<string[]>(
    SessionStorageKeys.CAMPAIGN_TO_OPTIMIZE,
    [],
  )
  const [, rerender] = useState(0)

  const hide = (metric: Record<string, string>) => {
    setHiddenCardIds(
      Array.from(new Set([...(hiddenCardIds ?? []), metric.ad_id])),
    )
    rerender(i => i + 1)
  }

  const {
    data: rawData,
    isLoading,
    isStarted,
  } = getItemsToIncreaseBudget(dateStart, diff, q, postProcess, prefix)

  const data = useMemo(
    () =>
      ((hiddenCardIds ?? []).length > 0
        ? (rawData ?? []).filter(
            (itm: Record<string, string>) =>
              !(hiddenCardIds ?? []).includes(itm.ad_id),
          )
        : (rawData ?? [])
      ).filter((metric: Record<string, string>) => {
        const spend = +metric.spend
        const budget = +metric.budget

        return spend * 1.05 >= budget * diff
      }),
    [hiddenCardIds, cache],
  )

  return {
    data,
    isLoading,
    isStarted,
    hide,
  }
}

export function useAdsetsToIncreaseBudget(dateStart: Date, diff: number) {
  const { q, postProcess } =
    MetricsMapping.brand_media_optimization_adsets_table
  const prefix = "company_media_metrics_adsets"

  const [hiddenCardIds, setHiddenCardIds] = useSessionStorage<string[]>(
    SessionStorageKeys.ADSET_TO_OPTIMIZE,
    [],
  )
  const [, rerender] = useState(0)

  const {
    data: rawData,
    isLoading,
    isStarted,
  } = getItemsToIncreaseBudget(dateStart, diff, q, postProcess, prefix)

  const data = useMemo(
    () =>
      ((hiddenCardIds ?? []).length > 0
        ? (rawData ?? []).filter(
            (itm: Record<string, string>) =>
              !(hiddenCardIds ?? []).includes(itm.ad_id),
          )
        : (rawData ?? [])
      ).filter((metric: Record<string, string>) => {
        const spend = +metric.spend
        const budget = +metric.budget

        return spend * 1.05 >= budget * diff
      }),
    [hiddenCardIds, cache],
  )

  const hide = (metric: Record<string, string>) => {
    setHiddenCardIds(
      Array.from(new Set([...(hiddenCardIds ?? []), metric.ad_id])),
    )
    rerender(i => i + 1)
  }

  return {
    data,
    isLoading,
    isStarted,
    hide,
  }
}
