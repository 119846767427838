import { CompanyDto } from "../api/types"
import { TComposedTableComposedValue } from "../components/ui/charts/ComposedTable/types"
import { CaptionPreview } from "~/ui-rtk/components/ui/common/CaptionPreview/CaptionPreview"
import { Image } from "../components/ui/common/Image"
import { formatNumber } from "../utils/format"
import { safeDivide } from "../utils/number-utils"
import { SOURCE_IDS } from "./sources"
import useAdImage from "../hooks/ad-image"

export enum AggregationType {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export const CHARTS_LOADING_DATA = [
  { date: 1672531200001, value: 2400 },
  { date: 1672531200002, value: 1398 },
  { date: 1672531200003, value: 9800 },
  { date: 1672531200004, value: 3908 },
  { date: 1672531200005, value: 4800 },
  { date: 1672531200006, value: 3800 },
  { date: 1672531200007, value: 4300 },
  { date: 1672531200008, value: 2400 },
  { date: 1672531200010, value: 5356 },
  { date: 1672531200009, value: 2398 },
]

export const AGG_TYPES = [
  { type: AggregationType.DAILY, short: "D", long: "Daily" },
  { type: AggregationType.WEEKLY, short: "W", long: "Weekly" },
  { type: AggregationType.MONTHLY, short: "M", long: "Monthly" },
]

export const TOTAL_CALCULATED_DATA_KEY = "__total__"
export const UNKNOWN_DATA_KEY = "__unknown__"
export const TOTAL_COLOR = "yellow"

export enum VISUALIZATION_TYPE {
  TableVisualization = "TableVisualization",
  AreaChart = "AreaChart",
  ComposedChart = "ComposedChart",
  DropDownChart = "DropDownChart",
  CohortVisualization = "CohortVisualization",
  MultiChart = "MultiChart",
  ComposedTable = "ComposedTable",
  SortedItems = "SortedItems",
  SimpleTable = "SimpleTable",
}

export type TLabelConfig = {
  label?: string
  numberType?: "default" | "currency"
  unit?: string
  pluralizeUnit?: boolean
  dataKey: string
  isBrand?: boolean
}

export const VISUALIZATIONS_MAP: Record<string, any> = {
  // In-use
  google_keywords_share_of_search_total_composed_chart: {
    id: "google_keywords_share_of_search_total_composed_chart",
    description:
      "This chart shows your brand's share of search across ALL of your category terms as compared to your selected competitors, which are editable in your settings page.",
    type: "ComposedChart",
    props: {
      dataAggTypeOnly: "MONTHLY",
      interactive: false,
      highlightMetric: "__CURRENT_COMPANY_NAME__",
      highlightMetricPrefix: "company_mind_share_metrics_monthly.brand",
      highlightMetricSuffix: "share_of_search",
      stacked: true,
      comparisonEnabled: true,
      graphsFromColumn: {
        type: "area",
        unit: "%",
        label: "{stackKey}",
        suffixKey: "share_of_search",
        column: "company_mind_share_metrics_monthly.brand",
        labelAggType: "percent-average",
        pluralizeUnit: false,
      },
      manualTotal: true,
      totalLabelProps: {
        label: "Total searches",
      },
      tooltipProps: {
        renderCell: (
          value: number,
          dataKey: string,
          payload: Record<string, number>,
          _labelConfig: TLabelConfig,
          _ignoreDecimals = false,
          prefix = "",
        ) => {
          const renderedValuePercentage = formatNumber(Number(value), {
            currency: false,
            decimals: 2,
          })
          const searchesKey = dataKey.replace(".share_of_search", ".searches")
          const searchesValue = formatNumber(
            Number(payload[`${prefix}${searchesKey}`]),
            {
              currency: false,
              compact: true,
            },
          )
          return (
            <>
              <div>{renderedValuePercentage}%&nbsp;</div>
              <div className="text-white/50">{searchesValue} searches</div>
            </>
          )
        },
      },
      blurTitle: true,
      emptyStateText: "Google returned no search volume for these queries.",
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_KEYWORDS],
  },
  google_keywords_share_of_search_brands_share_composed_chart: {
    id: "google_keywords_share_of_search_brands_share_composed_chart",
    description:
      "This chart shows the number of searches on your exact brand term (e.g. your company name) vs. those of your competitors, with no category modifiers appended.",
    type: "ComposedChart",
    props: {
      dataAggTypeOnly: "MONTHLY",
      interactive: false,
      highlightMetric: "__CURRENT_COMPANY_NAME__",
      highlightMetricPrefix: "company_mind_share_metrics_monthly.brand",
      highlightMetricSuffix: "share_of_search",
      stacked: true,
      comparisonEnabled: true,
      graphsFromColumn: {
        type: "area",
        unit: "%",
        label: "{stackKey}",
        suffixKey: "share_of_search",
        column: "company_mind_share_metrics_monthly.brand",
        labelAggType: "percent-average",
        pluralizeUnit: false,
      },
      manualTotal: true,
      totalLabelProps: {
        label: "Total searches",
      },
      tooltipProps: {
        renderCell: (
          value: number,
          dataKey: string,
          payload: Record<string, number>,
          _labelConfig: TLabelConfig,
          _ignoreDecimals = false,
          prefix = "",
        ) => {
          const renderedValuePercentage = formatNumber(Number(value), {
            currency: false,
            decimals: 2,
          })
          const searchesKey = dataKey.replace(".share_of_search", ".searches")
          const searchesValue = formatNumber(
            Number(payload[`${prefix}${searchesKey}`]),
            {
              currency: false,
              compact: true,
            },
          )
          return (
            <>
              <div>{renderedValuePercentage}%&nbsp;</div>
              <div className="text-white/50">{searchesValue} searches</div>
            </>
          )
        },
      },
      emptyStateText: "Google returned no search volume for these queries.",
      blurTitle: true,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_KEYWORDS],
  },
  google_keywords_share_of_search_by_category_multi_chart: {
    id: "google_keywords_share_of_search_by_category_multi_chart",
    name: "Share Of Search By Category Multi Chart",
    description:
      "This chart shows your brand's share of search for the given category search term for any given time period. Note this data is only available on a monthly basis.",
    type: "MultiChart",
    props: {
      unit: "%",
      highlightMetric: "__CURRENT_COMPANY_NAME__",
      highlightMetricSuffix: "share_of_search",
      groupKey: "category",
      stacked: true,
      dataAggTypeOnly: "MONTHLY",
      chartColSpan: 4,
      chartRowSpan: 6,
      labelAggType: "percent-total",
      groupStackKey: "brand",
      pluralizeUnit: false,
      groupStackLabelFormat: "{stackKey}",
      manualTotal: true,
      comparisonEnabled: true,
      totalLabelProps: {
        label: "Total searches",
      },
      tooltipProps: {
        renderCell: (
          value: number,
          dataKey: string,
          payload: Record<string, number>,
          _labelConfig: TLabelConfig,
          _ignoreDecimals = false,
          prefix = "",
        ) => {
          const renderedValuePercentage = formatNumber(Number(value), {
            currency: false,
            decimals: 2,
          })
          const searchesKey = dataKey.replace(".share_of_search", ".searches")
          const searchesValue = formatNumber(
            Number(payload[`${prefix}${searchesKey}`]),
            {
              currency: false,
              compact: true,
            },
          )
          return (
            <>
              <div>{renderedValuePercentage}%&nbsp;</div>
              <div className="text-white/50">{searchesValue} searches</div>
            </>
          )
        },
      },
      emptyStateText: "Google returned no search volume for these queries.",
      blurTitle: true,
    },
    metadata: { autoSize: true, noResize: true, defaultColSpan: 12 },
    requiredSources: [SOURCE_IDS.GOOGLE_KEYWORDS],
  },
  instagram_share_of_comments_area_chart: {
    id: "instagram_share_of_comments_area_chart",
    name: "Instagram Share of Comments Chart",
    description:
      "This metric represent the total number of comments across all of your Instagram posts on any given day.",
    type: "AreaChart",
    props: {
      unit: "%",
      title: "IG New Comments",
      dataAggTypeOnly: "DAILY",
      labelAggType: "avg",
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.INSTAGRAM],
  },
  instagram_share_of_likes_area_chart: {
    id: "instagram_share_of_likes_area_chart",
    name: "Instagram Share of Likes Chart",
    description:
      "This metric represent the total number of likes across all of your Instagram posts on any given day.",
    type: "AreaChart",
    props: {
      unit: "%",
      title: "IG New Likes",
      dataAggTypeOnly: "DAILY",
      labelAggType: "avg",
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.INSTAGRAM],
  },
  instagram_share_of_followers_area_chart: {
    id: "instagram_share_of_followers_area_chart",
    name: "Instagram Share of Followers Chart",
    description:
      "This metric represent the total number of likes across all of your Instagram posts on any given day.",
    type: "AreaChart",
    props: {
      unit: "%",
      title: "IG New Followers",
      dataAggTypeOnly: "DAILY",
      labelAggType: "avg",
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.INSTAGRAM],
  },
  total_followers_stacked_chart: {
    id: "total_followers_stacked_chart",
    name: "Total New Followers Chart",
    description:
      "The total number of new followers you added across all of your social channels on any given day. Note: the Tiktok follows metric shows net change (new follows - unfollows) as opposed to only new followers.",
    type: "ComposedChart",
    props: {
      title: "Total New Followers",
      totalLabelProps: {
        unit: "follower",
        label: "Total",
        pluralizeUnit: true,
      },
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "follower",
          label: "Total Profile Follows",
          dataKey: "metrics.instagram_insights_follows",
          requiredSources: [SOURCE_IDS.INSTAGRAM],
          pluralizeUnit: true,
        },
        {
          type: "area",
          unit: "follower",
          label: "IG Follows from Posts",
          dataKey: "metrics.instagram_business_follows",
          requiredSources: [SOURCE_IDS.INSTAGRAM],
          pluralizeUnit: true,
        },
        {
          type: "area",
          unit: "follower",
          label: "TikTok",
          dataKey: "metrics.tiktok_profile_follows",
          requiredSources: [SOURCE_IDS.TIKTOK],
          pluralizeUnit: true,
        },
        {
          type: "area",
          unit: "follower",
          label: "TikTok Ads",
          dataKey: "metrics.tiktok_ads_follows",
          requiredSources: [SOURCE_IDS.TIKTOK_ADS],
          pluralizeUnit: true,
        },
        {
          type: "area",
          unit: "follower",
          label: "Facebook",
          dataKey: "metrics.facebook_pages_follows",
          requiredSources: [SOURCE_IDS.FACEBOOK],
          pluralizeUnit: true,
        },
        {
          type: "area",
          unit: "follower",
          label: "YouTube",
          dataKey: "metrics.youtube_channel_follows",
          requiredSources: [SOURCE_IDS.YOUTUBE],
          pluralizeUnit: true,
        },
        {
          type: "area",
          unit: "follower",
          label: "Facebook Ads",
          dataKey: "metrics.facebook_ads_follows",
          requiredSources: [SOURCE_IDS.FACEBOOK_ADS],
          pluralizeUnit: true,
        },
        {
          type: "area",
          unit: "follower",
          label: "Total",
          isTotal: true,
          color: TOTAL_COLOR,
          dataKey: TOTAL_CALCULATED_DATA_KEY,
          requiredSources: [
            SOURCE_IDS.INSTAGRAM,
            SOURCE_IDS.TIKTOK,
            SOURCE_IDS.TIKTOK_ADS,
            SOURCE_IDS.FACEBOOK,
            SOURCE_IDS.FACEBOOK_ADS,
            SOURCE_IDS.YOUTUBE,
          ],
          pluralizeUnit: true,
        },
      ],
      compareUnit: "follow",
    },
    metadata: null,
    requiredSources: [
      SOURCE_IDS.INSTAGRAM,
      SOURCE_IDS.INSTAGRAM_ADS,
      SOURCE_IDS.TIKTOK,
      SOURCE_IDS.TIKTOK_ADS,
      SOURCE_IDS.FACEBOOK,
      SOURCE_IDS.FACEBOOK_ADS,
      SOURCE_IDS.YOUTUBE,
    ],
  },
  total_new_subscribers_stacked_chart: {
    id: "total_new_subscribers_stacked_chart",
    name: "Total Subscribers Chart",
    description:
      "This metric represents the number of new subscribers you earned across email and SMS on any given date",
    type: "ComposedChart",
    props: {
      title: "Total New Subscribers",
      totalLabelProps: {
        unit: "subscriber",
        label: "Total",
        pluralizeUnit: true,
      },
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          label: "Klaviyo email subscribers",
          dataKey: "metrics.klaviyo_email_subscribers",
          requiredSources: [SOURCE_IDS.KLAVIYO],
        },
        {
          type: "area",
          label: "Attentive SMS subscribers",
          dataKey: "metrics.attentive_sms_subscribers",
          requiredSources: [SOURCE_IDS.ATTENTIVE],
        },
        {
          type: "area",
          label: "Total New subscribers",
          isTotal: true,
          color: TOTAL_COLOR,
          dataKey: TOTAL_CALCULATED_DATA_KEY,
          requiredSources: [SOURCE_IDS.ATTENTIVE, SOURCE_IDS.KLAVIYO],
        },
      ],
      compareLabelAggType: "sum",
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.KLAVIYO, SOURCE_IDS.ATTENTIVE],
  },
  total_shares_stacked_chart: {
    id: "total_shares_stacked_chart",
    name: "Total Shares Chart",
    description:
      "This metric represents the total number of shares on your content across all of your social channels for any given date.",
    type: "ComposedChart",
    props: {
      title: "Total Shares",
      totalLabelProps: {
        unit: "share",
        label: "Total",
        pluralizeUnit: true,
      },
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          label: "Instagram",
          dataKey: "metrics.instagram_business_shares",
          requiredSources: [SOURCE_IDS.INSTAGRAM],
        },
        {
          type: "area",
          label: "TikTok",
          dataKey: "metrics.tiktok_videos_shares",
          requiredSources: [SOURCE_IDS.TIKTOK],
        },
        {
          type: "area",
          label: "TikTok Shares from Ads",
          dataKey: "metrics.tiktok_ads_shares",
          requiredSources: [SOURCE_IDS.TIKTOK_ADS],
        },
        {
          type: "area",
          label: "Facebook Ads",
          dataKey: "metrics.facebook_ads_shares",
          requiredSources: [SOURCE_IDS.FACEBOOK_ADS],
        },
        {
          type: "area",
          label: "Instagram Ads",
          dataKey: "metrics.instagram_ads_shares",
          requiredSources: [SOURCE_IDS.INSTAGRAM_ADS],
        },
        {
          type: "area",
          label: "Facebook",
          dataKey: "metrics.facebook_pages_shares",
          requiredSources: [SOURCE_IDS.FACEBOOK],
        },
        {
          type: "area",
          label: "YouTube",
          dataKey: "metrics.youtube_channel_shares",
          requiredSources: [SOURCE_IDS.YOUTUBE],
        },
        {
          type: "area",
          label: "Total",
          isTotal: true,
          color: TOTAL_COLOR,
          dataKey: TOTAL_CALCULATED_DATA_KEY,
          requiredSources: [
            SOURCE_IDS.INSTAGRAM,
            SOURCE_IDS.INSTAGRAM_ADS,
            SOURCE_IDS.FACEBOOK,
            SOURCE_IDS.FACEBOOK_ADS,
            SOURCE_IDS.TIKTOK,
            SOURCE_IDS.TIKTOK_ADS,
            SOURCE_IDS.YOUTUBE,
          ],
        },
      ],
      compareUnit: "share",
    },
    metadata: null,
    requiredSources: [
      SOURCE_IDS.INSTAGRAM,
      SOURCE_IDS.INSTAGRAM_ADS,
      SOURCE_IDS.FACEBOOK,
      SOURCE_IDS.FACEBOOK_ADS,
      SOURCE_IDS.TIKTOK,
      SOURCE_IDS.TIKTOK_ADS,
      SOURCE_IDS.YOUTUBE,
    ],
  },
  total_comments_stacked_chart: {
    id: "total_comments_stacked_chart",
    name: "Total Comments Chart",
    description:
      "The total number of comments on your brand's social content across all channels on any given day.",
    type: "ComposedChart",
    props: {
      title: "Total Comments",
      totalLabelProps: {
        unit: "comment",
        label: "Total",
        pluralizeUnit: true,
      },
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          label: "Instagram",
          dataKey: "metrics.instagram_business_comments",
          requiredSources: [SOURCE_IDS.INSTAGRAM],
        },
        {
          type: "area",
          label: "Instagram Ads",
          dataKey: "metrics.instagram_ads_comments",
          requiredSources: [SOURCE_IDS.INSTAGRAM_ADS],
        },
        {
          type: "area",
          label: "TikTok",
          dataKey: "metrics.tiktok_videos_comments",
          requiredSources: [SOURCE_IDS.TIKTOK],
        },
        {
          type: "area",
          label: "TikTok Ads",
          dataKey: "metrics.tiktok_ads_comments",
          requiredSources: [SOURCE_IDS.TIKTOK_ADS],
        },
        {
          type: "area",
          label: "Facebook",
          dataKey: "metrics.facebook_pages_comments",
          requiredSources: [SOURCE_IDS.FACEBOOK],
        },
        {
          type: "area",
          label: "Facebook Ads",
          dataKey: "metrics.facebook_ads_comments",
          requiredSources: [SOURCE_IDS.FACEBOOK_ADS],
        },
        {
          type: "area",
          label: "YouTube",
          dataKey: "metrics.youtube_channel_comments",
          requiredSources: [SOURCE_IDS.YOUTUBE],
        },
        {
          type: "area",
          label: "Total",
          isTotal: true,
          color: TOTAL_COLOR,
          dataKey: TOTAL_CALCULATED_DATA_KEY,
          requiredSources: [
            SOURCE_IDS.FACEBOOK_ADS,
            SOURCE_IDS.INSTAGRAM,
            SOURCE_IDS.INSTAGRAM_ADS,
            SOURCE_IDS.FACEBOOK,
            SOURCE_IDS.TIKTOK,
            SOURCE_IDS.TIKTOK_ADS,
            SOURCE_IDS.YOUTUBE,
          ],
        },
      ],
      compareUnit: "comment",
    },
    metadata: null,
    requiredSources: [
      SOURCE_IDS.INSTAGRAM,
      SOURCE_IDS.FACEBOOK,
      SOURCE_IDS.FACEBOOK_ADS,
      SOURCE_IDS.TIKTOK,
      SOURCE_IDS.TIKTOK_ADS,
      SOURCE_IDS.YOUTUBE,
    ],
  },
  total_likes_stacked_chart: {
    id: "total_likes_stacked_chart",
    name: "Total Likes Chart",
    description:
      "The total number of likes on your brand's social content across all channels on any given day.",
    type: "ComposedChart",
    props: {
      title: "Total Likes",
      totalLabelProps: {
        unit: "like",
        label: "Total",
        pluralizeUnit: true,
      },
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          label: "Instagram",
          dataKey: "metrics.instagram_business_likes",
          requiredSources: [SOURCE_IDS.INSTAGRAM],
        },
        {
          type: "area",
          label: "Instagram Ads",
          dataKey: "metrics.instagram_ads_likes",
          requiredSources: [SOURCE_IDS.INSTAGRAM_ADS],
        },
        {
          type: "area",
          label: "TikTok",
          dataKey: "metrics.tiktok_videos_likes",
          requiredSources: [SOURCE_IDS.TIKTOK],
        },
        {
          type: "area",
          label: "TikTok Ads",
          dataKey: "metrics.tiktok_ads_likes",
          requiredSources: [SOURCE_IDS.TIKTOK_ADS],
        },
        {
          type: "area",
          label: "Facebook",
          dataKey: "metrics.facebook_pages_likes",
          requiredSources: [SOURCE_IDS.FACEBOOK],
        },
        {
          type: "area",
          label: "Facebook Ads",
          dataKey: "metrics.facebook_ads_likes",
          requiredSources: [SOURCE_IDS.FACEBOOK_ADS],
        },
        {
          type: "area",
          label: "YouTube",
          dataKey: "metrics.youtube_channel_likes",
          requiredSources: [SOURCE_IDS.YOUTUBE],
        },
        {
          type: "area",
          label: "Total",
          isTotal: true,
          color: TOTAL_COLOR,
          dataKey: TOTAL_CALCULATED_DATA_KEY,
          requiredSources: [
            SOURCE_IDS.INSTAGRAM,
            SOURCE_IDS.INSTAGRAM_ADS,
            SOURCE_IDS.FACEBOOK,
            SOURCE_IDS.FACEBOOK_ADS,
            SOURCE_IDS.TIKTOK,
            SOURCE_IDS.TIKTOK_ADS,
            SOURCE_IDS.YOUTUBE,
          ],
        },
      ],
      compareUnit: "like",
    },
    metadata: null,
    requiredSources: [
      SOURCE_IDS.INSTAGRAM,
      SOURCE_IDS.FACEBOOK,
      SOURCE_IDS.FACEBOOK_ADS,
      SOURCE_IDS.TIKTOK,
      SOURCE_IDS.TIKTOK_ADS,
      SOURCE_IDS.YOUTUBE,
    ],
  },
  total_saves_stacked_chart: {
    id: "total_saves_stacked_chart",
    name: "Total Saves Chart",
    description: null,
    type: "ComposedChart",
    props: {
      title: "Total Saves",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          label: "Instagram Saves",
          dataKey: "metrics.instagram_business_saves",
          requiredSources: [SOURCE_IDS.INSTAGRAM],
        },
        {
          type: "area",
          label: "Instagram Saves from Ads",
          dataKey: "metrics.instagram_ads_saves",
          requiredSources: [SOURCE_IDS.INSTAGRAM_ADS],
        },
        {
          type: "area",
          label: "TikTok Saves from Ads",
          dataKey: "metrics.tiktok_ads_saves",
          requiredSources: [SOURCE_IDS.TIKTOK_ADS],
        },
        {
          type: "area",
          label: "Total Saves",
          isTotal: true,
          color: TOTAL_COLOR,
          dataKey: TOTAL_CALCULATED_DATA_KEY,
          requiredSources: [
            SOURCE_IDS.INSTAGRAM,
            SOURCE_IDS.INSTAGRAM_ADS,
            SOURCE_IDS.TIKTOK_ADS,
          ],
        },
      ],
      compareUnit: "save",
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.INSTAGRAM, SOURCE_IDS.TIKTOK_ADS],
  },
  total_social_profile_views_stacked_chart: {
    id: "total_social_profile_views_stacked_chart",
    name: "Total Social Profile Views Chart",
    description:
      "The total number of visits to your brand's social profile pages across all social platforms on any given day.",
    type: "ComposedChart",
    props: {
      title: "Total Social Profile Views",
      totalLabelProps: {
        unit: "view",
        label: "Total",
        pluralizeUnit: true,
      },
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          label: "Instagram",
          dataKey: "metrics.instagram_business_profile_views",
          requiredSources: [SOURCE_IDS.INSTAGRAM],
        },
        {
          type: "area",
          label: "TikTok",
          dataKey: "metrics.tiktok_profile_views",
          requiredSources: [SOURCE_IDS.TIKTOK],
        },
        {
          type: "area",
          label: "Facebook",
          dataKey: "metrics.facebook_pages_views",
          requiredSources: [SOURCE_IDS.FACEBOOK],
        },
        {
          type: "area",
          label: "Total",
          isTotal: true,
          color: TOTAL_COLOR,
          dataKey: TOTAL_CALCULATED_DATA_KEY,
          requiredSources: [
            SOURCE_IDS.INSTAGRAM,
            SOURCE_IDS.FACEBOOK,
            SOURCE_IDS.TIKTOK,
          ],
        },
      ],
      compareUnit: "view",
    },
    metadata: null,
    requiredSources: [
      SOURCE_IDS.INSTAGRAM,
      SOURCE_IDS.FACEBOOK,
      SOURCE_IDS.TIKTOK,
    ],
  },
  facebook_mentions_area_chart: {
    id: "facebook_mentions_area_chart",
    name: "Facebook Mentions Chart",
    description:
      "The total number of times your brand's profile was mentioned in another user's post or story on any given day.",
    type: "ComposedChart",
    props: {
      unit: "mention",
      title: "Facebook Mentions",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          label: "Facebook Mentions",
          dataKey: "metrics.facebook_pages_mentions",
          yAxisId: "mentions",
          requiredSources: [SOURCE_IDS.FACEBOOK],
        },
      ],
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.FACEBOOK],
  },
  branded_search_totals_stacked_chart: {
    id: "branded_search_totals_stacked_chart",
    name: "Branded Search Total Chart",
    description:
      "This metric represents the number of organic search impressions and clicks on your brand that were generated on searches containing your branded keywords as compared to the number of paid search impressions on thos same search terms.",
    type: "ComposedChart",
    props: {
      title: "Branded Search Totals",
      totalLabelProps: {
        unit: "impression",
        label: "Total",
        pluralizeUnit: true,
      },
      tooltipProps: {
        numeralFormat: "0.00a",
      },
      comparisonEnabled: true,
      hideTotals: true,
      customTotals: [
        {
          label: "Total Impressions",
          dataKey: "total-impressions",
          dataKeysToSum: [
            "metrics.google_search_organic_branded_search_impressions",
            "metrics.google_ads_paid_branded_search_impressions",
          ],
        },
        {
          label: "Total Clicks",
          dataKey: "total-clicks",
          dataKeysToSum: [
            "metrics.google_search_organic_branded_search_clicks",
            "metrics.google_ads_paid_branded_search_clicks",
          ],
        },
      ],
      graphs: [
        {
          type: "area",
          label: "Organic searches",
          dataKey: "metrics.google_search_organic_branded_search_impressions",
          // yAxisId: "impressions",
          requiredSources: [SOURCE_IDS.GOOGLE_SEARCH],
        },
        {
          type: "area",
          label: "Paid impressions",
          dataKey: "metrics.google_ads_paid_branded_search_impressions",
          // yAxisId: "impressions",
          requiredSources: [SOURCE_IDS.GOOGLE_ADS],
        },
        {
          type: "area",
          label: "Organic clicks",
          dataKey: "metrics.google_search_organic_branded_search_clicks",
          // yAxisId: "clicks",
          requiredSources: [SOURCE_IDS.GOOGLE_SEARCH],
        },
        {
          type: "area",
          label: "Paid clicks",
          dataKey: "metrics.google_ads_paid_branded_search_clicks",
          // yAxisId: "clicks",
          requiredSources: [SOURCE_IDS.GOOGLE_ADS],
        },
        // {
        //   type: "dashed",
        //   unit: "dollar",
        //   label: "Spend",
        //   dataKey: "metrics.google_ads_paid_branded_search_total_spend",
        //   requiredSources: [SOURCE_IDS.GOOGLE_ADS],
        //   numberType: "currency",
        //   pluralizeUnit: true,
        // },
      ],
      interactive: false,
      compareLabelAggType: "sum",
      graphsManualSort: true,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ADS, SOURCE_IDS.GOOGLE_SEARCH],
  },
  ga_revenue_break_down_stacked_chart: {
    id: "ga_revenue_break_down_stacked_chart",
    name: "Revenue Breakdown",
    description:
      "This chart shows a comparison between your total Baseline revenue - resilient revenue from organic channels - vs. Paid revenue from advertising sources.",
    type: "ComposedChart",
    renderInfo: () => (
      <div className="inline-block text-white/75 leading-relaxed mt-6 mb-4 border border-basic-blue bg-basic-dark-blue rounded-lg px-2 py-1.5 text-sm">
        <p>
          <span className="text-orange font-bold">Paid</span>: All revenue
          sources not specified in 'Triggered' or 'Baseline', including but not
          limited to Facebook Ads, Google Ads, Tiktok Ads, etc.
        </p>
        <p>
          <span className="text-yellow font-bold">Triggered</span>: Revenue
          attributed to email and SMS marketing campaigns.
        </p>
        <p>
          <span className="text-japanese-laurel font-bold">Baseline</span>:
          Revenue generated from organic search, direct traffic, and organic
          social referrals.
        </p>
      </div>
    ),
    props: {
      title: "Revenue Breakdown",
      comparisonEnabled: true,
      tooltipProps: {
        renderCell: (
          value: number,
          _dataKey: string,
          payload: Record<string, number>,
          _labelConfig: TLabelConfig,
          ignoreDecimals = false,
          prefix = "",
        ) => {
          const total = [
            `${prefix}metrics.ga_paid_revenue`,
            `${prefix}metrics.ga_triggered_revenue`,
            `${prefix}metrics.ga_baseline_revenue`,
          ].reduce((acc: number, key: string) => acc + payload[key], 0)
          const percentValue = 100 * (value / total)
          const renderedValue = formatNumber(Number(value), {
            currency: true,
            decimals: ignoreDecimals ? 0 : 2,
          })
          return (
            <>
              <div>{renderedValue}&nbsp;</div>
              <div className="text-white/50 text-sm">
                {percentValue.toFixed(2)}% of Total
              </div>
            </>
          )
        },
      },
      totalLabelProps: {
        label: "Total Revenue",
        numberType: "currency",
      },
      graphs: [
        {
          type: "area",
          label: "Paid Revenue",
          dataKey: "metrics.ga_paid_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#EE8434",
        },
        {
          type: "area",
          label: "Triggered Revenue",
          dataKey: "metrics.ga_triggered_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#F4D148",
        },
        {
          type: "area",
          label: "Baseline Revenue",
          dataKey: "metrics.ga_baseline_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#318936",
        },
        {
          type: "line",
          label: "Spend",
          dataKey: "metrics.total_ad_spend",
          numberType: "currency",
          color: "#E2323E",
        },
      ],
      graphsManualSort: true,
      interactive: false,
      compareNumberType: "currency",
      compareLabelAggType: "sum",
      hideTotal: false,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_mp_revenue_break_down_stacked_chart: {
    id: "ga_mp_revenue_break_down_stacked_chart",
    name: "Revenue Breakdown",
    description:
      "This chart shows a comparison between your total Baseline revenue - resilient revenue from organic channels - vs. Paid revenue from advertising sources.",
    type: "ComposedChart",
    renderInfo: () => (
      <div className="inline-block text-white/75 leading-relaxed mt-6 mb-4 border border-basic-blue bg-basic-dark-blue rounded-lg px-2 py-1.5 text-sm">
        <p>
          <span className="text-orange font-bold">Paid</span>: All revenue
          sources not specified in 'Triggered' or 'Baseline', including but not
          limited to Facebook Ads, Google Ads, Tiktok Ads, etc.
        </p>
        <p>
          <span className="text-yellow font-bold">Triggered</span>: Revenue
          attributed to email and SMS marketing campaigns.
        </p>
        <p>
          <span className="text-japanese-laurel font-bold">Baseline</span>:
          Revenue generated from organic search, direct traffic, and organic
          social referrals.
        </p>
      </div>
    ),
    props: {
      title: "Revenue Breakdown",
      comparisonEnabled: true,
      tooltipProps: {
        renderCell: (
          value: number,
          _dataKey: string,
          payload: Record<string, number>,
          _labelConfig: TLabelConfig,
          ignoreDecimals = false,
          prefix = "",
        ) => {
          const total = [
            `${prefix}metrics.ga_multi_property_paid_revenue`,
            `${prefix}metrics.ga_multi_property_triggered_revenue`,
            `${prefix}metrics.ga_multi_property_baseline_revenue`,
          ].reduce((acc: number, key: string) => acc + payload[key], 0)
          const percentValue = 100 * (value / total)
          const renderedValue = formatNumber(Number(value), {
            currency: true,
            decimals: ignoreDecimals ? 0 : 2,
          })
          return (
            <>
              <div>{renderedValue}&nbsp;</div>
              <div className="text-white/50 text-sm">
                {percentValue.toFixed(2)}% of Total
              </div>
            </>
          )
        },
      },
      totalLabelProps: {
        label: "Total Revenue",
        numberType: "currency",
      },
      graphs: [
        {
          type: "area",
          label: "Paid Revenue",
          dataKey: "metrics.ga_multi_property_paid_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#EE8434",
        },
        {
          type: "area",
          label: "Triggered Revenue",
          dataKey: "metrics.ga_multi_property_triggered_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#F4D148",
        },
        {
          type: "area",
          label: "Baseline Revenue",
          dataKey: "metrics.ga_multi_property_baseline_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#318936",
        },
        {
          type: "line",
          label: "Spend",
          dataKey: "metrics.total_ad_spend",
          numberType: "currency",
          color: "#E2323E",
        },
      ],
      graphsManualSort: true,
      interactive: false,
      compareNumberType: "currency",
      compareLabelAggType: "sum",
      hideTotal: false,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  shopify_revenue_break_down_stacked_chart: {
    id: "shopify_revenue_break_down_stacked_chart",
    name: "Revenue Breakdown",
    description:
      "This chart shows a comparison between your total Baseline revenue - resilient revenue from organic channels - vs. Paid revenue from advertising sources.",
    type: "ComposedChart",
    renderInfo: () => (
      <div className="inline-block text-white/75 leading-relaxed mt-6 mb-4 border border-basic-blue bg-basic-dark-blue rounded-lg px-2 py-1.5 text-sm">
        <p>
          <span className="text-orange font-bold">Paid</span>: All revenue
          sources not specified in 'Triggered' or 'Baseline', including but not
          limited to Facebook Ads, Google Ads, Tiktok Ads, etc.
        </p>
        <p>
          <span className="text-yellow font-bold">Triggered</span>: Revenue
          attributed to email and SMS marketing campaigns.
        </p>
        <p>
          <span className="text-japanese-laurel font-bold">Baseline</span>:
          Revenue generated from organic search, direct traffic, and organic
          social referrals.
        </p>
      </div>
    ),
    props: {
      title: "Revenue Breakdown",
      comparisonEnabled: true,
      tooltipProps: {
        renderCell: (
          value: number,
          _dataKey: string,
          payload: Record<string, number>,
          _labelConfig: TLabelConfig,
          ignoreDecimals = false,
          prefix = "",
        ) => {
          const total = [
            `${prefix}metrics.shopify_paid_revenue`,
            `${prefix}metrics.shopify_triggered_revenue`,
            `${prefix}metrics.shopify_baseline_revenue`,
          ].reduce((acc: number, key: string) => acc + payload[key], 0)
          const percentValue = 100 * (value / total)
          const renderedValue = formatNumber(Number(value), {
            currency: true,
            decimals: ignoreDecimals ? 0 : 2,
          })
          return (
            <>
              <div>{renderedValue}&nbsp;</div>
              <div className="text-white/50 text-sm">
                {percentValue.toFixed(2)}% of Total
              </div>
            </>
          )
        },
      },
      totalLabelProps: {
        label: "Total Revenue",
        numberType: "currency",
      },
      graphs: [
        {
          type: "area",
          label: "Paid Revenue",
          dataKey: "metrics.shopify_paid_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#EE8434",
        },
        {
          type: "area",
          label: "Triggered Revenue",
          dataKey: "metrics.shopify_triggered_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#F4D148",
        },
        {
          type: "area",
          label: "Baseline Revenue",
          dataKey: "metrics.shopify_baseline_revenue",
          numberType: "currency",
          labelAggType: "sum",
          pluralizeUnit: false,
          stacked: true,
          color: "#318936",
        },
        {
          type: "line",
          label: "Spend",
          dataKey: "metrics.total_ad_spend",
          numberType: "currency",
          color: "#E2323E",
        },
      ],
      graphsManualSort: true,
      interactive: false,
      compareNumberType: "currency",
      compareLabelAggType: "sum",
      hideTotal: false,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.SHOPIFY],
  },
  shopify_revenue_with_discount_table: {
    id: "shopify_revenue_with_discount_table",
    name: "Shopify Baseline, Triggered and Paid revenue Breakdown Table",
    description: "To be added please",
    type: "SimpleTable",
    props: {
      comparisonEnabled: true,
      showTotalRow: true,
      columnConfig: [
        {
          title: "Revenue Type",
          id: "revenue_type",
          totalRender: () => "All Channels:",
          columns: [
            {
              dataKey: "revenue_type",
              render: (row: any) => (
                <div className="flex justify-start gap-2 items-center">
                  <div
                    className="rounded-xl w-2 h-2"
                    style={{
                      backgroundColor: row.color,
                    }}
                  ></div>
                  {row.revenue_type}
                </div>
              ),
            },
          ],
        },
        {
          title: "Non-Discount Revenue",
          id: "non_discount_revenue",
          totalType: "currency",
          totalDataKey: "non_discount_revenue",
          columns: [
            {
              type: "currency",
              dataKey: "non_discount_revenue",
            },
            {
              type: "percent",
              dataKey: "non_discount_revenue_of_type",
              getSuffix: (row: any) => ` of ${row.revenue_type}`,
            },
            {
              type: "percent",
              dataKey: "non_discount_revenue_of_total",
              getSuffix: () => ` of Total`,
            },
          ],
        },
        {
          title: "Discount Revenue",
          id: "discount_revenue",
          totalType: "currency",
          totalDataKey: "discount_revenue",
          columns: [
            {
              type: "currency",
              dataKey: "discount_revenue",
            },
            {
              type: "percent",
              dataKey: "discount_revenue_of_type",
              getSuffix: (row: any) => ` of ${row.revenue_type}`,
            },
            {
              type: "percent",
              dataKey: "discount_revenue_of_total",
              getSuffix: () => ` of Total`,
            },
          ],
        },
        {
          title: "Total Revenue",
          id: "total_revenue",
          totalType: "currency",
          totalDataKey: "total_revenue",
          columns: [
            {
              type: "currency",
              dataKey: "total_revenue",
            },
            {
              type: "percent",
              dataKey: "total_revenue_of_total",
            },
          ],
        },
      ],
    },
    requiredSources: [SOURCE_IDS.SHOPIFY],
  },
  // TODO: EXPERIMENTARY
  ga_session_paid_triggered_and_baseline_traffic_data_from_daily_metrics_table:
    {
      id: "ga_session_paid_triggered_and_baseline_traffic_data_from_daily_metrics_table",
      name: "Baseline and Paid Session Traffic Table",
      description:
        "This table shows aggregate performance of your baseline traffic for the entire selected time period.",
      type: "ComposedTable",
      props: {
        isComposed: true,
        comparisonEnabled: true,
        calculateTotal: true,
        columnConfig: [
          {
            keys: ["paid", "triggered", "baseline"],
            type: "title",
            header: "Breakdown",
            isBreakdown: true,
            dataKey: "breakdown",
          },
          {
            computeConfig: {
              compute: (row: any, keyPrefix: string) => {
                const total = row[`${TOTAL_CALCULATED_DATA_KEY}.sessions`]
                const value = row[`${keyPrefix}sessions`]
                if (!total || !value) {
                  return "-"
                }
                if (keyPrefix.startsWith(TOTAL_CALCULATED_DATA_KEY)) {
                  return value
                }
                const percentage = safeDivide(value, total)
                return {
                  value,
                  percentage,
                } as TComposedTableComposedValue
              },
              keySuffix: "sessions_percentage",
            },
            keys: [
              "company_revenue_metrics_daily.paid_sessions_percentage",
              "company_revenue_metrics_daily.baseline_sessions_percentage",
              "company_revenue_metrics_daily.triggered_sessions_percentage",
            ],
            required: {
              keys: [
                "company_revenue_metrics_daily.paid_sessions",
                "company_revenue_metrics_daily.baseline_sessions",
                "company_revenue_metrics_daily.triggered_sessions",
              ],
              dataKey: "sessions",
            },
            type: "number",
            dataKey: "sessions_percentage",
            header: "Sessions",
          },
          {
            computeConfig: {
              compute: (row: any, keyPrefix: string) => {
                const total = row[`${TOTAL_CALCULATED_DATA_KEY}.revenue`]
                const value = row[`${keyPrefix}revenue`]
                if (!total || !value) {
                  return "-"
                }
                if (keyPrefix.startsWith(TOTAL_CALCULATED_DATA_KEY)) {
                  return value
                }
                const percentage = safeDivide(value, total)
                return {
                  value,
                  percentage,
                } as TComposedTableComposedValue
              },
              keySuffix: "revenue_percentage",
            },
            keys: [
              "company_revenue_metrics_daily.paid_revenue_percentage",
              "company_revenue_metrics_daily.baseline_revenue_percentage",
              "company_revenue_metrics_daily.triggered_revenue_percentage",
            ],
            required: {
              keys: [
                "company_revenue_metrics_daily.paid_revenue",
                "company_revenue_metrics_daily.baseline_revenue",
                "company_revenue_metrics_daily.triggered_revenue",
              ],
              dataKey: "revenue",
            },
            type: "currency",
            dataKey: "revenue_percentage",
            header: "Revenue",
          },
          {
            computeConfig: {
              compute: (row: any, keyPrefix: string) => {
                if (
                  !row[`${keyPrefix}revenue`] ||
                  !row[`${keyPrefix}sessions`]
                ) {
                  return "-"
                }
                const value = safeDivide(
                  row[`${keyPrefix}revenue`],
                  row[`${keyPrefix}sessions`],
                )
                if (keyPrefix.startsWith(TOTAL_CALCULATED_DATA_KEY)) {
                  return value
                }
                // const total = safeDivide(
                //   row[`${TOTAL_CALCULATED_DATA_KEY}.revenue`],
                //   row[`${TOTAL_CALCULATED_DATA_KEY}.sessions`],
                // )
                // const percentage = safeDivide(value, total) - 1
                return {
                  value,
                  // percentage,
                } as TComposedTableComposedValue
              },
              keySuffix: "revenue_per_session",
            },
            keys: [
              "company_revenue_metrics_daily.paid_revenue_per_session",
              "company_revenue_metrics_daily.baseline_revenue_per_session",
              "company_revenue_metrics_daily.triggered_revenue_per_session",
            ],
            type: "currency",
            dataKey: "revenue_per_session",
            header: "Revenue/Session",
            percentageStyles: {
              negative: "text-error",
              positive: "text-success",
            },
          },
        ],
        compareUniqueKey: "sessionType",
        disableGranularity: false, // needs for data blending
      },
      metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
      requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
    },
  ga_mp_session_paid_triggered_and_baseline_traffic_data_table: {
    id: "ga_mp_session_paid_triggered_and_baseline_traffic_data_table",
    name: "Baseline and Paid Session Traffic Table",
    description:
      "This table shows aggregate performance of your baseline traffic for the entire selected time period.",
    type: "ComposedTable",
    props: {
      isComposed: true,
      comparisonEnabled: true,
      calculateTotal: true,
      columnConfig: [
        {
          keys: ["paid", "triggered", "baseline"],
          type: "title",
          header: "Breakdown",
          isBreakdown: true,
          dataKey: "breakdown",
        },
        {
          computeConfig: {
            compute: (row: any, keyPrefix: string) => {
              const total = row[`${TOTAL_CALCULATED_DATA_KEY}.sessions`]
              const value = row[`${keyPrefix}sessions`]
              if (!total || !value) {
                return "-"
              }
              if (keyPrefix.startsWith(TOTAL_CALCULATED_DATA_KEY)) {
                return value
              }
              const percentage = safeDivide(value, total)
              return {
                value,
                percentage,
              } as TComposedTableComposedValue
            },
            keySuffix: "sessions_percentage",
          },
          keys: [
            "metrics.ga_multi_property_paid_sessions_percentage",
            "metrics.ga_multi_property_baseline_sessions_percentage",
            "metrics.ga_multi_property_triggered_sessions_percentage",
          ],
          required: {
            keys: [
              "metrics.ga_multi_property_paid_sessions",
              "metrics.ga_multi_property_baseline_sessions",
              "metrics.ga_multi_property_triggered_sessions",
            ],
            dataKey: "sessions",
          },
          type: "number",
          dataKey: "sessions_percentage",
          header: "Sessions",
        },
        {
          computeConfig: {
            compute: (row: any, keyPrefix: string) => {
              const total = row[`${TOTAL_CALCULATED_DATA_KEY}.revenue`]
              const value = row[`${keyPrefix}revenue`]
              if (!total || !value) {
                return "-"
              }
              if (keyPrefix.startsWith(TOTAL_CALCULATED_DATA_KEY)) {
                return value
              }
              const percentage = safeDivide(value, total)
              return {
                value,
                percentage,
              } as TComposedTableComposedValue
            },
            keySuffix: "revenue_percentage",
          },
          keys: [
            "metrics.ga_multi_property_paid_revenue_percentage",
            "metrics.ga_multi_property_baseline_revenue_percentage",
            "metrics.ga_multi_property_triggered_revenue_percentage",
          ],
          required: {
            keys: [
              "metrics.ga_multi_property_paid_revenue",
              "metrics.ga_multi_property_baseline_revenue",
              "metrics.ga_multi_property_triggered_revenue",
            ],
            dataKey: "revenue",
          },
          type: "currency",
          dataKey: "revenue_percentage",
          header: "Revenue",
        },
        {
          computeConfig: {
            compute: (row: any, keyPrefix: string) => {
              if (!row[`${keyPrefix}revenue`] || !row[`${keyPrefix}sessions`]) {
                return "-"
              }
              const value = safeDivide(
                row[`${keyPrefix}revenue`],
                row[`${keyPrefix}sessions`],
              )
              if (keyPrefix.startsWith(TOTAL_CALCULATED_DATA_KEY)) {
                return value
              }
              // const total = safeDivide(
              //   row[`${TOTAL_CALCULATED_DATA_KEY}.revenue`],
              //   row[`${TOTAL_CALCULATED_DATA_KEY}.sessions`],
              // )
              // const percentage = safeDivide(value, total) - 1
              return {
                value,
                // percentage,
              } as TComposedTableComposedValue
            },
            keySuffix: "revenue_per_session",
          },
          keys: [
            "metrics.ga_multi_property_paid_revenue_per_session",
            "metrics.ga_multi_property_baseline_revenue_per_session",
            "metrics.ga_multi_property_triggered_revenue_per_session",
          ],
          type: "currency",
          dataKey: "revenue_per_session",
          header: "Revenue/Session",
          percentageStyles: {
            negative: "text-error",
            positive: "text-success",
          },
        },
      ],
      compareUniqueKey: "sessionType",
      disableGranularity: false, // needs for data blending
    },
    metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_baseline_traffic_vs_revenue_stacked_chart: {
    id: "ga_baseline_traffic_vs_revenue_stacked_chart",
    name: "Baseline Traffic & Revenue",
    description: null,
    type: "ComposedChart",
    props: {
      title: "Baseline Traffic & Revenue",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "session",
          label: "Baseline Traffic",
          dataKey: "metrics.ga_baseline_sessions",
          yAxisId: "sessions",
        },
        {
          type: "area",
          numberType: "currency",
          label: "Baseline Revenue",
          dataKey: "metrics.ga_baseline_revenue",
          yAxisId: "currency",
        },
      ],
      hideTotal: true,
      interactive: false,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_mp_baseline_traffic_vs_revenue_stacked_chart: {
    id: "ga_mp_baseline_traffic_vs_revenue_stacked_chart",
    name: "Baseline Traffic & Revenue",
    description: null,
    type: "ComposedChart",
    props: {
      title: "Baseline Traffic & Revenue",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "session",
          label: "Baseline Traffic",
          dataKey: "metrics.ga_multi_property_baseline_sessions",
          yAxisId: "sessions",
        },
        {
          type: "area",
          numberType: "currency",
          label: "Baseline Revenue",
          dataKey: "metrics.ga_multi_property_baseline_revenue",
          yAxisId: "currency",
        },
      ],
      hideTotal: true,
      interactive: false,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_sessions_from_organic_sessions: {
    id: "ga_sessions_from_organic_sessions",
    name: "Sessions vs. Revenue from Organic",
    description:
      "This metric represents the total number of sessions that came from Organic Search, Direct, and Organic Referral - meant to represent those users who are visiting you purely through organic, and highly reliabe/repeatable, mediums.",
    type: "ComposedChart",
    props: {
      title: "Sessions vs. Revenue from Organic",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "session",
          label: "sessions",
          yAxisId: "sessions",
          dataKey: "metrics.ga_sessions_from_organic_sessions",
        },
        {
          type: "line",
          label: "revenue",
          dataKey: "metrics.ga_revenue_from_organic_sessions",
          numberType: "currency",
          yAxisId: "currency",
        },
      ],
      hideTotal: true,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_sessions_from_direct_sessions: {
    id: "ga_sessions_from_direct_sessions",
    name: "Sessions vs. Revenue from Direct",
    description:
      "Traffic that arrives to your site from direct entry of the brand url in browser.",
    type: "ComposedChart",
    props: {
      title: "Sessions vs. Revenue from Direct",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "session",
          label: "sessions",
          yAxisId: "sessions",
          dataKey: "metrics.ga_sessions_from_direct_sessions",
        },
        {
          type: "line",
          label: "revenue",
          dataKey: "metrics.ga_revenue_from_direct_sessions",
          numberType: "currency",
          yAxisId: "currency",
        },
      ],
      hideTotal: true,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_sessions_from_organic_referal_sessions: {
    id: "ga_sessions_from_organic_referal_sessions",
    name: "Organic Social Referral",
    description:
      'Traffic that arrives to your site with medium "referral" and an organic social source utm.',
    type: "ComposedChart",
    props: {
      title: "Organic Social Referral",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "session",
          label: "sessions",
          yAxisId: "sessions",
          dataKey: "metrics.ga_sessions_from_organic_referal_sessions",
        },
        {
          type: "line",
          label: "revenue",
          dataKey: "metrics.ga_revenue_from_organic_referral_sessions",
          numberType: "currency",
          yAxisId: "currency",
        },
      ],
      hideTotal: true,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_mp_sessions_from_organic_sessions: {
    id: "ga_mp_sessions_from_organic_sessions",
    name: "Sessions vs. Revenue from Organic",
    description:
      "This metric represents the total number of sessions that came from Organic Search, Direct, and Organic Referral - meant to represent those users who are visiting you purely through organic, and highly reliabe/repeatable, mediums.",
    type: "ComposedChart",
    props: {
      title: "Sessions vs. Revenue from Organic",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "session",
          label: "sessions",
          yAxisId: "sessions",
          dataKey: "metrics.ga_multi_property_sessions_from_organic_sessions",
        },
        {
          type: "line",
          label: "revenue",
          dataKey: "metrics.ga_multi_property_revenue_from_organic_sessions",
          numberType: "currency",
          yAxisId: "currency",
        },
      ],
      hideTotal: true,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_mp_sessions_from_direct_sessions: {
    id: "ga_mp_sessions_from_direct_sessions",
    name: "Sessions vs. Revenue from Direct",
    description:
      "Traffic that arrives to your site from direct entry of the brand url in browser.",
    type: "ComposedChart",
    props: {
      title: "Sessions vs. Revenue from Direct",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "session",
          label: "sessions",
          yAxisId: "sessions",
          dataKey: "metrics.ga_multi_property_sessions_from_direct_sessions",
        },
        {
          type: "line",
          label: "revenue",
          dataKey: "metrics.ga_multi_property_revenue_from_direct_sessions",
          numberType: "currency",
          yAxisId: "currency",
        },
      ],
      hideTotal: true,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  ga_mp_sessions_from_organic_referal_sessions: {
    id: "ga_mp_sessions_from_organic_referal_sessions",
    name: "Organic Social Referral",
    description:
      'Traffic that arrives to your site with medium "referral" and an organic social source utm.',
    type: "ComposedChart",
    props: {
      title: "Organic Social Referral",
      comparisonEnabled: true,
      graphs: [
        {
          type: "area",
          unit: "session",
          label: "sessions",
          yAxisId: "sessions",
          dataKey:
            "metrics.ga_multi_property_sessions_from_organic_referal_sessions",
        },
        {
          type: "line",
          label: "revenue",
          dataKey:
            "metrics.ga_multi_property_revenue_from_organic_referral_sessions",
          numberType: "currency",
          yAxisId: "currency",
        },
      ],
      hideTotal: true,
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  session_source_and_medium_traffic_data_table: {
    id: "session_source_and_medium_traffic_data_table",
    name: "Session Source And Medium Traffic Table",
    description:
      "This table shows a breakout of all of your session sources and mediums, and their relative performance, in aggregate over the entire time period selected.",
    type: "TableVisualization",
    props: {
      comparisonEnabled: true,
      columnConfig: [
        {
          key: "company_revenue_metrics_daily.sessionsource",
          type: "title",
          header: "Source",
          width: 300,
        },
        {
          key: "company_revenue_metrics_daily.sessionmedium",
          type: "title",
          header: "Medium",
        },
        {
          key: "company_revenue_metrics_daily.baseline_sessions",
          type: "number",
          header: "Sessions",
        },
        {
          key: "company_revenue_metrics_daily.baseline_engaged_sessions",
          type: "number",
          header: "Engaged Sessions",
        },
        {
          key: "company_revenue_metrics_daily.baseline_new_sessions",
          type: "number",
          header: "New Sessions",
        },
        {
          key: "company_revenue_metrics_daily.baseline_revenue",
          type: "currency",
          header: "Revenue",
        },
        {
          key: "company_revenue_metrics_daily.baseline_revenue_per_session",
          type: "currency",
          header: "Revenue Per Session",
        },
      ],
      compareUniqueKey: "company_revenue_metrics_daily.sessionsource",
      enablePagination: true,
      disableGranularity: true,
    },
    metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
    requiredSources: [SOURCE_IDS.GOOGLE_ANALYTICS],
  },
  branded_search_clicks_stacked_chart: {
    id: "branded_search_clicks_stacked_chart",
    name: "Branded Search Clicks Chart",
    description:
      "This chart compares the number of clicks you are getting from branded search terms organically vs. in paid branded search through Google Ads.",
    type: "ComposedChart",
    props: {
      title: "Branded Search Clicks",
      totalLabelProps: {
        unit: "click",
        label: "Total",
        pluralizeUnit: true,
      },
      graphs: [
        {
          type: "area",
          label: "Paid clicks",
          dataKey: "metrics.google_ads_paid_branded_search_clicks",
          yAxisId: "clicks",
          requiredSources: [SOURCE_IDS.GOOGLE_ADS],
          labelAggType: "percent-total",
        },
        {
          type: "area",
          label: "Organic clicks",
          dataKey: "metrics.google_search_organic_branded_search_clicks",
          yAxisId: "clicks",
          requiredSources: [SOURCE_IDS.GOOGLE_SEARCH],
          labelAggType: "percent-total",
        },
        // {
        //   type: "dashed",
        //   unit: "dollar",
        //   label: "Spend",
        //   dataKey: "metrics.google_ads_paid_branded_search_total_spend",
        //   requiredSources: [SOURCE_IDS.GOOGLE_ADS],
        //   numberType: "currency",
        //   pluralizeUnit: true,
        // },
      ],
      compareUnit: "click",
      interactive: false,
      compareLabelAggType: "sum",
    },
    metadata: null,
    requiredSources: [SOURCE_IDS.GOOGLE_ADS, SOURCE_IDS.GOOGLE_SEARCH],
  },
  brand_media_optimization_campaign_table: {
    id: "brand_media_optimization_campaign_table",
    name: "Brand Media Optimization Campaign Table",
    description: "<TO BE ADDED>",
    type: "TableVisualization",
    props: {
      sortBy: `brand_value_agg`,
      queryPrefix: "company_media_metrics_campaigns",
      columnConfig: [
        {
          key: "media_channel",
          type: "image",
          header: "Media Channel",
          classes: {
            head: "text-center",
            cell: "text-center",
          },
          width: 65,
        },
        {
          key: "campaign_name",
          type: "title",
          render: (row: any) => {
            const title = row.campaign_name
            if (typeof title !== "string") {
              return ""
            }

            return <CaptionPreview text={title} exact={true} />
          },
          header: "Name",
          width: 350,
        },
        {
          key: "campaign_status",
          type: "title",
          header: "Status",
          width: 100,
        },
        {
          key: "spend",
          type: "currency",
          header: "Spend",
          width: 100,
        },
        {
          key: "brand_value_agg",
          type: "currency",
          isBrand: true,
          header: "180d Brand Value",
          width: 120,
        },
        {
          key: "brand_value_over_spend",
          type: "coef",
          isBrand: true,
          header: "180d Brand ROAS",
          width: 120,
        },
        {
          key: "purchases",
          type: "currency",
          header: "7d Click DR Revenue",
          width: 110,
        },
        {
          key: "short_term_revenue_roas",
          type: "coef",
          header: "7d Click DR ROAS",
          width: 110,
        },
        {
          key: "long_term_revenue",
          type: "currency",
          header: "Total Value",
          width: 130,
        },
        {
          key: "long_term_revenue_roas",
          type: "coef",
          header: "Total ROAS",
          width: 130,
        },
        {
          key: "shares",
          header: "Shares",
          type: "number",
          width: 110,
        },
        {
          key: "likes",
          header: "Likes & Reactions",
          type: "number",
          width: 120,
        },
        {
          key: "follows",
          header: "Followers",
          type: "number",
          width: 120,
        },
        {
          key: "comments",
          header: "Comments",
          type: "number",
          width: 130,
        },
        {
          key: "saves",
          header: "Saves",
          type: "number",
          width: 110,
        },
        {
          key: "facebook_ads_spend",
          type: "currency",
          header: "Facebook ads spend",
          width: 120,
          disabled: true,
        },
        {
          key: "facebook_ads_comments",
          type: "number",
          header: "Facebook ads comments",
          width: 120,
          disabled: true,
        },
        {
          key: "facebook_ads_shares",
          type: "number",
          header: "Facebook ads shares",
          width: 120,
          disabled: true,
        },
        {
          key: "facebook_ads_new_fans",
          type: "number",
          header: "Facebook ads new fans",
          width: 120,
          disabled: true,
        },
        {
          key: "facebook_ads_reactions",
          type: "number",
          header: "Facebook ads reactions",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_spend",
          type: "currency",
          header: "Instagram ads spend",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_follows",
          type: "number",
          header: "Instagram ads follows",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_comments",
          type: "number",
          header: "Instagram ads comments",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_likes",
          type: "number",
          header: "Instagram ads likes",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_shares",
          type: "number",
          header: "Instagram ads shares",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_spend",
          type: "currency",
          header: "TikTok ads spend",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_follows",
          type: "number",
          header: "TikTok ads follows",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_comments",
          type: "number",
          header: "TikTok ads comments",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_shares",
          type: "number",
          header: "TikTok ads shares",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_likes",
          type: "number",
          header: "TikTok ads likes",
          width: 120,
          disabled: true,
        },
      ],
      totalLabelColumn: "campaign_name",
      compareUniqueKey: "campaign_id",
      enablePagination: true,
      rowClickInDrawer: true,
      prefixEnabled: true,
      disableGranularity: true,
    },
    metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
    requiredSources: [SOURCE_IDS.META, SOURCE_IDS.TIKTOK_ADS],
  },
  brand_media_optimization_adsets_table: {
    id: "brand_media_optimization_adsets_table",
    name: "Brand Media Optimization Ad Sets Table",
    description: "<TO BE ADDED>",
    type: "TableVisualization",
    props: {
      sortBy: `brand_value_agg`,
      queryPrefix: "company_media_metrics_adsets",
      additionalFilters: [
        {
          value: "campaign_id",
          label: "Campaign Id",
        },
      ],
      columnConfig: [
        {
          key: "media_channel",
          type: "image",
          header: "Media Channel",
          classes: {
            head: "text-center",
            cell: "text-center",
          },
          width: 65,
        },
        {
          key: "adset_name",
          type: "title",
          header: "Name",
          render: (row: any) => {
            const title = row["adset_name"]
            if (typeof title !== "string") {
              return ""
            }

            return <CaptionPreview text={title} exact={true} />
          },
          width: 350,
        },
        {
          key: "campaign_name",
          type: "title",
          header: "Campaign",
          render: (row: any) => {
            const title = row.campaign_name
            if (typeof title !== "string") {
              return ""
            }

            return <CaptionPreview text={title} exact={true} />
          },
          width: 350,
        },
        {
          key: "campaign_status",
          type: "title",
          header: "Campaign Status",
          width: 100,
        },
        {
          key: "adset_status",
          type: "title",
          header: "Adset Status",
          width: 125,
        },
        {
          key: "spend",
          type: "currency",
          header: "Spend",
          width: 100,
        },
        {
          key: "brand_value_agg",
          type: "currency",
          isBrand: true,
          header: "180d Brand Value",
          width: 120,
        },
        {
          key: "brand_value_over_spend",
          type: "coef",
          isBrand: true,
          header: "180d Brand ROAS",
          width: 120,
        },
        {
          key: "purchases",
          type: "currency",
          header: "7d Click DR Revenue",
          width: 110,
        },
        {
          key: "short_term_revenue_roas",
          type: "coef",
          header: "7d Click DR ROAS",
          width: 110,
        },
        {
          key: "long_term_revenue",
          type: "currency",
          header: "Total Value",
          width: 130,
        },
        {
          key: "long_term_revenue_roas",
          type: "coef",
          header: "Total ROAS",
          width: 130,
        },
        {
          key: "shares",
          header: "Shares",
          type: "number",
          width: 110,
        },
        {
          key: "likes",
          header: "Likes & Reactions",
          type: "number",
          width: 120,
        },
        {
          key: "follows",
          header: "Followers",
          type: "number",
          width: 120,
        },
        {
          key: "comments",
          header: "Comments",
          type: "number",
          width: 130,
        },
        {
          key: "saves",
          header: "Saves",
          type: "number",
          width: 110,
        },
        {
          key: "facebook_ads_spend",
          type: "currency",
          header: "Facebook ads spend",
          width: 120,
          disabled: true,
        },
        {
          key: "facebook_ads_comments",
          type: "number",
          header: "Facebook ads comments",
          width: 120,
          disabled: true,
        },
        {
          key: "facebook_ads_shares",
          type: "number",
          header: "Facebook ads shares",
          width: 120,
          disabled: true,
        },
        {
          key: "facebook_ads_new_fans",
          type: "number",
          header: "Facebook ads new fans",
          width: 120,
          disabled: true,
        },
        {
          key: "facebook_ads_reactions",
          type: "number",
          header: "Facebook ads reactions",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_spend",
          type: "currency",
          header: "Instagram ads spend",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_follows",
          type: "number",
          header: "Instagram ads follows",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_comments",
          type: "number",
          header: "Instagram ads comments",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_likes",
          type: "number",
          header: "Instagram ads likes",
          width: 120,
          disabled: true,
        },
        {
          key: "instagram_ads_shares",
          type: "number",
          header: "Instagram ads shares",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_spend",
          type: "currency",
          header: "TikTok ads spend",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_follows",
          type: "number",
          header: "TikTok ads follows",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_comments",
          type: "number",
          header: "TikTok ads comments",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_shares",
          type: "number",
          header: "TikTok ads shares",
          width: 120,
          disabled: true,
        },
        {
          key: "tiktok_ads_likes",
          type: "number",
          header: "TikTok ads likes",
          width: 120,
          disabled: true,
        },
        {
          key: "optimization_goal",
          type: "title",
          header: "Optimization Goal",
          width: 95,
          disabled: true,
        },
        {
          key: "budget",
          type: "currency",
          header: "Budget",
          width: 85,
          disabled: true,
        },
      ],
      totalLabelColumn: "adset_name",
      compareUniqueKey: "adset_id",
      enablePagination: true,
      rowClickInDrawer: true,
      prefixEnabled: true,
      disableGranularity: true,
    },
    metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
    requiredSources: [SOURCE_IDS.META, SOURCE_IDS.TIKTOK_ADS],
  },
  brand_media_optimization_ads_table: {
    id: "brand_media_optimization_ads_table",
    name: "Brand Media Optimization Ads Table",
    description: "<TO BE ADDED>",
    type: "TableVisualization",
    props: {
      queryPrefix: "company_media_metrics_ads",
      sortBy: `brand_value_agg`,
      additionalFilters: [
        {
          value: "campaign_id",
          label: "Campaign Id",
        },
        {
          value: "adset_id",
          label: "AdSet Id",
        },
      ],
      columnConfig: [
        // unused key "company_media_metrics_daily.campaignid",
        {
          key: "media_channel",
          type: "image",
          header: "Media Channel",
          classes: {
            head: "text-center",
            cell: "text-center",
          },
          width: 65,
        },
        {
          key: "ad_name",
          type: "title",
          header: "Name",
          render: (row: any) => {
            const title = row["ad_name"]
            if (typeof title !== "string") {
              return ""
            }

            return <CaptionPreview text={title} exact={true} />
          },
          width: 350,
        },
        {
          key: "adset_name",
          type: "title",
          header: "Ad Set",
          render: (row: any) => {
            const title = row["adset_name"]
            if (typeof title !== "string") {
              return ""
            }

            return <CaptionPreview text={title} exact={true} />
          },
          width: 350,
        },
        {
          key: "campaign_name",
          type: "title",
          header: "Campaign",
          render: (row: any) => {
            const title = row["campaign_name"]
            if (typeof title !== "string") {
              return ""
            }

            return <CaptionPreview text={title} exact={true} />
          },
          width: 350,
        },
        {
          key: "campaign_status",
          type: "title",
          header: "Campaign Status",
          width: 100,
        },
        {
          key: "adset_status",
          type: "title",
          header: "Adset Status",
          width: 125,
        },
        {
          key: "ad_status",
          type: "title",
          header: "Ad Status",
          width: 125,
        },
        {
          key: "spend",
          type: "currency",
          header: "Spend",
          width: 100,
        },
        {
          key: "brand_value_agg",
          type: "currency",
          isBrand: true,
          header: "180d Brand Value",
          width: 120,
        },
        {
          key: "brand_value_over_spend",
          type: "coef",
          isBrand: true,
          header: "180d Brand ROAS",
          width: 120,
        },
        {
          key: "purchases",
          type: "currency",
          header: "7d Click DR Revenue",
          width: 110,
        },
        {
          key: "short_term_revenue_roas",
          type: "coef",
          header: "7d Click DR ROAS",
          width: 110,
        },
        {
          key: "long_term_revenue",
          type: "currency",
          header: "Total Value",
          width: 130,
        },
        {
          key: "long_term_revenue_roas",
          type: "coef",
          header: "Total ROAS",
          width: 130,
        },
        {
          key: "shares",
          header: "Shares",
          type: "number",
          width: 120,
        },
        {
          key: "likes",
          header: "Likes & Reactions",
          type: "number",
          width: 120,
        },
        {
          key: "follows",
          header: "Followers",
          type: "number",
          width: 120,
        },
        {
          key: "comments",
          header: "Comments",
          type: "number",
          width: 120,
        },
        {
          key: "saves",
          header: "Saves",
          type: "number",
          width: 120,
        },
        {
          key: "facebook_ads_spend",
          type: "currency",
          header: "Facebook ads spend",
          disabled: true,
          width: 120,
        },
        {
          key: "facebook_ads_comments",
          type: "number",
          header: "Facebook ads comments",
          disabled: true,
          width: 120,
        },
        {
          key: "facebook_ads_shares",
          type: "number",
          header: "Facebook ads shares",
          disabled: true,
          width: 120,
        },
        {
          key: "facebook_ads_new_fans",
          type: "number",
          header: "Facebook ads new fans",
          disabled: true,
          width: 120,
        },
        {
          key: "facebook_ads_reactions",
          type: "number",
          header: "Facebook ads reactions",
          disabled: true,
          width: 120,
        },
        {
          key: "instagram_ads_spend",
          type: "currency",
          header: "Instagram ads spend",
          disabled: true,
          width: 120,
        },
        {
          key: "instagram_ads_follows",
          type: "number",
          header: "Instagram ads follows",
          disabled: true,
          width: 120,
        },
        {
          key: "instagram_ads_comments",
          type: "number",
          header: "Instagram ads comments",
          disabled: true,
          width: 120,
        },
        {
          key: "instagram_ads_likes",
          type: "number",
          header: "Instagram ads likes",
          disabled: true,
          width: 120,
        },
        {
          key: "instagram_ads_shares",
          type: "number",
          header: "Instagram ads shares",
          disabled: true,
          width: 120,
        },
        {
          key: "tiktok_ads_spend",
          type: "currency",
          header: "TikTok ads spend",
          disabled: true,
          width: 120,
        },
        {
          key: "tiktok_ads_follows",
          type: "number",
          header: "TikTok ads follows",
          disabled: true,
          width: 120,
        },
        {
          key: "tiktok_ads_comments",
          type: "number",
          header: "TikTok ads comments",
          disabled: true,
          width: 120,
        },
        {
          key: "tiktok_ads_shares",
          type: "number",
          header: "TikTok ads shares",
          disabled: true,
          width: 120,
        },
        {
          key: "tiktok_ads_likes",
          type: "number",
          header: "TikTok ads likes",
          disabled: true,
          width: 120,
        },
        {
          key: "optimization_goal",
          type: "title",
          header: "Optimization Goal",
          disabled: true,
          width: 95,
        },
        {
          key: "budget",
          type: "currency",
          header: "Budget",
          disabled: true,
          width: 85,
        },
      ],
      totalLabelColumn: "ad_name",
      compareUniqueKey: "ad_id",
      enablePagination: true,
      rowClickInDrawer: true,
      prefixEnabled: true,
      disableGranularity: true,
    },
    metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
    requiredSources: [SOURCE_IDS.META, SOURCE_IDS.TIKTOK_ADS],
  },
  brand_media_organic_social_posts_table: {
    id: "brand_media_organic_social_posts_table",
    name: "Brand Media Organic Social Posts Table",
    description: "<TO BE ADDED>",
    type: "TableVisualization",
    props: {
      queryPrefix: "company_media_metrics_organic_posts",
      sortBy: `company_media_metrics_organic_posts.brand_value_agg`,
      columnConfig: [
        // {
        //   key: "company_media_metrics_organic_posts.md_date",
        //   type: "date",
        //   header: "Date",
        //   width: 120,
        // },
        {
          key: "company_media_metrics_organic_posts.media_channel",
          type: "image",
          header: "Media Channel",
          classes: {
            head: "text-center",
            cell: "flex items-center justify-center",
          },
          width: 85,
        },
        {
          key: "company_media_metrics_organic_posts.post_preview",
          type: "image",
          header: "Post Preview",
          classes: {
            head: "text-center",
            cell: "flex items-center justify-center",
          },
          render: (
            row: any,
            { currentCompany }: { currentCompany: CompanyDto },
          ) => {
            const postId = row["company_media_metrics_organic_posts.post_id"]
            const previewLink =
              row["company_media_metrics_organic_posts.post_preview"]
            const postCaption =
              row["company_media_metrics_organic_posts.post_caption"]

            const mediaChannel =
              row["company_media_metrics_organic_posts.media_channel"]

            let cachedImage = useAdImage(
              currentCompany?.id,
              mediaChannel,
              postId,
            )
            if (mediaChannel === "youtube") {
              cachedImage = previewLink
            }

            return (
              <Image
                classes={{
                  image: "w-full h-full",
                  wrapper: "aspect-square overflow-hidden",
                }}
                src={cachedImage}
                fallback={previewLink}
                alt={postCaption}
              />
            )
          },
        },
        {
          key: "company_media_metrics_organic_posts.post_caption",
          type: "text",
          render: (row: any) => {
            const caption =
              row["company_media_metrics_organic_posts.post_caption"]
            const postLink =
              row["company_media_metrics_organic_posts.post_link"]
            if (typeof caption !== "string") {
              return ""
            }

            return (
              <CaptionPreview
                text={caption}
                link={postLink}
                alwaysShow={true}
                exact={true}
                fullCaptionClass="max-w-200 word-break break-words"
                align="left"
              />
            )
          },
          header: "Post Caption",
          width: 300,
        },
        {
          key: "company_media_metrics_organic_posts.brand_value_agg",
          type: "currency",
          isBrand: true,
          header: "180d Brand Value",
          width: 150,
        },
        {
          key: "company_media_metrics_organic_posts.reach",
          type: "number",
          header: "Reach",
          width: 100,
        },
        {
          key: "company_media_metrics_organic_posts.brand_value_reach",
          isBrand: true,
          type: "currency",
          header: "Brand Value / Reach",
          width: 140,
        },
        {
          key: "company_media_metrics_organic_posts.likes",
          header: "Likes & Reactions",
          type: "number",
          width: 100,
        },
        {
          key: "company_media_metrics_organic_posts.likes_reach",
          header: "Likes / Reach",
          type: "coef",
          width: 140,
        },
        {
          key: "company_media_metrics_organic_posts.comments",
          header: "Comments",
          type: "number",
          width: 130,
        },
        {
          key: "company_media_metrics_organic_posts.comments_reach",
          header: "Comments / Reach",
          type: "coef",
          width: 100,
        },
        {
          key: "company_media_metrics_organic_posts.shares",
          header: "Shares",
          type: "number",
          width: 100,
        },
        {
          key: "company_media_metrics_organic_posts.shares_reach",
          header: "Shares / Reach",
          type: "coef",
          width: 110,
        },
        {
          key: "company_media_metrics_organic_posts.saves",
          header: "Saves",
          type: "number",
          width: 100,
        },
        {
          key: "company_media_metrics_organic_posts.saves_reach",
          header: "Saves / Reach",
          type: "coef",
          width: 100,
        },
        {
          key: "company_media_metrics_organic_posts.follows",
          header: "Follows",
          type: "number",
          width: 110,
        },
      ],
      totalLabelColumn: "company_media_metrics_organic_posts.post_caption",
      enablePagination: true,
      compareUniqueKey: "company_media_metrics_organic_posts.post_id",
      disableGranularity: true,
      rowClickInDrawer: true,
    },
    metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
    requiredSources: [
      SOURCE_IDS.FACEBOOK,
      SOURCE_IDS.INSTAGRAM,
      SOURCE_IDS.TIKTOK,
    ],
  },
  brand_media_media_mentions_table: {
    id: "brand_media_media_mentions_posts_table",
    name: "Brand Media Mentions Table",
    description: "<TO BE ADDED>",
    type: "TableVisualization",
    props: {
      queryPrefix: "company_media_metrics_mentions",
      sortBy: `company_media_metrics_mentions.brand_value_agg`,
      columnConfig: [
        {
          key: "company_media_metrics_mentions.md_date",
          type: "date",
          header: "Date",
          width: 100,
        },
        {
          key: "company_media_metrics_mentions.media_channel",
          type: "image",
          header: "Media Channel",
          classes: {
            head: "text-center",
            cell: "flex items-center justify-center",
          },
          width: 85,
        },
        {
          key: "company_media_metrics_mentions.post_caption",
          type: "text",
          render: (row: any) => {
            const caption = row["company_media_metrics_mentions.post_caption"]
            const postLink = row["company_media_metrics_mentions.post_link"]
            if (typeof caption !== "string") {
              return ""
            }

            return (
              <CaptionPreview
                text={caption}
                link={postLink}
                alwaysShow={true}
                exact={true}
                fullCaptionClass="max-w-200 word-break break-words"
                align="left"
              />
            )
          },
          header: "Post Caption",
        },
        {
          key: "company_media_metrics_mentions.display_url",
          type: "image",
          header: "Post Preview",
          render: (row: any) => {
            const imageUrl = row["company_media_metrics_mentions.display_url"]
            const creatorName =
              row["company_media_metrics_mentions.creator_name"]

            return (
              <Image
                classes={{
                  image: "w-full h-full max-w-60 max-h-60",
                  wrapper: "aspect-square overflow-hidden rounded-xl",
                }}
                src={imageUrl}
                alt={creatorName}
              />
            )
          },
          width: 400,
        },
        {
          key: "company_media_metrics_mentions.creator_name",
          type: "title",
          header: "Creator",
          width: 150,
        },
        {
          key: "company_media_metrics_mentions.followers",
          header: "Followers",
          type: "number",
          width: 120,
        },
        {
          key: "company_media_metrics_mentions.brand_value_agg",
          type: "currency",
          isBrand: true,
          header: "180d Brand Value",
          width: 120,
        },
        {
          key: "company_media_metrics_mentions.reach",
          type: "number",
          header: "Reach",
          disabled: true,
          width: 100,
        },
        {
          key: "company_media_metrics_mentions.likes",
          header: "Likes & Reactions",
          type: "number",
          width: 100,
        },
        {
          key: "company_media_metrics_mentions.comments",
          header: "Comments",
          type: "number",
          width: 130,
        },
      ],
      totalLabelColumn: "company_media_metrics_mentions.post_caption",
      enablePagination: true,
      compareUniqueKey: "company_media_metrics_mentions.post_id",
      disableGranularity: true,
      rowClickInDrawer: true,
    },
    metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
  },
  brand_value_cohort_table: {
    id: "brand_value_cohort_table",
    name: "Brand Value Cohort Table",
    description: "___MISSING___",
    type: "CohortVisualization",
    props: {
      dataId: "query_cohort_analysis",
      filterId: "cohort-metric",
      dataAggTypeOnly: "MONTHLY",
      columnConfig: [
        {
          key: "company_modeled_metrics_monthly.engagement_date.month",
          type: "date",
          header: "Cohort Date",
        },
        {
          key: "company_modeled_metrics_monthly.engagements",
          type: "number",
          header: "Cohort Size",
        },
        {
          key: "company_modeled_metrics_monthly.conversionMonth1",
          type: "currency",
          header: "Month 1",
        },
        {
          key: "company_modeled_metrics_monthly.conversionMonth2",
          type: "currency",
          header: "Month 2",
        },
        {
          key: "company_modeled_metrics_monthly.conversionMonth3",
          type: "currency",
          header: "Month 3",
        },
        {
          key: "company_modeled_metrics_monthly.conversionMonth4",
          type: "currency",
          header: "Month 4",
        },
        {
          key: "company_modeled_metrics_monthly.conversionMonth5",
          type: "currency",
          header: "Month 5",
        },
        {
          key: "company_modeled_metrics_monthly.conversionMonth6",
          type: "currency",
          header: "Month 6",
        },
        // {
        //   key: "company_modeled_metrics_monthly.conversionMonth7",
        //   type: "currency",
        //   header: "Month 7",
        // },
        // {
        //   key: "company_modeled_metrics_monthly.conversionMonth8",
        //   type: "currency",
        //   header: "Month 8",
        // },
        // {
        //   key: "company_modeled_metrics_monthly.conversionMonth9",
        //   type: "currency",
        //   header: "Month 9",
        // },
        // {
        //   key: "company_modeled_metrics_monthly.conversionMonth10",
        //   type: "currency",
        //   header: "Month 10",
        // },
        // {
        //   key: "company_modeled_metrics_monthly.conversionMonth11",
        //   type: "currency",
        //   header: "Month 11",
        // },
        // {
        //   key: "company_modeled_metrics_monthly.conversionMonth12",
        //   type: "currency",
        //   header: "Month 12",
        // },
      ],
      compareUniqueKey: "id",
      forecastCellColor: "#D6D6D6",
      forecastLabelText: "Forecasted Incremental Owned & Organic Revenue",
      realizedCellColor: "#BBDDFF",
      realizedLabelText: "Realized Incremental Owned & Organic Revenue",
      disableGranularity: false,
    },
  },
  brand_value_line_chart: {
    id: "brand_line_stacked_chart",
    name: "180-Day Brand Value",
    description:
      "Brand Value represents the next-180-day baseline revenue of your brand engagements. Think of this as the next 180 day cohort value of the new engagements you created in any given time period as measured in growth in baseline revenue.",
    type: "ComposedChart",
    props: {
      title: "180-Day Brand Value",
      hideTotal: true,
      dataAggTypeOnly: "MONTHLY",
      comparisonEnabled: true,
      graphs: [
        {
          type: "line",
          label: "180d Brand Value",
          numberType: "currency",
          dataKey: "company_modeled_metrics_monthly.conversions",
        },
      ],
    },
    metadata: null,
  },
  brand_value_stacked_chart: {
    id: "brand_value_stacked_chart",
    name: "Brand Value Chart",
    description:
      "Brand Value represents the next-180-day baseline revenue of your brand engagements. Think of this as the next 180 day cohort value of the new engagements you created in any given time period as measured in growth in baseline revenue.",
    type: "ComposedChart",
    props: {
      title: "180d Brand Value from Direct Engagements",
      hideTotal: true,
      comparisonEnabled: true,
      isInverted: true,
      hideTooltipTitle: true,
      graphs: [
        {
          type: "area",
          label: "Engagements",
          dataKey: "engagements",
          yAxisId: "eng",
        },
        {
          type: "area",
          label: "180d Brand Value from Direct Engagements",
          numberType: "currency",
          dataKey: "conversions",
          isBrand: true,
          yAxisId: "cur",
        },
        {
          type: "area",
          label: "Baseline Revenue",
          numberType: "currency",
          dataKey: "baseline_revenue",
          yAxisId: "cur",
        },
      ],
    },
    metadata: null,
  },
  brand_value_conversions_table: {
    id: "brand_value_conversions_table",
    name: "Brand Value Conversions Table",
    description:
      "Brand Value represents the next-180-day baseline revenue of your brand engagements. Think of this as the next 180 day cohort value of the new engagements you created in any given time period as measured in growth in baseline revenue.",
    type: "TableVisualization",
    props: {
      comparisonEnabled: true,
      summaryWidget: "brand_value_conversions_table_summary",
      totalLabelColumn: "company_platform_brand_value.platform",
      columnConfig: [
        {
          key: "company_platform_brand_value.platform",
          type: "title",
          width: 300,
          header: "Platform",
        },
        {
          key: "company_platform_brand_value.engagements",
          type: "number",
          header: "Count",
        },
        {
          key: "company_platform_brand_value.revenue90Days",
          type: "currency",
          isBrand: true,
          header: "90d Brand Value",
        },
        {
          key: "company_platform_brand_value.revenue180Days",
          type: "currency",
          isBrand: true,
          header: "180d Brand Value",
        },
        {
          key: "company_platform_brand_value.rpe90Days",
          type: "currency",
          isBrand: true,
          header: "90d Brand Value from Direct Engagements Per Platform",
          disableSorting: true,
        },
        {
          key: "company_platform_brand_value.rpe180Days",
          type: "currency",
          isBrand: true,
          header: "180d Brand Value from Direct Engagements Per Platform",
          disableSorting: true,
        },
      ],
      compareUniqueKey: "company_platform_brand_value.platform",
      enablePagination: true,
      disableGranularity: true,
    },
    metadata: { defaultColSpan: 12, defaultRowSpan: 12 },
    requiredSources: [
      SOURCE_IDS.GOOGLE_ADS,
      SOURCE_IDS.FACEBOOK_ADS,
      SOURCE_IDS.TIKTOK_ADS,
    ],
  },
}

export type VISUALIZATIONS_MAP_KEY = keyof typeof VISUALIZATIONS_MAP
