import pluralize from "pluralize"
import { calcDelta } from "~/ui-rtk/utils/chart-utils"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { TrendingUpSVG } from "../../svg/essentials/TrendingUp"
import { TrendingDownSVG } from "../../svg/essentials/TrendingDown"

type TValueWithDeltaProps = {
  actualValue: number
  comparedValue: number
  unit?: string
  hideActualValue?: boolean
  vertical?: boolean
  className?: string
  actualClassName?: string
  size?: number
}

export default function ValueWithDelta({
  actualValue,
  comparedValue,
  unit,
  hideActualValue = false,
  vertical = false,
  className,
  actualClassName,
  size,
}: TValueWithDeltaProps) {
  const actualValueDelta = calcDelta(actualValue, comparedValue)
  let actualValueColorClassName = ""
  if (actualValueDelta !== null) {
    actualValueColorClassName =
      actualValueDelta < 0 ? "text-states-error" : "text-american-green"
  }

  const mainClassNames = cn(
    "value-with-delta",
    !vertical && "flex gap-2",
    className,
  )
  const textClassNames = cn(
    "value-with-delta__compare self-end",
    actualValueColorClassName,
  )

  return (
    <div className={mainClassNames}>
      {!hideActualValue && (
        <div className={cn("value-with-delta__actualValue", actualClassName)}>
          {actualValue} {unit && pluralize(unit, actualValue)}
        </div>
      )}
      {actualValueDelta !== null ? (
        <div className={textClassNames}>
          {actualValueDelta > 0 ? (
            <>
              <TrendingUpSVG size={size} />+{actualValueDelta.toFixed(0)}
            </>
          ) : (
            <>
              <TrendingDownSVG size={size} />
              {actualValueDelta.toFixed(0)}
            </>
          )}
          %
        </div>
      ) : (
        <span className="px-1.5">-</span>
      )}
    </div>
  )
}
