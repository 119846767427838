import React from "react"
import { ConnectorsTable } from "~/ui-rtk/components/features/connectors"
import Breadcrumbs from "../../components/layout/Breadcrumbs"

const Connectors: React.FC = () => (
  <div>
    <div className="text-start">
      <Breadcrumbs
        items={[
          {
            slug: "/setup",
            name: "Setup",
          },
          {
            slug: "connectors",
            name: "Connectors",
          },
        ]}
      />
    </div>
    <div className="max-w-350 mx-auto md:space-y-8 md:mt-4 text-center md:pr-8">
      <ConnectorsTable />
    </div>
  </div>
)

export default Connectors
