import { usePostHog } from "posthog-js/react"
import {
  POSTHOG_FEATURES,
  type TPosthogFeatureKey,
} from "../constants/feature-flags"

export default function usePostHogFeatureFlags(flag: TPosthogFeatureKey) {
  const postHog = usePostHog()
  if (flag === POSTHOG_FEATURES.BRAND_MEDIA_MENTIONS) {
    return true
  }
  return postHog?.isFeatureEnabled(flag) ?? false
}
