import useDemoMode from "~/ui-rtk/hooks/demo-mode"
import useConnect, { type TKeywordsPageFormValues } from "./connect"
import { Field, FieldProps, Form, Formik, FormikProps } from "formik"
import { useEffect, useRef, useState } from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { Button, ChipsInput } from "~/ui-rtk/components/ui/controls"
import { debounce } from "lodash"
import { ErrorBlock } from "~/ui-rtk/utils/form"
import KeywordsChangesDialog from "~/ui-rtk/components/ui/common/KeywordsChangesDialog/KeywordsChangesDialog"

export default function BrandKeywordsForm({ onSave }: { onSave?: () => void }) {
  const { isEnabled: isDemoEnabled } = useDemoMode()
  const [isLoading, setIsLoading] = useState(false)
  const [showInfoDialog, setShowInfoDialog] = useState(false)
  const formRef = useRef<FormikProps<TKeywordsPageFormValues> | null>(null)

  const [uiDontShowDialog, setUiDontShowDialog] = useState(false)
  const {
    initialValues,
    validationSchema,
    updateKeywords,
    dontShowDialogAgain,
    setDontShowDialogAgain,
  } = useConnect()

  useEffect(() => {
    setUiDontShowDialog(dontShowDialogAgain)
  }, [])

  const handleUpdateKeywords = async (values: TKeywordsPageFormValues) => {
    setIsLoading(true)
    await updateKeywords(values)
    setIsLoading(false)
    setShowInfoDialog(true)
    setUiDontShowDialog(dontShowDialogAgain)
    if (dontShowDialogAgain) {
      onSave?.()
    }
  }

  const handleCloseInfoDialog = () => {
    setShowInfoDialog(false)
    onSave?.()
  }

  const handleSubmit = async () => {
    await formRef.current?.validateForm()
    if (formRef.current?.isValid) {
      handleUpdateKeywords(formRef.current?.values).catch(() => null)
    }
  }

  const handleSubmitDebounced = debounce(handleSubmit, 2000)

  const infoDialogContent =
    !uiDontShowDialog && showInfoDialog ? (
      <KeywordsChangesDialog
        onClose={handleCloseInfoDialog}
        dontShowDialogAgain={dontShowDialogAgain}
        setDontShowDialogAgain={setDontShowDialogAgain}
      />
    ) : null

  return (
    <div className="px-3">
      <div className={cn("text-start", isDemoEnabled && "blur-md")}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleUpdateKeywords}
          validationSchema={validationSchema}
          enableReinitialize
          innerRef={formRef}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ errors /* ,  touched */ }) => (
            <Form className="py-3">
              <div className="relative col-span-2">
                <label className="leading-8" htmlFor="googleCategories">
                  Google Brand Keywords *:
                  <br />
                  <Field id="googleBrands" name="googleBrands">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      // meta,
                    }: FieldProps) => (
                      <ChipsInput
                        {...field}
                        onChange={async value => {
                          await setFieldValue("googleBrands", value)
                          void handleSubmitDebounced()
                        }}
                      />
                    )}
                  </Field>
                  <ErrorBlock error={errors.googleBrands} />
                </label>
              </div>
              <div className="flex aling-center justify-end mt-4">
                <Button
                  variant={{ variant: "solid", color: "pink" }}
                  size="sm"
                  className="btn-block"
                  isLoading={isLoading}
                  onClick={handleSubmit}
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {infoDialogContent}
      </div>
    </div>
  )
}
