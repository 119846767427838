import { NavLink } from "react-router-dom"
import { Fragment } from "react"
import { ChevronRightSvg } from "../../ui/svg/arrow"

export type TBreadcrumb = {
  name: string
  slug?: string
}

type TBreadcrumbsProps = {
  items: TBreadcrumb[]
}

export default function Breadcrumbs({ items }: TBreadcrumbsProps) {
  const isLastIdx = (idx: number) => items.length - 1 === idx

  const renderedBreadcrumbs = items.map(
    ({ name, slug }: TBreadcrumb, idx: number) =>
      isLastIdx(idx) ? (
        <span key={idx} className="text-lg">
          {name}
        </span>
      ) : (
        <Fragment key={idx}>
          {slug ? (
            <NavLink to={slug} key={slug} className="text-lg">
              {name}
            </NavLink>
          ) : (
            <div className="text-lg text-white/50">{name}</div>
          )}
          <span key={`connector-${slug}`} className="text-lg">
            <ChevronRightSvg size={12} className="inline mx-2" fill="gray" />
          </span>
        </Fragment>
      ),
  )
  return (
    <div className="flex justify-start items-center">{renderedBreadcrumbs}</div>
  )
}
