import { useState } from "react"
import { useAppSession } from "../pages/Auth/useCubeApiToken"

const cache: Record<string, string[]> = {}

export default function useMediaOptimizationMediaChannels(prefix: string) {
  const { cubeApi } = useAppSession()
  const [isLoading, setIsLoading] = useState(false)
  const mediaChannelKey = `${prefix}.media_channel`

  const request = async () => {
    setIsLoading(true)
    if (!cache[prefix]) {
      const q = {
        dimensions: [mediaChannelKey],
      }

      const res = await cubeApi.load(q)

      if (res?.rawData().length) {
        cache[prefix] = res
          .rawData()
          .map(row => row[mediaChannelKey]) as string[]
      }
    }
    setIsLoading(false)
  }

  return {
    isLoading,
    mediaChannels: cache[prefix],
    request,
  }
}
