import { HTMLAttributes, useMemo } from "react"
import { Image } from "../common/Image"
import Logo from "~/ui-rtk-public/assets/img/logo.svg?url"

export type TMarathonDataLogoProps = HTMLAttributes<HTMLImageElement> & {
  size?: number
}

export default function MarathonDataLogo({
  size,
  ...props
}: TMarathonDataLogoProps) {
  const renderSize = useMemo(() => size ?? 16, [size])
  const styles = useMemo(
    () => ({
      width: `${renderSize}px`,
      height: `${renderSize}px`,
    }),
    [renderSize],
  )
  return (
    <Image
      size={renderSize}
      style={styles}
      src={Logo}
      alt="MarathonData logo"
      {...props}
    />
  )
}
