import { useEffect, useMemo, useState } from "react"
import { type CompanyGoalDto } from "~/ui-rtk/api/companyGoalApi"
import { Card, Loader } from "~/ui-rtk/components/ui/common"
import useDate from "~/ui-rtk/hooks/date"
import useCurrentCompany from "~/ui-rtk/hooks/current-company"
import {
  CheckCircleIcon,
  ClockIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline"
import useMetricChartData, {
  getGoalValueForDate,
} from "~/ui-rtk/pages/Goals/hooks/metric-chart"
import {
  GOAL_GROWTH_TYPE,
  metricsOptions,
} from "~/ui-rtk/pages/Goals/constants"
import GoalsChart from "~/ui-rtk/pages/Goals/components/GoalsChart"
import { useChartForecast } from "~/ui-rtk/pages/Goals/hooks/metric-forecast"
import { H6 } from "~/ui-rtk/components/ui/typography"
import { toCurrency } from "~/ui-rtk/utils/number-utils"

const dayjs = useDate()
const FORMAT = "MMMM, YYYY"

export type TGoalCardProps = {
  card: CompanyGoalDto
  onDelete: () => void
}

const today = dayjs()

export default function GoalCard({ card, onDelete }: TGoalCardProps) {
  const [isLoading, setIsLoading] = useState(true)
  const company = useCurrentCompany()

  const {
    chartData,
    requestChartData,
    isLoading: isGoalDataLoading,
  } = useMetricChartData()

  const title = useMemo(() => {
    const targetDate = dayjs(card.targetPeriod).format(FORMAT)
    return `${targetDate}: ${card.title}`
  }, [card])

  const chartForecast = useChartForecast(
    chartData,
    card.goalGrowthValue,
    card.goalGrowthType,
    card.targetPeriod,
  )

  const prevMax = useMemo(() => {
    if (!chartData || !chartData.prevData?.length) {
      return null
    }
    const chartDataPrevLength = chartData.prevData.length
    return +(chartData.prevData[chartDataPrevLength - 1].value ?? 0)
  }, [chartData])

  const goalValue = useMemo(() => {
    if (!prevMax || !card) {
      return null
    }

    if (card.goalGrowthType === GOAL_GROWTH_TYPE.PERCENTAGE) {
      return (prevMax * (100 + +card.goalGrowthValue)) / 100
    }
    return card.goalGrowthValue
  }, [chartData, card])

  const lastActualItem = useMemo(
    () => chartData.actualData?.[chartData.actualData.length - 1],
    [chartData],
  )

  const lastActualDate = useMemo(() => lastActualItem?.date, [lastActualItem])

  const goalValueForLastChartDateForecast = useMemo(() => {
    if (!chartForecast?.forecastData || !goalValue || !lastActualDate) {
      return null
    }

    return getGoalValueForDate(goalValue, dayjs(lastActualDate).toDate())
  }, [goalValue, chartForecast, lastActualDate])

  const goalMetric = useMemo(
    () =>
      (metricsOptions ?? []).find(({ value }) => value === card.metric)?.label,
    [card],
  )

  useEffect(() => {
    void requestChartData(card.metric, card.compareTo, card.targetPeriod).then(
      () => {
        setIsLoading(false)
      },
    )
  }, [company])

  const status = useMemo(() => {
    if (dayjs(card.targetPeriod).isBefore(today.startOf("month"))) {
      return (
        <div className="flex items-center justify-start gap-1">
          <CheckCircleIcon className="w-5 h-5" />
          <span>Completed</span>
        </div>
      )
    }
    if (dayjs(card.targetPeriod).isAfter(today.endOf("month"))) {
      return (
        <div className="flex items-center justify-start gap-1">
          <ClockIcon className="w-5 h-5" />
          <span>Not Started</span>
        </div>
      )
    }
    if (!goalValueForLastChartDateForecast || !lastActualItem) {
      return "-"
    }

    return goalValueForLastChartDateForecast > (lastActualItem?.value ?? 0) ? (
      <div className="text-error flex items-center justify-start gap-1">
        <XCircleIcon className="w-5 h-5" />
        <span>Off Target</span>
      </div>
    ) : (
      <div className="text-success flex items-center justify-start gap-1">
        <CheckCircleIcon className="w-5 h-5" />
        <span>On Target</span>
      </div>
    )
  }, [card, goalValueForLastChartDateForecast, chartData, lastActualItem])

  if (isLoading || isGoalDataLoading) {
    return (
      <div className="w-full h-20 flex items-center justify-center">
        <Loader size={66} />
      </div>
    )
  }

  return (
    <Card
      rounded
      className="mt-3 p-5 border border-basic-blue bg-basic-dark-blue !w-150 flex flex-col flex-grow-0 flex-shrink-0 justify-between items-start group/goal"
    >
      <div className="w-full">
        <div className="flex items-start justify-between gap-4">
          <div className="text-base text-white">
            {title} {goalMetric && <>({goalMetric})</>}
          </div>
          <div className="flex items-center justify-end gap-2">
            <div>{status}</div>
            <div className="invisible group-hover/goal:visible cursor-pointer">
              <TrashIcon className="w-4 h-4" onClick={onDelete} />
            </div>
          </div>
        </div>
        {chartForecast && prevMax && (
          <H6 className="font-bold">
            Target: {toCurrency(chartForecast.forecastData[1].value)} vs{" "}
            {toCurrency(prevMax)}
          </H6>
        )}
        {card.description && (
          <div className="text-basic-neutral mt-2">{card.description}</div>
        )}
        {card.notes && (
          <div className="text-basic-neutral mt-2">{card.notes}</div>
        )}
      </div>
      <div className="mt-6 w-full">
        {chartForecast && (
          <GoalsChart
            actualData={chartData.actualData}
            prevData={chartData.prevData}
            forecastData={chartForecast.forecastData}
          />
        )}
      </div>
    </Card>
  )
}
