import { ElementType } from "react"
import CampaignCard from "../../metricCards/CampaignCard/CampaignCard"
import AdSetCard from "../../metricCards/AdsetCard/AdsetCard"
import AdCard from "../../metricCards/AdCard/AdCard"
import type {
  TBrandMediaOptimizationTable,
  TBrandMediaOptimizationTableId,
} from "~/ui-rtk/pages/BrandMedia/Optimization/connect"
import { SOURCE_IDS } from "~/ui-rtk/constants/sources"

export const VISUALIZATION_TABLE = {
  CAMPAIGN: "brand_media_optimization_campaign_table",
  AD_SET: "brand_media_optimization_adsets_table",
  AD: "brand_media_optimization_ads_table",
}

export type TBrandMediaDashboardCharts = Record<
  TBrandMediaOptimizationTableId,
  TBrandMediaOptimizationTable
>

export const TYPE_TO_CARD_MAP: Record<string, ElementType> = {
  [VISUALIZATION_TABLE.CAMPAIGN]: CampaignCard,
  [VISUALIZATION_TABLE.AD_SET]: AdSetCard,
  [VISUALIZATION_TABLE.AD]: AdCard,
}

export const TYPE_TO_LABEL_MAP: Record<string, string> = {
  [VISUALIZATION_TABLE.CAMPAIGN]: "campaign",
  [VISUALIZATION_TABLE.AD_SET]: "ad set",
  [VISUALIZATION_TABLE.AD]: "ad",
}

export const SORTED_ITEMS_ENABLED_FOR = [
  VISUALIZATION_TABLE.CAMPAIGN,
  VISUALIZATION_TABLE.AD_SET,
  VISUALIZATION_TABLE.AD,
]

export const SOURCE_ID_TO_MEDIA_CHANNEL_MAPPING: Record<string, string> = {
  [SOURCE_IDS.TIKTOK_ADS]: "tiktok",
  [SOURCE_IDS.TIKTOK]: "tiktok",
  [SOURCE_IDS.META]: "meta",
  [SOURCE_IDS.YOUTUBE]: "youtube",
  [SOURCE_IDS.YOUTUBE_ADS]: "youtube_ads",
}

export const MEDIA_CHANNEL_TO_SOURCE_ID_MAPPING: Record<string, string> = {
  tiktok: SOURCE_IDS.TIKTOK_ADS.toLowerCase(),
  meta: SOURCE_IDS.META.toLocaleLowerCase(),
  youtube_ads: SOURCE_IDS.YOUTUBE_ADS.toLocaleLowerCase(),
}

export const URL_WIDGET_PARAMS = {
  CAMPAIGN: "campaign",
  ADSET: "adset",
  AD: "ad",
}

export const URL_PARAM_TO_WIDGET_MAP: Record<string, string> = {
  [URL_WIDGET_PARAMS.CAMPAIGN]: VISUALIZATION_TABLE.CAMPAIGN,
  [URL_WIDGET_PARAMS.ADSET]: VISUALIZATION_TABLE.AD_SET,
  [URL_WIDGET_PARAMS.AD]: VISUALIZATION_TABLE.AD,
}
