import { useEffect, useMemo, useState } from "react"
import TabsGroup from "~/ui-rtk/components/ui/controls/TabsGroup/TabsGroup"
import { H3 } from "~/ui-rtk/components/ui/typography"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import useGoals from "../../hooks/goals"
import { CompanyGoalDto } from "~/ui-rtk/api/companyGoalApi"
import GoalCard from "./GoalsCard"
import { Button } from "~/ui-rtk/components/ui/controls"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { brandRoutes } from "~/ui-rtk/constants/routes"
import { Dialog, Loader } from "~/ui-rtk/components/ui/common"
import useDate from "~/ui-rtk/hooks/date"
import {
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline"

const dayjs = useDate()

type TGoalsSectionProps = {
  className?: string
}

const TABS = {
  ALL: "all",
  ACTIVE: "active",
  COMPLETED: "completed",
}

const TABS_OPTIONS = [
  {
    label: "All Goals",
    value: TABS.ALL,
  },
  {
    label: "Active",
    value: TABS.ACTIVE,
  },
  {
    label: "Completed",
    value: TABS.COMPLETED,
  },
]

const startOfMonth = dayjs().startOf("month")
const startOfNextMonth = dayjs().endOf("month")

export default function GoalsSection({ className }: TGoalsSectionProps) {
  const { goals, isLoading, isError, deleteGoal, tryRefetch } = useGoals()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [goalToDelete, setGoalToDelete] = useState<string | null>(null)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const [tabIndex, handleSetTabIndex] = useState(0)
  const navigate = useStableNavigate()

  const activeTabCards = useMemo(() => {
    if ((goals ?? []).length < 1) {
      return goals ?? []
    }
    const tabId = TABS_OPTIONS[tabIndex]?.value
    if (tabId === TABS.ACTIVE) {
      return (
        goals?.filter(
          goal =>
            !dayjs(goal.targetPeriod).isBefore(startOfMonth) &&
            dayjs(goal.targetPeriod).isBefore(startOfNextMonth),
        ) ?? []
      )
    } else if (tabId === TABS.COMPLETED) {
      return (
        goals?.filter(goal =>
          dayjs(goal.targetPeriod).isBefore(startOfMonth),
        ) ?? []
      )
    }
    return goals ?? []
  }, [tabIndex, goals])

  const handleAddNewGoal = () => navigate(brandRoutes.HOME.NEW_GOAL)

  const handleDelete = (id: string) => {
    setGoalToDelete(id)
    setShowConfirmDelete(true)
  }

  const handleConfirmDelete = async () => {
    if (goalToDelete) {
      setDeleteInProgress(true)
      await deleteGoal(goalToDelete)
      setDeleteInProgress(false)
      setGoalToDelete(null)
      setShowConfirmDelete(false)
    }
  }

  const handleCloseDialog = () => {
    setShowConfirmDelete(false)
  }

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className="w-full h-20 flex items-center justify-center">
          <Loader size={66} />
        </div>
      )
    }
    return activeTabCards.length > 0
      ? activeTabCards.map((card: CompanyGoalDto, idx: number) => (
          <GoalCard
            key={idx}
            card={card}
            onDelete={() => handleDelete(card.id)}
          />
        ))
      : "No Saved Goals. Please add new one."
  }, [isLoading, activeTabCards])

  const confirmDeleteDialog = useMemo(() => {
    if (!showConfirmDelete) {
      return null
    }
    return (
      <Dialog isOpen={true} handleClose={handleCloseDialog}>
        <div className="max-w-160 w-full absolute rounded-md py-8 px-10 center-absolute bg-basic-dark-blue border border-basic-blue shadow-deep">
          <div className="flex flex-row items-center justify-center gap-6 mb-2">
            <ExclamationTriangleIcon className="w-8 h-8" />
            <H3 className="font-medium text-white">
              Are you sure you want to delete this goal?
            </H3>
          </div>
          <div className="mb-4">You won't be able to undo it!</div>
          <XCircleIcon
            className="absolute right-4 top-4 w-7 h-7"
            onClick={handleCloseDialog}
          />
          <Button
            className="btn-block"
            variant={{
              variant: "solid",
              color: "pink",
            }}
            isLoading={deleteInProgress}
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>
        </div>
      </Dialog>
    )
  }, [showConfirmDelete, setShowConfirmDelete, goalToDelete, deleteInProgress])

  useEffect(() => {
    void tryRefetch()
  }, [])

  if (isError) {
    return null
  }

  return (
    <div>
      <div className="flex justify-start items-center gap-3">
        <H3>Goals</H3>
      </div>
      <div
        className={cn(
          className,
          "flex max-h-14 border-b border-b-basic-blue w-full gap-4 items-center",
        )}
      >
        <TabsGroup
          options={TABS_OPTIONS}
          onChange={handleSetTabIndex}
          index={tabIndex}
        />
        <Button
          variant={{ variant: "solid", color: "pink" }}
          size="sm"
          onClick={handleAddNewGoal}
        >
          + Add New Goal
        </Button>
      </div>
      <div className="mt-4 p-0 flex gap-3 overflow-x-auto justify-start">
        {content}
      </div>
      {confirmDeleteDialog}
    </div>
  )
}
