import { POSTHOG_FEATURES } from "../constants/feature-flags"
import usePostHogFeatureFlags from "./posthog-feaures"

export default function useHomepageAccess() {
  const isFeatureFlagEnabled = usePostHogFeatureFlags(
    POSTHOG_FEATURES.HOMEPAGE_ENABLED,
  )

  return isFeatureFlagEnabled
}
