import { useMemo } from "react"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

import { TDateRestrictions } from "~/ui-rtk/components/ui/controls/PeriodPicker/types"

export const useMinMaxDates = (restrictions?: TDateRestrictions) => {
  const { lastDate } = useLastUpdateDate()
  const minDate = useMemo(() => {
    if (restrictions?.minDate) {
      return restrictions.minDate
    }

    return dayjs().subtract(30, "month").toDate()
  }, [restrictions, lastDate])

  const maxDate = useMemo(() => {
    if (restrictions?.maxDate) {
      return restrictions.maxDate
    }

    if (lastDate) {
      return new Date(lastDate)
    }

    const $maxDate = dayjs().subtract(1, "day").toDate()

    return $maxDate
  }, [restrictions, lastDate])

  return { minDate, maxDate }
}
