export const POSTHOG_FEATURES = {
  REVENUE_DEP_VAR_SETUP_PAGE: "revenue-dep-var-setup-page",
  AMAZON_REVENUE_SOURCE: "amazon-dep-var",
  ONBOARDING_CONNECTION_FLOW_ENABLED: "connection-flow",
  BRAND_MEDIA_MENTIONS: "brand-media-mentions",
  GA_MULTI_PROPERTY: "ga-multi-property",
  AMAZON_SCALER_TOGGLE: "amazon-scaler-toggle",
  HOMEPAGE_ENABLED: "homepage-enabled",
  CREATIVE_REPORTS_PAGE: "creative-reports-page",
  IG_TAG_REVIEW: "ig-tag-review",
}

export type TPosthogFeatureKey =
  (typeof POSTHOG_FEATURES)[keyof typeof POSTHOG_FEATURES]
