import { POSTHOG_FEATURES } from "../constants/feature-flags"
import usePostHogFeatureFlags from "./posthog-feaures"

export default function useCreativeReportsPageAccess() {
  const isFeatureFlagEnabled = usePostHogFeatureFlags(
    POSTHOG_FEATURES.CREATIVE_REPORTS_PAGE,
  )

  return isFeatureFlagEnabled
}
