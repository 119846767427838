import React, { useMemo } from "react"
import { Header } from "@tanstack/react-table"
import { useSortable } from "@dnd-kit/sortable"

import { cn } from "~/ui-rtk/utils/tailwind-utils"

import type {
  TableVisualizationColumn,
  TMetric,
} from "~/ui-rtk/constants/table-visualization-types"
import { formatValue, getCommonPinningOrderingStyles } from "../../utils"
import ValueWithDelta from "~/ui-rtk/components/ui/common/ValueWithDelta/ValueWithDelta"
import {
  ConvertNumberType,
  ConvertNumberTypeArr,
} from "~/ui-rtk/utils/number-utils"
import { CaptionPreviewNumber } from "~/ui-rtk/components/ui/common/CaptionPreviewNumber"
import { Loader } from "~/ui-rtk/components/ui/common"

type TSummaryCellProps = {
  header: Header<TMetric, unknown>
  className?: string
  value: string | number
  compareValue?: string | number
  totalLabelColumn?: string
  isLoading?: boolean
}

export const SummaryCell: React.FC<TSummaryCellProps> = ({
  header,
  className,
  value,
  compareValue,
  totalLabelColumn,
  isLoading = false,
}) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: header.column.id,
  })

  const thStyles = useMemo(
    () => ({
      maxWidth: `${header.column.getSize() ? `${header.column.getSize()}px` : "unset"}`,
      minWidth: `${header.column.getSize() ? `${header.column.getSize()}px` : "unset"}`,
      ...getCommonPinningOrderingStyles(header.column, isDragging, transform),
    }),
    [header, isDragging, transform],
  )

  const defaultString = useMemo(
    () => (totalLabelColumn && header.id === totalLabelColumn ? "Total" : "-"),
    [header, totalLabelColumn],
  )

  return (
    <th
      key={header.id}
      style={thStyles}
      className={cn(
        "px-2 py-1.5 text-base text-white font-bold leading-6 tracking-[-2%] text-left group bg-basic-dark-blue",
        className,
      )}
      colSpan={header.colSpan}
      ref={setNodeRef}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex items-center justify-between gap-2">
          <div className="text-ellipsis">
            {ConvertNumberTypeArr.includes(
              (header.column.columnDef as TableVisualizationColumn)
                .type as ConvertNumberType,
            ) ? (
              <CaptionPreviewNumber
                value={value as number}
                top
                columnType={
                  (header.column.columnDef as TableVisualizationColumn)
                    .type as ConvertNumberType
                }
              />
            ) : (
              defaultString
            )}
            {compareValue &&
            ["currency", "number", "percent", "coef"].includes(
              `${(header.column.columnDef as TableVisualizationColumn).type}`,
            ) ? (
              <span className="text-gray-light">
                <div className="flex gap-2 text-sm">
                  <ValueWithDelta
                    actualValue={value as number}
                    comparedValue={compareValue as number}
                    hideActualValue
                  />
                  <span>
                    prev:{" "}
                    {formatValue(
                      compareValue,
                      (header.column.columnDef as TableVisualizationColumn)
                        .type,
                      undefined,
                    )}
                  </span>
                </div>
              </span>
            ) : null}
          </div>
        </div>
      )}
    </th>
  )
}
