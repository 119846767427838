import React, { useState } from "react"
import { CompanyDto } from "~/ui-rtk/api/types"

import { Logo } from "~/ui-rtk/components/ui/common"
import { Title, ProfileMenu } from "./components"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { DEFAULT_LOGGED_ROUTE } from "~/ui-rtk/constants/routes"

import { PlusSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectUserSessionRole } from "~/ui-rtk/app/selectors/user.selector"
import { SessionRole } from "~/api/dto/auth/session-role"
import { CreateCompanyDialog } from "./components/AddCompanyDialog"
import { Button } from "~/ui-rtk/components/ui/controls"
import LastUpdatedChipStandalone from "~/ui-rtk/components/ui/common/LastUpdatedChip/LastUpdatedChip"
import { toast } from "react-toastify"
import { copyTextToClipboard } from "~/ui-rtk/utils/clipboard"
import { URL_COMPANY_ID } from "~/ui-rtk/constants/url-params"
import { LinkIcon, ShieldExclamationIcon } from "@heroicons/react/24/outline"
import useDemoMode from "~/ui-rtk/hooks/demo-mode"

type TTopbarProps = {
  onCompanyChange: (companyId: string) => void
  currentCompany: CompanyDto
}

const Topbar: React.FC<TTopbarProps> = ({
  onCompanyChange,
  currentCompany,
}) => {
  const navigate = useStableNavigate()
  const { isEnabled: isDemoEnabled, toggleEnabled } = useDemoMode()

  const role = useAppSelector(selectUserSessionRole)

  const enableDemoMode = () => {
    toggleEnabled()
  }

  const [isOpen, setIsOpen] = useState(false)

  const redirectToHomePage = () => {
    void navigate(DEFAULT_LOGGED_ROUTE)
  }

  const openDialog = () => {
    if (role !== SessionRole.SUPER_ADMIN) {
      return
    }

    setIsOpen(true)
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const copyLinkToPage = () => {
    const currentLink = new URL(window.location.href)
    currentLink.searchParams.set(URL_COMPANY_ID, currentCompany.id)
    void copyTextToClipboard(currentLink.href)
      .then(() =>
        toast.success("Copied url successfully!", {
          toastId: "clipboard-url-copy-success",
        }),
      )
      .catch(() =>
        toast.error("Failed to copy id to clipboard", {
          toastId: "clipboard-url-copy-error",
        }),
      )
  }

  return (
    <div className="fixed top-0 left-0 right-0 z-[1001] flex items-center justify-between px-2 py-1.5 border-b border-b-basic-blue bg-basic-black">
      <div className="flex items-center gap-8.5">
        <Logo
          size={32}
          className="ml-1.5"
          onClick={redirectToHomePage}
          cursor="pointer"
        />
        <div className="flex gap-2">
          <Title
            onCompanyChange={onCompanyChange}
            currentCompany={currentCompany}
          />

          {role === SessionRole.SUPER_ADMIN && (
            <React.Fragment>
              <Button
                onClick={openDialog}
                size="xs"
                variant={{ variant: "solid", color: "blue" }}
                className="rounded-md"
              >
                <PlusSvg />
              </Button>

              <CreateCompanyDialog isOpen={isOpen} onClose={closeDialog} />
            </React.Fragment>
          )}
          {role === SessionRole.SUPER_ADMIN && (
            <React.Fragment>
              <Button
                onClick={enableDemoMode}
                size="xs"
                variant={{
                  variant: "solid",
                  color: isDemoEnabled ? "red" : "blue",
                }}
                className="rounded-md text-sm"
              >
                <ShieldExclamationIcon className="size-5" />
                Demo Mode
              </Button>

              <Button
                onClick={copyLinkToPage}
                size="xs"
                variant={{
                  variant: "solid",
                  color: "blue",
                }}
                className="rounded-md text-sm"
              >
                <LinkIcon className="size-4" />
                Link to Page
              </Button>

              <CreateCompanyDialog isOpen={isOpen} onClose={closeDialog} />
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="flex items-center gap-3">
        <LastUpdatedChipStandalone key={currentCompany?.id}>
          Max date available in Platform:
        </LastUpdatedChipStandalone>
        <ProfileMenu />
      </div>
    </div>
  )
}

export default Topbar
