import React, {
  Fragment,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react"

import SelectItem from "./SelectItem"

import { useSearch } from "./useSearch"
import { useClickOutside } from "~/ui-rtk/shared/hooks"
import { SwitchSvg } from "~/ui-rtk/components/ui/svg/arrow"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

type TClasses = {
  container: string
  input: string
  list: string
}

type Props = {
  options: Option[]
  value?: Option
  Icon?: React.ReactElement

  classes?: Partial<TClasses>

  render?: (option: Option) => React.ReactElement
  onSelect?: (selectedOption: Option) => void
  demoMode?: boolean
}

export type Option = {
  label: string
  value: string | number
}

const SearchSelect: React.FC<Props> = ({
  options,
  value,
  Icon,
  classes,
  render,
  onSelect,
  demoMode,
}) => {
  const onSelectFallback = onSelect ?? (() => null)

  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<Option | undefined>(value)
  const { searchQuery, setSearchQuery, filteredItems, setIsSearching } =
    useSearch(options, value?.label)

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const handleContainerClick = useCallback(() => {
    inputRef.current?.focus()
    setShowDropdown(true)
  }, [])

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowDropdown(true)
      setSearchQuery(event.target.value)
    },
    [],
  )

  const handleSelect = useCallback(
    (option: Option) => {
      onSelectFallback(option)
      setSelectedItem(option)
      setSearchQuery(option.label)
      setShowDropdown(false)
      setIsSearching(false)
    },
    [onSelectFallback],
  )

  const handleKeydown = useCallback(
    (event: React.KeyboardEvent) => {
      event.stopPropagation()
      if (event.key === "Enter" && filteredItems.length > 0) {
        handleSelect(filteredItems[0])
      }
    },
    [filteredItems, handleSelect],
  )

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current
          .querySelector(".active-select-item")
          ?.scrollIntoView()
      }
    }, 50)
  }, [showDropdown])

  const inputCursorClass = `${showDropdown ? "cursor-text" : "cursor-pointer"}`

  useClickOutside(containerRef, () => {
    setSearchQuery(selectedItem?.label ?? "")
    setShowDropdown(false)
    setIsSearching(false)
  })

  useEffect(() => {
    setSelectedItem(value)
  }, [value])

  return (
    <div
      ref={containerRef}
      className={cn(
        "relative h-10 max-w-75",
        demoMode && "blur-md",
        classes?.container,
      )}
      onClick={handleContainerClick}
    >
      <div
        className={cn(
          `flex h-full items-center justify-between w-full px-4 py-2 rounded-md border-basic-blue border bg-basic-dark-blue`,
          inputCursorClass,
          classes?.input,
        )}
      >
        <input
          ref={inputRef}
          value={searchQuery}
          onFocus={ev => ev.target.select()}
          onChange={handleSearch}
          onKeyDown={handleKeydown}
          className={`w-full h-full text-cadet-blue font-medium focus:outline-none focus:ring-0 ${inputCursorClass}`}
        />

        {Icon ? Icon : <SwitchSvg size={18} fill="gray" />}
      </div>
      {showDropdown ? (
        <ul
          className={cn(
            `absolute top-[120%] z-[1000] rounded-md left-0 right-0 border-basic-blue border bg-basic-dark-blue max-h-75 overflow-y-auto scrollbar animated-dropdown`,
            classes?.list,
          )}
        >
          {!render
            ? filteredItems.map(item => (
                <Fragment key={item.value}>
                  <SelectItem
                    onSelect={handleSelect}
                    className={
                      item.value === value?.value
                        ? "active-select-item scroll-mt-10 rounded-md"
                        : undefined
                    }
                    option={item}
                    disabled={item.value === value?.value}
                  />
                </Fragment>
              ))
            : filteredItems.map(render)}
        </ul>
      ) : null}
    </div>
  )
}

export default SearchSelect
