import { useMemo } from "react"
import { SOURCE_IDS } from "~/ui-rtk/constants/sources"
import { type TLastUpdateDateProps } from "~/ui-rtk/hooks/cube"
import useSources from "~/ui-rtk/hooks/sources"

export const BRAND_MEDIA_OPTIMIZATION_TABLES = {
  CAMPAIGN: "CAMPAIGN",
  ADSET: "ADSET",
  AD: "AD",
}

export type TBrandMediaOptimizationTableId =
  keyof typeof BRAND_MEDIA_OPTIMIZATION_TABLES

export type TBrandMediaOptimizationTable = {
  id: TBrandMediaOptimizationTableId
  label: string
  widget: string
  summaryWidget: string
  requiredSources: string[]
  lastDateChipProps: TLastUpdateDateProps
}

const content = {
  id: "cltes8yua000008jv2xgg9fc5",
  slug: "/brand-media/optimization",
  icon: "NULL",
  title: "Brand Media Optimization",
  parent: {
    slug: "/brand-media",
    title: "Brand Media",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 30.1,
  content: {
    items: {
      [BRAND_MEDIA_OPTIMIZATION_TABLES.CAMPAIGN]: {
        id: BRAND_MEDIA_OPTIMIZATION_TABLES.CAMPAIGN,
        label: "Campaigns",
        widget: "brand_media_optimization_campaign_table",
        summaryWidget: "brand_media_optimization_campaign_table_summary",
        requiredSources: [
          SOURCE_IDS.META,
          SOURCE_IDS.TIKTOK_ADS,
          SOURCE_IDS.YOUTUBE,
        ],
        lastDateChipProps: {
          dateMetric:
            "company_last_updated_date.brand_media_paid_optimization_campaigns_max_date",
        },
      },
      [BRAND_MEDIA_OPTIMIZATION_TABLES.ADSET]: {
        id: BRAND_MEDIA_OPTIMIZATION_TABLES.ADSET,
        label: "Ad Sets",
        widget: "brand_media_optimization_adsets_table",
        summaryWidget: "brand_media_optimization_adsets_table_summary",
        requiredSources: [
          SOURCE_IDS.META,
          SOURCE_IDS.TIKTOK_ADS,
          SOURCE_IDS.YOUTUBE,
        ],
        lastDateChipProps: {
          dateMetric:
            "company_last_updated_date.brand_media_paid_optimization_adsets_max_date",
        },
      },
      [BRAND_MEDIA_OPTIMIZATION_TABLES.AD]: {
        id: BRAND_MEDIA_OPTIMIZATION_TABLES.AD,
        label: "Ads",
        widget: "brand_media_optimization_ads_table",
        summaryWidget: "brand_media_optimization_ads_table_summary",
        requiredSources: [
          SOURCE_IDS.META,
          SOURCE_IDS.TIKTOK_ADS,
          SOURCE_IDS.YOUTUBE,
        ],
        lastDateChipProps: {
          dateMetric:
            "company_last_updated_date.brand_media_paid_optimization_ads_max_date",
        },
      },
    } as Record<TBrandMediaOptimizationTableId, TBrandMediaOptimizationTable>,
    title: "Paid Media Optimization",
    periodPicker: {
      visible: true,
    },
  },
  items: [],
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  const requiredSources = useMemo(
    () =>
      Array.from(
        new Set(
          Object.values(content.content.items).reduce(
            (acc: string[], chart) => [
              ...acc,
              ...(chart.requiredSources ?? []),
            ],
            [],
          ),
        ),
      ),
    [],
  )

  return {
    content,
    sources,
    requiredSources,
    isLoading: isSourcesDataLoading,
  }
}
