import { CSSProperties, useMemo } from "react"
import {
  Area,
  CartesianGrid,
  Line,
  ComposedChart as ReComChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"
import useDate from "~/ui-rtk/hooks/date"
import useTailwindTheme from "~/ui-rtk/hooks/tailwind-theme"
import { getTickFormatter } from "~/ui-rtk/utils/chart-utils"
import { formatNumber } from "~/ui-rtk/utils/format"
import { type TMetric } from "../constants"
import { DATE_FORMAT } from "~/ui-rtk/hooks/period"

const dayjs = useDate()

type TGoalChartProps = {
  prevData: TMetric[]
  actualData?: TMetric[]
  forecastData: [TMetric, TMetric]
}

type TActualResultMetric = {
  date: Date | null
  value: number | null
  forecastValue: number | null
}

export default function GoalsChart({
  prevData,
  actualData,
  forecastData,
}: TGoalChartProps) {
  const theme = useTailwindTheme()

  const yAxisStyle = useMemo(
    () => ({
      fontSize: `${theme.fontSize.sm}`,
      textAnchor: "end" as CSSProperties["textAnchor"],
      transform: "translate(-10px, 10px)",
    }),
    [theme],
  )

  const cartesianGridStyles = useMemo(
    () => ({ stroke: theme.colors["gray-dark"] }),
    [theme],
  )

  const formatTick = getTickFormatter()

  const actualMetrics = useMemo<TActualResultMetric[]>(() => {
    const forecastDateStart = dayjs(forecastData[0].date)
    const forecastDateEnd = dayjs(forecastData[1].date)
    const forecastDaysRange = forecastDateEnd.diff(forecastDateStart, "day") + 1

    const monthFromForecast = forecastDateStart.month()
    const yearFromForecast = forecastDateStart.year()

    const res = []

    for (let i = 0; i < forecastDaysRange; i++) {
      const dateString = `${yearFromForecast}-${`${monthFromForecast + 1}`.padStart(2, "0")}-${`${i + 1}`.padStart(2, "0")}`

      const date = dayjs(dateString, DATE_FORMAT, true)
      const dateValid = date.isValid()
      const dateToPush = dateValid ? date : null
      const actualItem = actualData?.find(({ date: itemDate }: TMetric) =>
        dayjs(itemDate).isSame(date),
      )
      const value = dateValid ? (actualItem?.value ?? null) : null

      let forecastValue = null
      if (i === 0) {
        forecastValue = 0
      } else if (i === forecastDaysRange - 1) {
        forecastValue = forecastData[1].value
      }

      res.push({
        date: dateToPush?.toDate() ?? null,
        value,
        forecastValue,
      })
    }

    return res.filter(({ date }) => !!date)
  }, [prevData, actualData, forecastData])

  const maxVal = useMemo(
    () =>
      Math.max(
        ...[...actualMetrics, ...(prevData as TActualResultMetric[])].map(
          ({ value, forecastValue }) =>
            Math.max(value ?? 0, forecastValue ?? 0, 0),
        ),
      ),
    [actualMetrics, prevData],
  )

  return (
    <div className="relative">
      <ResponsiveContainer width="100%" height={400} minHeight={85}>
        <ReComChart
          data={actualMetrics}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 0"
            vertical={false}
            style={cartesianGridStyles}
          />
          <XAxis
            xAxisId={0}
            dataKey="date"
            tickFormatter={formatTick}
            interval="preserveStart"
            minTickGap={30}
            className="text-sm"
            padding={{
              right: 0,
            }}
          />
          <YAxis
            domain={[0, maxVal]}
            orientation="right"
            interval="preserveEnd"
            padding={{ top: 0, bottom: 0 }}
            width={0.01}
            axisLine={false}
            tickLine={false}
            style={yAxisStyle}
            tickFormatter={(value, index) =>
              index !== 0 || value > 0
                ? `${formatNumber(value, { compact: true })}`
                : ""
            }
          />
          <Area
            type="monotone"
            key="value"
            dataKey="value"
            strokeWidth={2}
            stroke={"#EB89B5"}
            fill="#EB89B5"
            fillOpacity={0.25}
            isAnimationActive={true}
            animationDuration={1500}
            animationBegin={0}
            xAxisId={0}
          />
          <Line
            type="monotone"
            key="forecastValue"
            dataKey="forecastValue"
            connectNulls
            dot={false}
            strokeWidth={2}
            strokeDasharray="6 6"
            stroke="#EB89B5"
            strokeOpacity={1}
            fill="#EB89B5"
            fillOpacity={0.25}
            isAnimationActive={true}
            animationDuration={1500}
            animationBegin={0}
            xAxisId={0}
          />
        </ReComChart>
      </ResponsiveContainer>
      <ResponsiveContainer
        width="100%"
        height={400}
        minHeight={85}
        className="absolute top-0 right-0 left-0 bottom-0"
      >
        <ReComChart
          data={prevData}
          margin={{
            top: 0,
            right: 0,
            bottom: 30,
            left: 0,
          }}
        >
          <XAxis xAxisId={1} dataKey="date" tick={false} hide />
          <YAxis
            domain={[0, maxVal]}
            orientation="right"
            interval="preserveEnd"
            padding={{ top: 0, bottom: 0 }}
            width={0.01}
            axisLine={false}
            tickLine={false}
            style={yAxisStyle}
            tick={false}
          />
          <Area
            type="monotone"
            key="value"
            dataKey="value"
            strokeWidth={2}
            stroke="#5C8BD6"
            fill="#5C8BD6"
            fillOpacity={0.25}
            isAnimationActive={true}
            animationDuration={1500}
            animationBegin={0}
            xAxisId={1}
          />
        </ReComChart>
      </ResponsiveContainer>
    </div>
  )
}
